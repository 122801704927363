<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" :isLong="true" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px', height:this.mainFunction+'px'}">
            <div class="caseMainFunctionLargeDiv" style="height: 86%;">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/shengchuanbo/computer.png" style="height: 68.1%;">
                </div>
                <div class="caseMainFunctionIconLine" style="height: 58.1%; width: 65%;">
                    <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                    <CaseMainFunctionIconDiv :iconItem="iconItem2" />
                    <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                    <CaseMainFunctionIconDiv :iconItem="iconItem4" />
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}" style="background-color: #eee; position: relative;">
            <div class="caseProjectPic" style="width: 72.6%; height: 50%; position: absolute; left: 50%; transform: translateX( -50% );">
                <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize1" />
                <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize2" />
            </div>
            <div class="caseProjectPic" style="width: 72.6%; height: 50%; position: absolute; left: 50%; transform: translateX( -50% ); top: 50%;">
                <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize1" />
                <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize2" />
            </div>
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/shengchuanbo/icon1.png'
import icon2 from '../assets/case/shengchuanbo/icon2.png'
import icon3 from '../assets/case/shengchuanbo/icon3.png'
import icon4 from '../assets/case/shengchuanbo/icon4.png'
import pic1 from '../assets/case/shengchuanbo/projectPic1.png'
import pic2 from '../assets/case/shengchuanbo/projectPic2.png'
import pic3 from '../assets/case/shengchuanbo/projectPic3.png'
import pic4 from '../assets/case/shengchuanbo/projectPic4.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseSoundPropagation',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunction: '430',
            projectPic:'850',
            topTitle: '声传播模型仿真软件',
            pojectOverviewMsg: '“声传播模型仿真软件”用于模拟声波在不同环境中的传播和影响，包括室内、室外、水下等场景。该软件将提供准确的声学模拟功能，以帮助用户更好地理解和分析声波传播的特性和效果。项目的核心目标是实现高度准确的声传播模型仿真，包括声源的发射、传播路径的模拟和接收器的接收等。用户可以根据实际需求设置声源的参数，如频率、幅度、位置等，同时还可以模拟不同材质的障碍物对声波的衰减和反射。通过仿真结果，用户可以直观地了解声波在不同环境中的传播情况，预测声音的强度、衰减和传播路径等',
            iconItem1: {title:'水下环境模型文件读取', text: '', icon: icon1},
            iconItem2: {title:'声传播射线模型以及简振波模型的仿真计算', text: '', icon: icon2},
            iconItem3: {title:'将计算结果输出至二进制文件中供读取', text: '', icon: icon3},
            iconItem4: {title:'用户计算模块接口供用户二次开发', text: '', icon: icon4},
            picItem1: {title:'声传播射线模型以及简振波模型', pic: pic1},
            picItem2: {title:'声道传播', pic: pic2},
            picItem3: {title:'海底探测回波', pic: pic3},
            picItem4: {title:'频波', pic: pic4},
            caseProjectPicSize1: {width: '53.9', height: '100'},
            caseProjectPicSize2: {width: '46.1', height: '100'},
            index: 8,
            currentType: 0,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunction = 269;
                this.projectPic = 531;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunction = document.body.clientWidth * 0.224;
                this.projectPic = document.body.clientWidth * 0.443;
            }
        },
    },
}
</script>