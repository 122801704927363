<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" :isLong="true" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px'}">
            <div class="caseMainFunctionLargeDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/shengchan/computer.png">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" />
                    </div>
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem5" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem6" :isLong="true" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem7" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem8" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem5" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem6" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/shengchan/icon1.png'
import icon2 from '../assets/case/shengchan/icon2.png'
import icon3 from '../assets/case/shengchan/icon3.png'
import icon4 from '../assets/case/shengchan/icon4.png'
import icon5 from '../assets/case/shengchan/icon5.png'
import icon6 from '../assets/case/shengchan/icon6.png'
import icon7 from '../assets/case/shengchan/icon7.png'
import icon8 from '../assets/case/shengchan/icon8.png'
import pic1 from '../assets/case/shengchan/projectPic1.png'
import pic2 from '../assets/case/shengchan/projectPic2.png'
import pic3 from '../assets/case/shengchan/projectPic3.png'
import pic4 from '../assets/case/shengchan/projectPic4.png'
import pic5 from '../assets/case/shengchan/projectPic5.png'
import pic6 from '../assets/case/shengchan/projectPic6.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseProductionInformatizationView',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '620',
            projectPic:'427',
            topTitle: '生产信息化管理软件',
            pojectOverviewMsg: '“生产信息化管理软件”是一种专门为企业生产流程设计的系统，帮助企业实现生产计划、生产调度、生产过程监控和生产数据分析的自动化管理。该软件通过集成生产计划、物料管理、设备监控和生产数据分析等功能，实现对生产全过程的实时监控和控制，提高生产效率、降低生产成本、优化资源配置，并提升产品质量和客户满意度的工具。该软件能够自动化处理生产订单、物料需求计划、设备调度和生产报表等流程，提供实时数据分析、异常预警和生产过程优化建议，帮助企业实现生产过程的高效、精确管理，提升企业竞争力和生产效益',
            iconItem1: {title:'生产计划管理', text: '支持制定和调整生产计划，如订单管理、排产计划、任务分配', icon: icon1},
            iconItem2: {title:'物料管理', text: '管理生产所需的物料和零部件，如库存管理、物料需求计划', icon: icon2},
            iconItem3: {title:'设备监控', text: '实时监测生产设备的运行状态、故障信息和维护记录', icon: icon3},
            iconItem4: {title:'生产过程监控', text: '通过传感器和数据采集技术，实时监控生产过程中的关键参数', icon: icon4},
            iconItem5: {title:'质量管理', text: '管理产品质量数据，如质检记录、异常处理和质量指标分析', icon: icon5},
            iconItem6: {title:'人力资源管理', text: '管理生产人员的信息、工时记录和绩效评估', icon: icon6},
            iconItem7: {title:'数据分析与报表', text: '通过挖掘生产数据，生成各类报表和数据分析图表', icon: icon7},
            iconItem8: {title:'系统集成与扩展', text: '能够与其他企业系统进行集成，支持定制化需求和扩展功能', icon: icon8},
            picItem1: {title:'生产计划', pic: pic1},
            picItem2: {title:'物料清单', pic: pic2},
            picItem3: {title:'设备监控', pic: pic3},
            picItem4: {title:'质量管理', pic: pic4},
            picItem5: {title:'生产任务', pic: pic5},
            picItem6: {title:'生产报表', pic: pic6},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 3,
            currentType: 2,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 387.5;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.322;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    },
}
</script>