<template>
    <div class="projectMilitaryFeaturesLineDiv">
        <div class="projectMilitaryFeaturesTextDiv" :style="{'background-image':'url('+require('../assets/ProjectMilitaryFeaturesLeftBgimg.png')+')'}">
            <p class="textTitle" style="text-align: right;" :style="{right:this.titleRight+'px'}">{{ item.title }}</p>
            <div class="projectMilitaryFeaturesRightText">
                <p :style="{width:this.width+'px', left:this.left+'px'}" class="textMsg">{{ item.text }}</p>
            </div>
        </div>
        <div class="projectMilitaryFeaturesImgDiv">
            <img :src="item.img">
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectMilitaryFeaturesLeft',
    props:{
        item:{
            type: Object,
            required: true,
        },
        changeWidth:{
            type: Number,
            default: 0,
        }
    },
    data(){
        return{
            isActive: false,
            width: 380,
            left: 30,
            titleRight: 72.5,
        }
    },
    created() {
        this.init();
    },
    methods: {
        init(){
            switch (this.changeWidth){
                case 1:
                    this.width = 396;
                    this.left = 22;
                    this.titleRight = 64.5;
                    break;
                case 2:
                    this.width = 361;
                    this.left = 40;
                    this.titleRight = 82;
                    break;
            }
        },
        mouseOver() {
            this.isActive = true;
        },
        mouseLeave() {
            this.isActive = false;
        },
    },
}
</script>