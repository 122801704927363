<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px'}">
            <div class="caseMainFunctionLargeDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title">主要功能</p>
                    <img src="../assets/case/jidongtaizhan/computer.png">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" :isLong="true" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" />
                    </div>
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem5" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem6" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem7" :isLong="true" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem8" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem5" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem6" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/jidongtaizhan/icon1.png'
import icon2 from '../assets/case/jidongtaizhan/icon2.png'
import icon3 from '../assets/case/jidongtaizhan/icon3.png'
import icon4 from '../assets/case/jidongtaizhan/icon4.png'
import icon5 from '../assets/case/jidongtaizhan/icon5.png'
import icon6 from '../assets/case/jidongtaizhan/icon6.png'
import icon7 from '../assets/case/jidongtaizhan/icon7.png'
import icon8 from '../assets/case/jidongtaizhan/icon8.png'
import pic1 from '../assets/case/jidongtaizhan/projectPic1.png'
import pic2 from '../assets/case/jidongtaizhan/projectPic2.png'
import pic3 from '../assets/case/jidongtaizhan/projectPic3.png'
import pic4 from '../assets/case/jidongtaizhan/projectPic4.png'
import pic5 from '../assets/case/jidongtaizhan/projectPic5.png'
import pic6 from '../assets/case/jidongtaizhan/projectPic6.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CustomerCaseMobileStation',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '620',
            projectPic:'427',
            topTitle: '机动台站模拟训练系统',
            pojectOverviewMsg: '为实现军民融合，响应国家号召，减轻学校设备的维修和学习成本，我们采用了模拟训练仿真技术，建立了仿真实验教学，以提高学习效率并解决以下问题：设备量少，学习难度大；无设备只能纸上谈兵；设备维修成本高昂，多人学习使用设备导致设备易坏，维修成本高等。我们的仿真教学不仅可以随时学习理论知识，还可以进行与真实设备无差别的实际操作。通过模拟训练，学生可以获得真实场景下的操作体验，进一步巩固理论知识，并且能够进行考核，检验学生的学习成果。这种实践性的学习方式可以让学生对自己的学习情况有清楚的认知',
            iconItem1: {title:'设备介绍', text: '讲解设备的组成、应用以及原理', icon: icon1},
            iconItem2: {title:'文档资料', text: '上传课件及设备资料，最新资讯等', icon: icon2},
            iconItem3: {title:'操作演示', text: '上传设备正确使用视频及错误示范等', icon: icon3},
            iconItem4: {title:'考试系统', text: '分为实操考试和理论考试两种', icon: icon4},
            iconItem5: {title:'信号测试', text: '使用示波器进行信号测试', icon: icon5},
            iconItem6: {title:'电压测试', text: '使用万用表进行电压测试', icon: icon6},
            iconItem7: {title:'设备操作', text: '软件操作、硬件操作、连接操作', icon: icon7},
            iconItem8: {title:'故障排除', text: '设置故障点，观察故障现象进行排故', icon: icon8},
            picItem1: {title:'软件操作', pic: pic1},
            picItem2: {title:'连接操作', pic: pic2},
            picItem3: {title:'故障排除', pic: pic3},
            picItem4: {title:'部件查看', pic: pic4},
            picItem5: {title:'考核系统', pic: pic5},
            picItem6: {title:'室外场景', pic: pic6},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 6,
            currentType: 0,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 387.5;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.322;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    },
}
</script>

<style>
.caseNav{
    width: 100%;
    height: 90px;
    background-color: #000;
}
/* 主要功能 */
.caseMainFunction{
    position: relative;
    background-color: #fff;
    height: 620px;
}
.caseMainFunctionLargeDiv{
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 1200px;
    height: 91.1%;
    left: 50%;
    transform: translateX( -50% );
    bottom: 0;
}
.caseMainFunctionComputer{
    width: 35%;
    height: 100%;
}
.caseMainFunctionComputer .title{
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 4px;
    color: #333; 
    margin-top: 20px;
}
.caseMainFunctionComputer img{
    position: absolute;
    bottom: 0;
    left: 5px;
    width: 25.5%;
    height: 44.6%;
}
.caseMainFunctionIcon{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 65%;
    height: 83.2%;
}
.caseMainFunctionIconLine{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 45.7%;
}

.caseProjectPic{
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
</style>