<template>
    <div style="width: 1200px; height: 100%; position: absolute; left: 50%; transform: translateX( -50% );">
        <div class="aboutUsPartnersTitle">
            <p class="title" style="color: #333; position: absolute; top: 100px; left: 50%; transform: translateX( -50% );">合作伙伴</p>
        </div>
        <div class="aboutUsPartnersImgDiv">
            <div class="aboutUsPartnersImgMiddle">
                <img src="../assets/aboutUs/aboutUsPartnersIcon1.png" style="width: 76%; height: 47%;">
            </div>
            <div class="aboutUsPartnersImgSmallDiv">
                <div class="aboutUsPartnersImgSmall" style="top: 0;">
                    <img src="../assets/aboutUs/aboutUsPartnersIcon2.png" style="width: 50%; height: 52%;">
                </div>
                <div class="aboutUsPartnersImgSmall" style="bottom: 0;">
                    <img src="../assets/aboutUs/aboutUsPartnersIcon3.png" style="width: 74%; height: 56%;">
                </div>
            </div>
            <div class="aboutUsPartnersImgSmallDiv">
                <div class="aboutUsPartnersImgSmall" style="top: 0;">
                    <img src="../assets/aboutUs/aboutUsPartnersIcon4.png" style="width: 58%; height: 59%;">
                </div>
                <div class="aboutUsPartnersImgSmall" style="bottom: 0;">
                    <img src="../assets/aboutUs/aboutUsPartnersIcon5.png" style="width: 53%; height: 55%;">
                </div>
            </div>
            <div class="aboutUsPartnersImgLargeDiv">
                <div class="aboutUsPartnersImgLarge" style="top: 0;">
                    <img src="../assets/aboutUs/aboutUsPartnersIcon6.png" style="width: 70%; height: 57.3%;">
                </div>
                <div class="aboutUsPartnersImgLarge" style="bottom: 0;">
                    <img src="../assets/aboutUs/aboutUsPartnersIcon7.png" style="width: 60%; height: 46.7%;">
                </div>
            </div>
            <div class="aboutUsPartnersImgSmallDiv">
                <div class="aboutUsPartnersImgSmall" style="top: 0;">
                    <img src="../assets/aboutUs/aboutUsPartnersIcon8.png" style="width: 60%; height: 56%;">
                </div>
                <div class="aboutUsPartnersImgSmall" style="bottom: 0;">
                    <img src="../assets/aboutUs/aboutUsPartnersIcon9.png" style="width: 48%; height: 49%;">
                </div>
            </div>
            <div class="aboutUsPartnersImgSmallDiv">
                <div class="aboutUsPartnersImgSmall" style="top: 0;">
                    <img src="../assets/aboutUs/aboutUsPartnersIcon10.png" style="width: 62%; height: 58%;">
                </div>
                <div class="aboutUsPartnersImgSmall" style="bottom: 0;">
                    <img src="../assets/aboutUs/aboutUsPartnersIcon11.png" style="width: 54%; height: 53%;">
                </div>
            </div>
            <div class="aboutUsPartnersImgMiddle">
                <img src="../assets/aboutUs/aboutUsPartnersIcon12.png" style="width: 74%; height: 28%;">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUsPartners',
}
</script>

<style>
.aboutUsPartnersTitle{
    width: 100%;
    height: 208px;
}
.aboutUsPartnersImgDiv{
    position: relative;
    left: 50%; 
    transform: translateX( -50% );
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 355px;
}
.aboutUsPartnersImgMiddle{
    position: relative;
    width: 13.2%;
    height: 28.2%;
	background-color: #fff;
	box-shadow: 0px 4px 8px 0px rgba(2, 4, 11, 0.35);
	border-radius: 8px;
}
.aboutUsPartnersImgSmallDiv{
    position: relative;
    width: 8.3%;
    height: 73.2%;
}
.aboutUsPartnersImgSmall{
    position: absolute; 
    width: 100%;
    height: 38.5%;
	background-color: #fff;
	box-shadow: 0px 4px 8px 0px rgba(2, 4, 11, 0.35);
	border-radius: 8px;
}
.aboutUsPartnersImgLargeDiv{
    position: relative;
    width: 12.5%;
    height: 100%;
}
.aboutUsPartnersImgLarge{
    position: absolute; 
    width: 100%;
    height: 42.3%;
	background-color: #fff;
	box-shadow: 0px 4px 8px 0px rgba(2, 4, 11, 0.35);
	border-radius: 8px;
}
.aboutUsPartnersImgLarge img,.aboutUsPartnersImgMiddle img,.aboutUsPartnersImgSmall img{
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate( -50%, -50% );
}
</style>