import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutUsView from '../views/AboutUsView'
import AboutOneView from '../views/AboutOneView'
import AboutProductWMSView from '../views/AboutProductWMSView'
import AboutProductERP   from '../views/AboutProductERP'
import AboutProductRetail   from '../views/AboutProductRetail'
import AboutOnlineTransactions   from '../views/AboutOnlineTransactions'
import AboutOnlineEducation   from '../views/AboutOnlineEducation'
import AboutOnlineRecruit   from '../views/AboutOnlineRecruit'
import ProductVirtualSimulationView from '../views/ProductVirtualSimulationView.vue'
import ProductGuidanceControlView from '../views/ProductGuidanceControlView.vue'
import ProductElectronicChartView from '../views/ProductElectronicChartView.vue'
import ProductSportsSimulationView from '../views/ProductSportsSimulationView.vue'
import ProductWavePredictionView from '../views/ProductWavePredictionView.vue'
import ProductDataAnalysisVisualizationView from '../views/ProductDataAnalysisVisualizationView.vue'
import ProductDecisionSupportView from '../views/ProductDecisionSupportView.vue'
import ProductEquipmentStatusMonitoringView from '../views/ProductEquipmentStatusMonitoringView.vue'
import ProductManagementView from '../views/ProductManagementView.vue'
import ProductExamineView from '../views/ProductExamineView.vue'
import CaseShipMotion from '../views/CaseShipMotion.vue'
import CaseElectronicChart from '../views/CaseElectronicChart.vue'
import CaseFaultAnalysis from '../views/CaseFaultAnalysis.vue'
import CaseSeaWaveInformation from '../views/CaseSeaWaveInformation.vue'
import CaseEnvironmentTroopsGeneration from '../views/CaseEnvironmentTroopsGeneration.vue'
import CaseGrassrootsOrganizationalEducation from '../views/CaseGrassrootsOrganizationalEducation.vue'
import CustomerCaseMobileStation from '../views/CustomerCaseMobileStationView.vue'
import CaseFullDimensionalData from '../views/CaseFullDimensionalData.vue'
import CaseSoundPropagation from '../views/CaseSoundPropagation.vue'
import CaseERPView from '../views/CaseERPView.vue'
import CaseJingmeiView from '../views/CaseJingmeiView.vue'
import CaseInventoryManagementView from '../views/CaseInventoryManagementView.vue'
import CaseProductionInformatizationView from '../views/CaseProductionInformatizationView.vue'
import CaseLarkView from '../views/CaseLarkView.vue'
import CaseInternationalFreightForwarderView from '../views/CaseInternationalFreightForwarderView.vue'
import CaseRencaiyunView from '../views/CaseRencaiyunView.vue'
import CaseYunzhanOnlineView from '../views/CaseYunzhanOnlineView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: AboutUsView
  },
  {
    path: '/aboutOne',
    name: 'aboutOne',
    component: AboutOneView
  },
  {
    path: '/aboutWms',
    name: 'AboutProductWMSView',
    component: AboutProductWMSView
  },
  {
    path: '/aboutRecruit',
    name: 'AboutOnlineRecruit',
    component: AboutOnlineRecruit
  },
  {
    path: '/ProductVirtualSimulationView',
    name: 'ProductVirtualSimulationView',
    component: ProductVirtualSimulationView
  },
  {
    path: '/ProductGuidanceControlView',
    name: 'ProductGuidanceControlView',
    component: ProductGuidanceControlView
  },
  {
    path: '/ProductElectronicChartView',
    name: 'ProductElectronicChartView',
    component: ProductElectronicChartView
  },
  {
    path: '/ProductSportsSimulationView',
    name: 'ProductSportsSimulationView',
    component: ProductSportsSimulationView
  },
  {
    path: '/ProductWavePredictionView',
    name: 'ProductWavePredictionView',
    component: ProductWavePredictionView
  },
  {
    path: '/ProductDataAnalysisVisualizationView',
    name: 'ProductDataAnalysisVisualizationView',
    component: ProductDataAnalysisVisualizationView
  },
  {
    path: '/ProductEquipmentStatusMonitoringView',
    name: 'ProductEquipmentStatusMonitoringView',
    component: ProductEquipmentStatusMonitoringView
  },
  {
    path: '/ProductDecisionSupportView',
    name: 'ProductDecisionSupportView',
    component: ProductDecisionSupportView
  },
  {
    path: '/ProductManagementView',
    name: 'ProductManagementView',
    component: ProductManagementView
  },
  {
    path: '/ProductExamineView',
    name: 'ProductExamineView',
    component: ProductExamineView
  },
  {
    path: '/aboutErp',
    name: 'AboutProductERP',
    component: AboutProductERP
  },
  {
    path: '/aboutRetail',
    name: 'AboutProductRetail',
    component: AboutProductRetail
  },
  {
    path: '/aboutTransactions',
    name: 'AboutOnlineTransactions',
    component: AboutOnlineTransactions
  },
  {
    path: '/aboutEducation',
    name: 'AboutOnlineEducation',
    component: AboutOnlineEducation
  },
  {
    path: '/CaseShipMotion',
    name: 'CaseShipMotion',
    component: CaseShipMotion
  },
  {
    path: '/CaseElectronicChart',
    name: 'CaseElectronicChart',
    component: CaseElectronicChart
  },
  {
    path: '/CaseFaultAnalysis',
    name: 'CaseFaultAnalysis',
    component: CaseFaultAnalysis
  },
  {
    path: '/CaseSeaWaveInformation',
    name: 'CaseSeaWaveInformation',
    component: CaseSeaWaveInformation
  },
  {
    path: '/CaseEnvironmentTroopsGeneration',
    name: 'CaseEnvironmentTroopsGeneration',
    component: CaseEnvironmentTroopsGeneration
  },
  {
    path: '/CaseGrassrootsOrganizationalEducation',
    name: 'CaseGrassrootsOrganizationalEducation',
    component: CaseGrassrootsOrganizationalEducation
  },
  {
    path: '/CustomerCaseMobileStation',
    name: 'CustomerCaseMobileStation',
    component: CustomerCaseMobileStation
  },
  {
    path: '/CaseFullDimensionalData',
    name: 'CaseFullDimensionalData',
    component: CaseFullDimensionalData
  },
  {
    path: '/CaseSoundPropagation',
    name: 'CaseSoundPropagation',
    component: CaseSoundPropagation
  },
  {
    path: '/CaseERPView',
    name: 'CaseERPView',
    component: CaseERPView
  },
  {
    path: '/CaseJingmeiView',
    name: 'CaseJingmeiView',
    component: CaseJingmeiView
  },
  {
    path: '/CaseInventoryManagementView',
    name: 'CaseInventoryManagementView',
    component: CaseInventoryManagementView
  },
  {
    path: '/CaseProductionInformatizationView',
    name: 'CaseProductionInformatizationView',
    component: CaseProductionInformatizationView
  },
  {
    path: '/CaseLarkView',
    name: 'CaseLarkView',
    component: CaseLarkView
  },
  {
    path: '/CaseInternationalFreightForwarderView',
    name: 'CaseInternationalFreightForwarderView',
    component: CaseInternationalFreightForwarderView
  },
  {
    path: '/CaseRencaiyunView',
    name: 'CaseRencaiyunView',
    component: CaseRencaiyunView
  },
  {
    path: '/CaseYunzhanOnlineView',
    name: 'CaseYunzhanOnlineView',
    component: CaseYunzhanOnlineView
  },
]

const router = new VueRouter({
  mode: 'history', //编译时需要注释这一句，否则无法进行跳转
  base: process.env.BASE_URL,
  routes
})

// 解决跳转到当前路由报错问题
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
  // 重写路由的push方法，捕捉异常并继续运行程序
//   return originalPush.call(this, location).catch(err => err)
// }

export default router
