<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" :isShort="true" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px'}">
            <div class="caseMainFunctionLargeDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/jiaoyihui/computer.png">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" />
                    </div>
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem5" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem6" :isLong="true" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem7" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem8" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem5" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem6" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/jiaoyihui/icon1.png'
import icon2 from '../assets/case/jiaoyihui/icon2.png'
import icon3 from '../assets/case/jiaoyihui/icon3.png'
import icon4 from '../assets/case/jiaoyihui/icon4.png'
import icon5 from '../assets/case/jiaoyihui/icon5.png'
import icon6 from '../assets/case/jiaoyihui/icon6.png'
import icon7 from '../assets/case/jiaoyihui/icon7.png'
import icon8 from '../assets/case/jiaoyihui/icon8.png'
import pic1 from '../assets/case/jiaoyihui/projectPic1.jpg'
import pic2 from '../assets/case/jiaoyihui/projectPic2.jpg'
import pic3 from '../assets/case/jiaoyihui/projectPic3.jpg'
import pic4 from '../assets/case/jiaoyihui/projectPic4.jpg'
import pic5 from '../assets/case/jiaoyihui/projectPic5.png'
import pic6 from '../assets/case/jiaoyihui/projectPic6.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseInternationalFreightForwarderView',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '620',
            projectPic:'427',
            topTitle: '云平台在线交易会',
            pojectOverviewMsg: '“云平台在线交易会”是一个创新的贸易平台，旨在为国际货代行业提供全新的交易和合作机会。该项目结合了云计算和在线交易技术，通过数字化展览和在线交流，为货代企业与供应链伙伴搭建了一个高效便捷的商务交流平台。展商超过千家，观展人数预计20万人次。每家参展商可以拥有独立在线展位，每个展位拥有三个独立洽谈窗口，随时与观展客户在线视频洽谈、在线文档演示以及在线签约。同时，每个观展参嘉宾可以通过云展平台预约展商、1v1视频洽谈，参与看论坛、看推介、看直播、对话主播、查看名录、视频回放等活动',
            iconItem1: {title:'直播间', text: '提供在线的直播间，吸引潜在客户和合作伙伴进入洽谈', icon: icon1},
            iconItem2: {title:'供应链合作拓展', text: '寻找合适的供应链伙伴，促进供应链协同和拓展合作网络', icon: icon2},
            iconItem3: {title:'专业论坛和培训', text: '提供行业专业论坛和培训课程，分享行业动态和最佳实践', icon: icon3},
            iconItem4: {title:'多样化展示', text: '在线视频洽谈、在线文档演示和在线签约', icon: icon4},
            iconItem5: {title:'交易保障', text: '设立交易保障机制和支付安全措施，确保交易的安全可靠', icon: icon5},
            iconItem6: {title:'信息发布', text: '提供信息发布平台，供货代企业发布新闻、产品信息等', icon: icon6},
            iconItem7: {title:'需求匹配', text: '通过线上逛展，与潜在客户和合作伙伴进行需求匹配', icon: icon7},
            iconItem8: {title:'数据云存储和共享', text: '提供数据云存储和共享功能，方便货代企业存储业务数据', icon: icon8},
            picItem1: {title:'直播间', pic: pic1},
            picItem2: {title:'交易会', pic: pic2},
            picItem3: {title:'论坛', pic: pic3},
            picItem4: {title:'在线交易', pic: pic4},
            picItem5: {title:'信息发布', pic: pic5},
            picItem6: {title:'观展记录', pic: pic6},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 1,
            currentType: 1,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 387.5;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.322;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    },
}
</script>