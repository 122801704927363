<template>
    <div class="involve">
        <div class="involveDiv">
            <div style="position: absolute; height: 6.25%; width: 100%;">
                <p class="title" style="color: #333; text-align: center; background-color: #f6f6f6;">涉及领域</p>
            </div>
            <div style="position: absolute; margin-top: 4.5%; height: 22.8%; width: 100%;">
                <p class="textMsg" style="color: #333; text-align: center;">产品应用领域涵盖军事、民用、线上、线下等不同领域，综合C++，<br>
                    Python，JAVA，Unity等开发语言，满足不同场景使用，为您打造高品质方案</p>
            </div>
            <div class="involveClassify">
                <div class="involveIconDiv">
                    <div class="involveIcon">
                        <img src="../assets/home/involveIcon1.png" alt="涉及领域小图标1">
                        <img src="../assets/home/involveIconHover1.png" alt="涉及领域悬停小图标1">
                    </div>
                    <div class="involveIconTextDiv">
                        <span class="topText" style="text-align: center; color: #333;">国防军工</span>
                    </div>
                </div>
                <div class="involveIconDiv">
                    <div class="involveIcon">
                        <img src="../assets/home/involveIcon2.png" alt="涉及领域小图标2">
                        <img src="../assets/home/involveIconHover2.png" alt="涉及领域悬停小图标2">
                    </div>
                    <div class="involveIconTextDiv">
                        <span class="topText" style="text-align: center; color: #333;">车辆</span>
                    </div>
                </div>
                <div class="involveIconDiv">
                    <div class="involveIcon">
                        <img src="../assets/home/involveIcon3.png" alt="涉及领域小图标3">
                        <img src="../assets/home/involveIconHover3.png" alt="涉及领域悬停小图标3">
                    </div>
                    <div class="involveIconTextDiv">
                        <span class="topText" style="text-align: center; color: #333;">航海</span>
                    </div>
                </div>
                <div class="involveIconDiv">
                    <div class="involveIcon">
                        <img src="../assets/home/involveIcon4.png" alt="涉及领域小图标4">
                        <img src="../assets/home/involveIconHover4.png" alt="涉及领域悬停小图标4">
                    </div>
                    <div class="involveIconTextDiv">
                        <span class="topText" style="text-align: center; color: #333;">制造</span>
                    </div>
                </div>
                <div class="involveIconDiv">
                    <div class="involveIcon">
                        <img src="../assets/home/involveIcon5.png" alt="涉及领域小图标5">
                        <img src="../assets/home/involveIconHover5.png" alt="涉及领域悬停小图标5">
                    </div>
                    <div class="involveIconTextDiv">
                        <span class="topText" style="text-align: center; color: #333;">医疗</span>
                    </div>
                </div>
                <div class="involveIconDiv">
                    <div class="involveIcon">
                        <img src="../assets/home/involveIcon6.png" alt="涉及领域小图标6">
                        <img src="../assets/home/involveIconHover6.png" alt="涉及领域悬停小图标6">
                    </div>
                    <div class="involveIconTextDiv">
                        <span class="topText" style="text-align: center; color: #333;">音视频</span>
                    </div>
                </div>
                <div class="involveIconDiv">
                    <div class="involveIcon">
                        <img src="../assets/home/involveIcon7.png" alt="涉及领域小图标8">
                        <img src="../assets/home/involveIconHover7.png" alt="涉及领域悬停小图标8">
                    </div>
                    <div class="involveIconTextDiv">
                        <span class="topText" style="text-align: center; color: #333;">教育</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeInvolvedField',
}
</script>

<style>
.involve{
    position: relative; 
    /* padding-top: 100px; */
    width: 100%;
    height: 100%;
    z-index: 15;
    background-color: #f6f6f6;
    
}
.involveDiv{
    position: absolute; 
    width: 100%; 
    height:85%; 
    bottom: 0;
    background-image: url('../assets/home/HomeInvolvedFieldBackgroundImg.png'); 
    background-size: 100% 94.7%; 
    background-position: bottom; 
    z-index: 20;
}
.involveClassify{
    top: 36.5%; 
    position: absolute; 
    left:50%; 
    transform: translateX( -50% ); 
    height:33.3%; 
    width: 65%; 
    display: flex; 
    justify-content: space-between; 
}
.involveIconDiv{
    position: relative; 
    width: 9%; 
    height: 100%;
}
.involveIconTextDiv{
    width: 100%; 
    position: absolute; 
    bottom: 0;
}

/* 小图标普通及悬停样式 */
.involveIcon{
    position: relative;
    width: 100%; 
    height: 63%;
}
.involveIcon img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.2s ease;
  }
.involveIcon img:not(:first-child) {
    opacity: 0;
}

.involveIcon:hover img:first-child {
    opacity: 0;
}

.involveIcon:hover img:not(:first-child) {
    opacity: 1;
}
</style>