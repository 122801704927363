<template>
    <div style="position: relative; width: 100%; height: 100%; z-index: 20; background-color: #f6f6f6;">
        <div class="case">
            <div class="caseLeftDiv">
                <div style="position: absolute; writing-mode: vertical-lr; top: 6.2%; left: 11% ;">
                    <p class="title" style="color: #fff;">典型案例</p>
                </div>
                <div style="position: absolute; top: 51%; left: -18%; width: 67.85%; height: 6px; background-color: #ff9000; border-radius: 3px;" />
                <div style="position: absolute; top: 59.4%; left:50%; transform: translateX( -50% ); width: 72%; height: 15%;">
                    <p class="textMsg" style="color: #fff; text-indent: 2em;">根据企业特色定制专业的解决方案，提升企业市场竞争力，多家企业的的选择</p>
                </div>
                <div style="position: absolute; bottom: 0; left:50%; transform: translateX( -50% ); width: 18%; height: 19%;">
                    <div style="position: relative; height: 50%;">
                        <button @click="changeImg(-1)" class="caseBtn">
                            <img class="caseBtnImg" src="../assets/home/upButton.png"/>
                            <img class="caseBtnImgHover" src="../assets/home/upButtonHover.png"/>
                        </button>
                    </div>
                    <div style="height: 50%; position: relative;">
                       <button @click="changeImg(1)" class="caseBtn">
                            <img class="caseBtnImg" src="../assets/home/downButton.png"/>
                            <img class="caseBtnImgHover" src="../assets/home/downButtonHover.png"/>
                        </button> 
                    </div>
                </div>
            </div>
            <div style="width: 81%; height: 100%;">
                <div style="width: 100%; height: 87%;">
                    <div style="width: 100%; height: 100%; position: relative;" ref="imageContainer">
                        <img class="caseImg" :src="caseImgs[index]" :key="index" alt="客户案例">
                    </div>
                </div> 
                <div class="caseTextDiv" style="position: relative; width: 100%; height: 13%; background-color: #fff;">
                    <p class="textMsg" style="position: absolute; color: #333; width: 96%; text-indent: 2em; left: 2%; top: 50%; transform: translateY( -50% ); ">
                        {{ caseText }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import img1 from '../assets/home/caseImg1.webp'
import img2 from '../assets/home/caseImg2.webp'
import img3 from '../assets/home/caseImg3.webp'
import img4 from '../assets/home/caseImg4.webp'

export default {
    name: 'HomeCustomerCases',
    data() {
        return {
            index: 0,
            caseImgs: [img1, img2, img3, img4],
            expandHeight: 0,
            caseText: '某院校设备仿真模拟训练系统，通过三维模型模拟真实设备操作，主要训练学员拆装、测量、操作、开关机等，不仅可以实现设备操控还可以模仿设备中的软件操作。熟悉设备的各部分结构，以及操作规范，大大提高了实际设备的使用寿命，降低维修成本',
            caseTexts: ['某院校设备仿真模拟训练系统，通过三维模型模拟真实设备操作，主要训练学员拆装、测量、操作、开关机等，不仅可以实现设备操控还可以模仿设备中的软件操作。熟悉设备的各部分结构，以及操作规范，大大提高了实际设备的使用寿命，降低维修成本',
            '提供s57标准的电子海图应用相关接口，用户可实现电子海图的显示、海图标绘使用管理及电子海图相关的二次开发，基于Qt进行接口封装，采用Mingw版本进行编译，海图接口包括：各个海图数据加载；海图拼接显示，海图缩放，平移操作；海图各图层分层显示；海图坐标计算，方位距离计算；标绘显示。标绘定制，支持鼠标与键盘操作；标绘管理', 
            '云平台在线交易会展商超过千家，观展人数预计20万人次。每家参展商可以拥有独立在线展位，每个展位拥有三个独立洽谈窗口，随时与观展客户在线视频洽谈、在线文档演示和在线签约。同时，每个观展参嘉宾可以通过云展平台预约展商、1v1视频洽谈，参与看论坛、看推介、看直播、对话主播、查看名录、视频回放等活动', 
            '精密制造ERP系统集订单管理、生产投产、排产计划、每日日报、外协加工、库存管理、统计分析、大屏显示等业务流程为一体的管理系统。不仅可以精准的根据模板安排生产排产进度，还可以根据日报的信息统计出所有订单的生产进度以及每道工序的剩余生产量和不良生产记录，做出最终的产品工序分析，方便产品工序工艺的改进'],
        }
    },
    mounted() {
        this.calcRateImg();
        //启动监听：窗口发生变化重新计算图片所占大小
        window.addEventListener('resize', this.calcRateImg)
    },
    methods: {
        calcRateImg(){
            this.expandHeight = document.body.clientHeight * 0.135;
        },
        changeImg(changeIndex){ //点击切换按钮
            let _this = this;
            this.index = (this.index + changeIndex + 4) % 4;
            this.caseText = this.caseTexts[this.index];
        },
    },
}
</script>

<style>
.case{
    height: 130%; 
    width: 71%; 
    position: absolute; 
    top: -13%; 
    left: 50%; 
    transform: translateX( -50% ); 
    display: flex;
}
.caseLeftDiv{
    position: relative; 
    width: 19%; 
    height: 100%; 
    background-color: #070f40;
    background-image: url('../assets/home/homeCaseBackgroundImg.jpg'); 
    background-size: cover; 
    background-position: center;
}
.caseBtn{
    background-color: rgba(0, 0, 0, 0); 
    height: 63%; 
    border: none; 
    padding: 0;
}
/* 轮播按钮 */
.caseBtn img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 60%;
    transition: opacity 0.3s ease; /* 添加过渡效果，使图片切换更平滑 */
}
.caseBtn .caseBtnImgHover{
    opacity: 0;
}
.caseBtn:hover .caseBtnImgHover{
    opacity: 1;
}
.caseBtn:hover .caseBtnImg{
    opacity: 0;
}

.caseImg{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s, height 0.5s;
}
</style>