<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px'}">
            <div class="caseMainFunctionLargeDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/jingmei/computer.png">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" :isLong="true" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" />
                    </div>
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem5" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem6" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem7" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem8" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem5" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem6" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/jingmei/icon1.png'
import icon2 from '../assets/case/jingmei/icon2.png'
import icon3 from '../assets/case/jingmei/icon3.png'
import icon4 from '../assets/case/jingmei/icon4.png'
import icon5 from '../assets/case/jingmei/icon5.png'
import icon6 from '../assets/case/jingmei/icon6.png'
import icon7 from '../assets/case/jingmei/icon7.png'
import icon8 from '../assets/case/jingmei/icon8.png'
import pic1 from '../assets/case/jingmei/projectPic1.jpg'
import pic2 from '../assets/case/jingmei/projectPic2.png'
import pic3 from '../assets/case/jingmei/projectPic3.png'
import pic4 from '../assets/case/jingmei/projectPic4.png'
import pic5 from '../assets/case/jingmei/projectPic5.png'
import pic6 from '../assets/case/jingmei/projectPic6.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseJingmeiView',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '620',
            projectPic:'427',
            topTitle: '新零售自助柜机',
            pojectOverviewMsg: '“新零售自助柜机”旨在为消费者提供便捷、快速的购物体验。通过自助柜机，消费者可以自主选择商品并完成支付，实现无人值守的购物过程。该项目结合了先进的自动化技术、支付系统和数据管理系统，实现了商品展示、库存管理、支付与结算、订单管理等功能。同时，该项目还提供促销活动展示和数据统计分析，帮助商家了解消费者偏好和优化销售策略。通过远程监控与管理，商家可以实时掌握自助柜机的运行状况和销售数据，提高设备稳定性和运营效率',
            iconItem1: {title:'自助购物', text: '消费者自主选择商品并完成支付，实现无人值守的购物体验', icon: icon1},
            iconItem2: {title:'商品展示', text: '展示各类商品的信息、图片和价格，商品描述和规格参数', icon: icon2},
            iconItem3: {title:'库存管理', text: '实时监控自助柜机中商品的库存，及时更新商品的库存数量', icon: icon3},
            iconItem4: {title:'支付与结算', text: '支持多种支付方式，如现金、银行卡、移动支付等', icon: icon4},
            iconItem5: {title:'订单管理', text: '记录消费者的购买订单，包括购买时间、商品信息和支付方式', icon: icon5},
            iconItem6: {title:'补货管理', text: '管理补货人员，配送车辆，待补货设备以及补货路线', icon: icon6},
            iconItem7: {title:'数据统计与分析', text: '收集自助柜机的销售数据，生成销售报表和数据分析图表', icon: icon7},
            iconItem8: {title:'远程监控与管理', text: '实时监测自助柜机的运行状态、库存情况和销售数据', icon: icon8},
            picItem1: {title:'自助售货机', pic: pic1},
            picItem2: {title:'商品管理', pic: pic2},
            picItem3: {title:'设备管理', pic: pic3},
            picItem4: {title:'补货管理', pic: pic4},
            picItem5: {title:'订单管理', pic: pic5},
            picItem6: {title:'数据统计', pic: pic6},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 1,
            currentType: 2,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 387.5;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.322;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    },
}
</script>