<template>
    <div class="projectMilitaryFeaturesLineDiv">
        <div class="projectMilitaryFeaturesImgDiv">
            <img :src="item.img">
        </div>
        <div class="projectMilitaryFeaturesTextDiv">
            <p class="textTitle" :style="{left:this.titleLeft+'px'}">{{ item.title }}</p>
            <div class="projectMilitaryFeaturesRightText">
                <p class="textMsg" :style="{width:this.width+'px', left:this.left+'px'}">{{ item.text }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectMilitaryFeaturesRight',
    props:{
        item:{
            type: Object,
            required: true,
        },
        changeWidth:{
            type: Number,
            default: 0,
        }
    },
    data(){
        return{
            isActive: false,
            width: 380,
            left: 30,
            titleLeft: 72.5,
        }
    },
    created() {
        this.init();
    },
    methods: {
        init(){
            switch (this.changeWidth){
                case 1:
                    this.width = 396;
                    this.left = 22;
                    this.titleLeft = 64.5;
                    break;
                case 2:
                    this.width = 361;
                    this.left = 40;
                    this.titleLeft = 82;
                    break;
            }
        },
        mouseOver() {
            this.isActive = true;
        },
        mouseLeave() {
            this.isActive = false;
        },
    },
}
</script>

<style>
.projectMilitaryFeaturesLineDiv{
    display: flex; 
    justify-content: space-between; 
    width: 100%; 
    height: 385px; 
}
.projectMilitaryFeaturesImgDiv{
    position: relative; 
    width: 525px; 
    height: 385px; 
}
.projectMilitaryFeaturesImgDiv img{
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate( -50%,-50% );
}
.projectMilitaryFeaturesTextDiv{
    position: relative; 
    width: 525px; 
    height: 385px; 
    background-image: url('../assets/ProjectMilitaryFeaturesBgimg.png');
    background-size: cover;
    background-position: center;
}
.projectMilitaryFeaturesTextDiv .textTitle{
    color: #333; 
    position: absolute; 
    top: 50px; 
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 2px;
    white-space: pre-line;
}
.projectMilitaryFeaturesRightText{
    width: 439px; 
    height: 227px; 
    position: absolute; 
    bottom: 0; 
    left: 50%; 
    transform: translateX( -50% ); 
    background-image: linear-gradient(-90deg, rgba(101, 113, 127, 0.9), rgba(50, 59, 70, 0.9)); 
    /* background-image: linear-gradient(-90deg, #65717f 0%, #323b46 100%);  */
    border-radius: 8px;
}
.projectMilitaryFeaturesRightText .textMsg{
    color: #fff; 
    position: absolute; 
    /* width: 380px;
    left: 30px; */
    top: 50%;
    transform: translateY( -50% ); 
    white-space: pre-line;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 1.1px;
    margin: 0;
}
</style>