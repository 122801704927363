<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px', height:this.mainFunctionLarge+'px'}">
            <div class="caseMainFunctionLargeDiv" style="height: 86.6%;">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/hailang/computer.png" style="height: 65.1%; ">
                </div>
                <div class="caseMainFunctionIconLine" style="height: 55.6%; width: 65%;">
                    <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                    <CaseMainFunctionIconDiv :iconItem="iconItem2" />
                    <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/hailang/icon1.png'
import icon2 from '../assets/case/hailang/icon2.png'
import icon3 from '../assets/case/hailang/icon3.png'
import pic1 from '../assets/case/hailang/projectPic1.png'
import pic2 from '../assets/case/hailang/projectPic2.png'
import pic3 from '../assets/case/hailang/projectPic3.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseSeaWaveInformation',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '447',
            projectPic:'851',
            topTitle: '海浪信息及海浪预报软件',
            pojectOverviewMsg: '“海浪信息及预报软件”旨在提供准确、实时的海浪数据和预报信息，帮助海事工作者、海洋研究人员和水上活动爱好者更好地了解和预测海浪的情况。通过收集、分析和处理海洋观测数据、数值模拟结果和卫星遥感数据等多种信息源，生成海浪的相关参数和预报结果。用户可以根据自己的需求选择特定的海域或位置，并获取相应的海浪信息，包括波高、波长、波速、波向等。还提供用户友好的界面和操作流程，使用户能够轻松地访问和解读海浪数据和预报信息。用户可以直观地查看海浪参数的图表和图像展示，以及预报结果的可视化呈现',
            iconItem1: {title:'海浪信息数据维护及管理', text: '', icon: icon1},
            iconItem2: {title:'在界面中调用MATLAB方法对各个算法进行实现', text: '', icon: icon2},
            iconItem3: {title:'实现算法结果显示及海浪预报', text: '', icon: icon3},
            picItem1: {title:'浪谱分析', pic: pic1},
            picItem2: {title:'预测结果谱分析', pic: pic2},
            picItem3: {title:'预测算法实现', pic: pic3},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 3,
            currentType: 0,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 278;
                this.projectPic = 532;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.233;
                this.projectPic = document.body.clientWidth * 0.443;
            }
        },
    },
}
</script>