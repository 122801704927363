<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" :isLong="true" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px'}">
            <div class="caseMainFunctionLargeDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/jingmizhizao/computer.png">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" />
                    </div>
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem5" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem6" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem7" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem8" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem5" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem6" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/jingmizhizao/icon1.png'
import icon2 from '../assets/case/jingmizhizao/icon2.png'
import icon3 from '../assets/case/jingmizhizao/icon3.png'
import icon4 from '../assets/case/jingmizhizao/icon4.png'
import icon5 from '../assets/case/jingmizhizao/icon5.png'
import icon6 from '../assets/case/jingmizhizao/icon6.png'
import icon7 from '../assets/case/jingmizhizao/icon7.png'
import icon8 from '../assets/case/jingmizhizao/icon8.png'
import pic1 from '../assets/case/jingmizhizao/projectPic1.png'
import pic2 from '../assets/case/jingmizhizao/projectPic2.png'
import pic3 from '../assets/case/jingmizhizao/projectPic3.png'
import pic4 from '../assets/case/jingmizhizao/projectPic4.png'
import pic5 from '../assets/case/jingmizhizao/projectPic5.png'
import pic6 from '../assets/case/jingmizhizao/projectPic6.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseERPView',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '620',
            projectPic:'427',
            topTitle: '精密制造ERP系统',
            pojectOverviewMsg: '“精密制造ERP系统”是一个面向精密制造行业的企业资源规划（ERP）解决方案，旨在帮助企业管理和优化生产过程、提高生产效率和产品质量。该系统整合了供应链管理、生产计划、物料管理、质量控制、成本核算等功能模块，提供全面的业务管理和决策支持。通过实时数据采集、分析和报告，该系统能够提供准确的生产计划、库存控制、供应商管理等信息，帮助企业实现资源的高效利用和生产的精细化管理。同时，该系统还具备灵活性和可扩展性，可以根据企业的特定需求进行定制和集成，以适应不同规模和类型的精密制造企业',
            iconItem1: {title:'物料管理', text: '确保物料供应的及时性和准确性，避免生产中断或库存过剩', icon: icon1},
            iconItem2: {title:'生产计划', text: '分析订单以及库存情况，确定最优生产计划，并进行生产调度', icon: icon2},
            iconItem3: {title:'质量控制', text: '监控生产过程中的关键环节，并能够追溯到原材料的质量信息', icon: icon3},
            iconItem4: {title:'成本核算', text: '对生产过程中的各项成本进行准确计算和核算', icon: icon4},
            iconItem5: {title:'客户管理', text: '收集和维护详细的客户信息记录，包括联系人资料、历史交易', icon: icon5},
            iconItem6: {title:'供应商管理', text: '实现供应链的协同管理，确保物料供应的稳定性和质量可控性', icon: icon6},
            iconItem7: {title:'外协管理', text: '提高生产效率、降低生产成本、扩大生产规模', icon: icon7},
            iconItem8: {title:'数据分析与报告', text: '收集、整理和分析生产过程中的数据，生成各类报表和指标', icon: icon8},
            picItem1: {title:'物料清单', pic: pic1},
            picItem2: {title:'班组管理', pic: pic2},
            picItem3: {title:'客户管理', pic: pic3},
            picItem4: {title:'供应商管理', pic: pic4},
            picItem5: {title:'财务管理', pic: pic5},
            picItem6: {title:'数据分析', pic: pic6},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 0,
            currentType: 2,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 387.5;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.322;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    },
}
</script>