<template>
  <div class="about">
    <!-- <div class="aboutNav" /> -->
    <!-- -----------------------在线教育 -->
    <CaseTop :topTitle="topTitle" :topType="topType" />

    <!-- ----------------------------产品概述 -->

    <ProjectMilitaryOverview :overViewText='overViewText' :isShort="true" />


    <!-- ---------------------------应用场景 -->
    <div class="three" :style="{ width: this.threeWidth.width + 'px' }" style="height: 673px;">
      <div class="font3">
        <p>应用场景</p>
      </div>
      <div class="box">
        <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
      </div>
    </div>

    <!-- --------------------------------------- 我们支持 -->
    <div class="four" :style="{ width: this.fourWidth.width + 'px' }" style="height: 804px;">
      <div class="educationFont4">
        <p style="width:9% ;height:20%">我们支持</p>
      </div>
      <div class="onlineAll" style="width: 100%; height:650px;position: relative; margin-top: 60px;">
        <div class="online-all" style="width: 1206px; height:594px">
          <!-- 上面一排四个 -->
          <div class="topEducation" style="width: 1206px; height: 307px">

            <div class="top-education">
              <AboutOnlineBlue :goodItem="bearItem1"></AboutOnlineBlue>
            </div>

            <div class="top-education">
              <AboutOnlineBlue :goodItem="bearItem2"></AboutOnlineBlue>
            </div>

            <div class="top-education">
              <AboutOnlineBlue :goodItem="bearItem3"></AboutOnlineBlue>
            </div>

            <div class="top-education">
              <AboutOnlineBlue :goodItem="bearItem4"></AboutOnlineBlue>
            </div>
          </div>

          <!-- 下面一排四个 -->
          <div class="bottomEducation" style="width: 1206px; height:284px">
            <div class="bottom-education">
              <AboutOnlineBlue :goodItem="bearItem5"></AboutOnlineBlue>
            </div>

            <div class="bottom-education">
              <AboutOnlineBlue :goodItem="bearItem6"></AboutOnlineBlue>
            </div>

            <div class="bottom-education">
              <AboutOnlineBlue :goodItem="bearItem7"></AboutOnlineBlue>
            </div>

            <div class="bottom-education">
              <AboutOnlineBlue :goodItem="bearItem8"></AboutOnlineBlue>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- --------------------------------硬件支持 -->
    <div class="AboutOneHardwareSupport" :style="{ width: this.fiveWidth.width + 'px' }" style="height:535px">
      <!-- 实际案例 -->
      <Others :index="index" :currentType="currentType" :topType="topType" :arrayIndex="0" />
    </div>

    <!-- 关于我们 -->
    <div :style="{ width: this.sixWidth.width + 'px', height: this.sixWidth.height + 'px' }">
      <contactUs :pageType="1" />
    </div>

  </div>
</template>

<script>
// 顶部标题
import CaseTop from '../components/CaseTop.vue'
// 关于我们
import contactUs from '../components/contactUs.vue'
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
// 优势特点
import AboutOnlineBlue from '../components/AboutOnlineBlue.vue'
// 应用领域
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
// 申请试用
import AboutOneApplyTryDiv from '../components/AboutOneApplyTryDiv.vue'
// 实际案例
import Others from '../components/Others.vue'


// 应用领域
import areaImg1 from '../assets/AboutOnlineEducation/part1.png'
import areaImg2 from '../assets/AboutOnlineEducation/part2.png'
import areaImg3 from '../assets/AboutOnlineEducation/part3.png'
import areaImg4 from '../assets/AboutOnlineEducation/part4.png'

// 在线支持
import goodImg1 from '../assets/AboutOnlineEducation/sustain1.png'
import goodImg2 from '../assets/AboutOnlineEducation/sustain2.png'
import goodImg3 from '../assets/AboutOnlineEducation/sustain3.png'
import goodImg4 from '../assets/AboutOnlineEducation/sustain4.png'
import goodImg5 from '../assets/AboutOnlineEducation/sustain5.png'
import goodImg6 from '../assets/AboutOnlineEducation/sustain6.png'
import goodImg7 from '../assets/AboutOnlineEducation/sustain7.png'
import goodImg8 from '../assets/AboutOnlineEducation/sustain8.png'


export default {
  name: 'AboutOneView',
  components: {
    CaseTop,
    AboutOnlineBlue,
    contactUs,
    ProjectMilitaryOverview,
    AboutOneApplyTryDiv,
    AboutOneApplicationArea,
    Others
  },
  mounted() {
    //加载后先计算一遍缩放比例
    this.Scale();
    //生成一个监听器：窗口发生变化从新计算计算一遍缩放比例
    window.addEventListener('resize', this.Scale);

    // 实际案例部分

    //获取图片样式
    this.slides = document.querySelectorAll('.slide');
    //根据索引居中
    this.centerSlide(this.currentIndex);
    // 定时器  自动轮播，每3秒切换一次图片
    this.startTime();

  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.startTime);
  },
  data() {
    return {
      oneWidth: {
        width: '1920',
        height: '400'
      },
      twoWidth: {
        width: '1919',
        height: '472'
      },
      threeWidth: {
        width: '1919',
        height: '614'
      },
      fourWidth: {
        width: '1920',
        height: '802'
      },
      fiveWidth: {
        width: '1920',
        height: '2001'
      },
      sixWidth: {
        width: '1920',
        height: '533'
      },
      // 顶部标题
      topTitle: '在线教育',
      topType: 0,

      // 产品概述
      overViewText: '通过电脑、手机或平板电脑等设备进行网络学习。这种教育方式提供了灵活的学习安排，学生可以根据自己的时间表进行学习，不受地点限制，可以在家中、办公室或其他场所接受教育。在线教育的课程类型丰富，包括语言学习、职业培训、学位课程等，具有诸多优点，如灵活性、可访问性、成本效益高等',
      // 在线支持
      bearItem1: { title: '播放控制', text: '进度条拖动控制，视频倍速播放、学习进度自己管控', icon: goodImg1, iconWidth: '24.7', iconHeight: '49',iocnTop:'30',iconRight:'35' },
      bearItem2: { title: '直播间面对面', text: '一对一、一对多、多对多等不掉线，不卡顿，轻松畅聊', icon: goodImg2, iconWidth: '28', iconHeight: '55',iocnTop:'23',iconRight:'35' },
      bearItem3: { title: '视频热区分析', text: '统计视频学习时长，支持按时间线分析观看次数', icon: goodImg3, iconWidth: '25', iconHeight: '53' ,iocnTop:'26',iconRight:'34'},
      bearItem4: { title: '线上题库', text: '多种题型，多媒体内容、多种刷题模式、智能阅卷', icon: goodImg4, iconWidth: '24', iconHeight: '48',iocnTop:'30' ,iconRight:'36'},
      bearItem5: { title: '可视化数据报表', text: '按课程课时、视频，深入分析学员学习进度及课程效果', icon: goodImg5, iconWidth: '25', iconHeight: '54',iocnTop:'25',iconRight:'35' },
      bearItem6: { title: '多终端跨平台', text: 'PC网页端、移动APP、H5网页、微信小程序', icon: goodImg6, iconWidth: '25', iconHeight: '50' ,iocnTop:'32',iconRight:'37'},
      bearItem7: { title: '多技术投入', text: '视频转码技术、视频缓存技术', icon: goodImg7, iconWidth: '24', iconHeight: '47' ,iocnTop:'32',iconRight:'35'},
      bearItem8: { title: '过程回放', text: '直播结束后，通过回放功能查看直播过程', icon: goodImg8, iconWidth: '25', iconHeight: '50',iocnTop:'32',iconRight:'35' },

      // 实际案例
      index: -1,
      currentType: 1,

      carText1: '机动实操考核系统',
      carText2: '设备台站模拟训练系统',
      carText3: '红蓝双方兵力对抗系统',
      carImage1: areaImg1,
      carImage2: areaImg2,
      carImage3: areaImg3,
      carFieldImgs: [areaImg1, areaImg2, areaImg3],
      carFieldTexts: ['机动实操考核系统', '设备台站模拟训练系统', '红蓝双方兵力对抗系统'],

      currentIndex: 0,//当前显示的图片索引

      intervalId: null, //用于存储定时器ID


      // 应用领域
      info: [

        { p1: '网上教学', span: '网上授课、线上解读的方法，提升了时间和空间的限定，学生能够反复学习培训录播课程多方面推进，并根据线上答疑，立即在非在学校时间段解决困难', isActive: false, backgroundImage: areaImg1 },
        { p1: '在线题库', span: '试题类产品以做题进入，解决教师或是学生自身花时间批阅，而且能及时得出正误分辨，全自动梳理错题集，闹钟提醒备考和推进，巨大缓解了学生们的精力和头脑压力', isActive: false, backgroundImage: areaImg2 },
        { p1: '在线答疑', span: '在线答疑类产品只需将题照相放进特定输入框，软件就可以全自动在线搜索题中答案，并装有详尽解题思路和全过程。答疑类产品解决了学生刷题全过程中的一部分艰难', isActive: false, backgroundImage: areaImg3 },
        { p1: '家校互动', span: '家校互动服务于教学资源访问及学生情况追踪，拓宽合理布局教学资源和“智慧课堂”的线上教育服务，重点围绕教学课堂，为老师备课教案讲课、学生学生自主学习、父母课外辅导出示多方位的服务', isActive: false, backgroundImage: areaImg4 }
      ],
    }
  },
  methods: {
    Scale() {
      if (document.body.clientWidth < 1200) {
        this.oneWidth.width = 1200;
        this.oneWidth.height = 250;

        this.twoWidth.width = 1200;
        // this.twoWidth.height = 295

        this.threeWidth.width = 1200;
        // this.threeWidth.height = 616;

        this.fourWidth.width = 1200;
        // this.fourWidth.height = 503

        this.fiveWidth.width = 1200;
        // this.fiveWidth.height = 1251

        this.sixWidth.width = 1200;
        this.sixWidth.height = 333
      } else {
        this.oneWidth.height = 0.21 * document.body.clientWidth;
        this.oneWidth.width = document.body.clientWidth;

        // this.twoWidth.height = 0.25 * document.body.clientWidth;
        this.twoWidth.width = document.body.clientWidth;
        // 0.32 * document.body.clientWidth;
        // this.threeWidth.height = 616;
        this.threeWidth.width = document.body.clientWidth;

        // this.fourWidth.height = 0.42 * document.body.clientWidth;
        this.fourWidth.width = document.body.clientWidth;

        // this.fiveWidth.height = 1.04 * document.body.clientWidth;
        this.fiveWidth.width = document.body.clientWidth;

        this.sixWidth.height = 0.28 * document.body.clientWidth;
        this.sixWidth.width = document.body.clientWidth;
      }
    },

    /*锁定选定的盒子判断他是否选定 */
    // divClick(index) {
    //   /** 当 “点击” 一个“已被点击”的div时 使该div恢复默认色 */
    //   // 遍历info数组中的每个对象
    //   for (let i of this.info) {
    //     // 判断当前对象是否和传入的索引index对应 如果是，则取反
    //     if (index === this.info.indexOf(i)) {
    //       i.isActive = !i.isActive
    //     } else {
    //       i.isActive = false;
    //     }
    //   }
    // },

    // 鼠标滑过时，显示背景图片和修改文字颜色
    mouseOver(index) {
      this.info[index].isActive = true;
    },

    // 鼠标离开时则恢复默认
    mouseLeave(index) {
      this.info[index].isActive = false;
    },


    /*给选定的盒子修改样式 */
    colorDiv(index) {
      if (this.info[index].isActive) {
        return {
          backgroundImage: `url(${this.info[index].backgroundImage})`,
          color: 'white'
        };
      } else {
        return {
          backgroundImage: 'none',
          '.p1 p': {
            color: '#333333;'
          },
          '.span span': {
            color: 'black'
          }
        }
      }
    },

    /*
    实际案例部分轮播切换
     */
    showSlide(index) {
      this.carImage1 = this.carFieldImgs[index];
      this.carText1 = this.carFieldTexts[index];
      this.carImage2 = this.carFieldImgs[++index % 3];
      this.carText2 = this.carFieldTexts[index % 3];
      this.carText3 = this.carFieldTexts[++index % 3];
      this.carImage3 = this.carFieldImgs[index % 3];
      this.currentIndex = (++index) % 3;
    },
    //居中显示
    centerSlide(index) {
      this.currentIndex = index;
      this.showSlide(index);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.carFieldImgs.length;
      this.currentIndex = (this.currentIndex + 1) % this.carFieldTexts.length;
      this.showSlide(this.currentIndex);
    },

    // 定时器 自动轮播
    startTime() {
      this.intervalId = setInterval(this.nextSlide, 5000);
    },

    chanageImg(index) {
      /* 重置计时器 */
      clearInterval(this.intervalId);
      this.currentIndex = index;
      //  // 将被点击的图片居中显示
      // this.centerSlide(this.currentIndex);

      //计算需要移动的步数
      let steps = index - this.currentIndex;

      // 如果步数大于轮播图一半的长度，向前滚动到达目标图片
      if (Math.abs(steps) > this.slides.length / 2) {
        steps = steps > 0 ? steps - 3 : steps + 3;
      }

      // 更新 currentIndex，使目标图片移动到中间位置
      this.currentIndex = (this.currentIndex + steps + 3) % 3;

      // 将目标图片居中显示
      this.centerSlide(this.currentIndex);

      //重新启动定时器
      this.startTime();
    },

    // 主要功能部分切换功能
    changeContent() {
      // 在页面加载完成后先执行这个
      // document.addEventListener("DOMContentLoaded",function(){
      //   // 获取第一个菜单项
      //   var firstMenuItem = document.querySelector('.menu-router');
      //   // 让他点击第一个菜单项
      //   firstMenuItem.click();
      // })

      // 获取元素
      // var tab_list = document.querySelector('.menu');
      var list = document.querySelectorAll('.menu-router');
      var item = document.querySelectorAll('.contentItem');

      // item[0].style.display='block';
      // 获取第一个导航项
      var firstNavItem = document.querySelectorAll('.menu-router')[6];
      //获取第一个导航项对应的内容
      var firstContentItem = document.querySelectorAll('.contentItem')[6];
      // 给导航项添加样式
      firstNavItem.classList.add('menuActive');
      // 让第一导航项对应内容默认显示
      firstContentItem.style.display = 'block';

      // 分别绑定点击事件
      for (var i = 0; i < list.length; i++) {
        // 在这里创建属性，添加索引
        list[i].setAttribute('index', i);
        list[i].onclick = function () {
          // 1.第一大步骤，先解决导航栏的颜色切换
          // 所有的 menu-router清除class类
          for (var i = 0; i < list.length; i++) {
            list[i].classList.remove('menuActive');
          }
          // 自己添加上这个类
          this.classList.add('menuActive');
          // 2.第二大步骤：下面模块跟着选项卡一一匹配
          // 给上面的tab_list里面所有的li添加索引
          var index = this.getAttribute('index');
          console.log(index);
          // 先全部隐藏
          for (var j = 0; j < item.length; j++) {
            item[j].style.display = 'none';
          }
          // 当前的显示
          item[index].style.display = 'block';
        }
      }
    },


  },
}

</script>


<style>
.about {
  width: 100%;
  height: auto;
}

.aboutNav {
  width: 100%;
  height: 90px;
  background-color: #000;
}


.three {
  /* width: 1202px;
	height: 663px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  background-color: #f6f6f6;
}

.font3 {
  width: 100%;
  height: 116px;
}

.font3 p {
  font-family: MySourceHanSansSC-Heavy;
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 2px;
  color: #333333;
  white-space: nowrap;
  margin: 0 auto;
}

.box {
  width: 1200px;
  height: 390px;
  display: flex;
  justify-content: space-between;
  /*间隔均匀排列*/
  justify-content: center;
  /* margin: 0 auto; */
  margin: 0;
}

.four {
  background-image: url(' ../assets/mainFunctions.jpg ');
  background-size: cover;
}

.educationFont4 {
  width: 100%;
  height: 95px;
  display: flex;
  white-space: nowrap;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  /*水平居中 */
  /*align-items: center; 垂直居中*/
  position: relative;
  /*相对定位 */
}

.educationFont4 p {
  font-family: SourceHanSansSC-Heavsy;
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  /* line-height: 30px; */
  letter-spacing: 4px;
  color: #ffffff;
  margin: 0;
  position: absolute;
  /*绝对定位 */
  bottom: 15%;
  /*让他的下边距为0 */
  /* margin-top: 50%; */
  /* margin-bottom: 50%; */
}


.onlineAll{
  display: flex;
  flex-direction: column;
  /*让整个容器垂直排列 */
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
}

.online-all {
  /* margin: 0 auto; */
}


.topEducation {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.bottomEducation{
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.top-education {
  width: 23%;
  height: 91%;
  background-color: #ffffff;
  border-radius: 8px;
}

.bottom-education {
  width: 23%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
}

.AboutOneHardwareSupport {
  /* margin: 0 auto; */
  /* display: flex;
 justify-content: center; */
  /* margin-top: 4%; */
  background-color: #f6f6f6;
}


/* 实际案例标题 */
.font6 h2 {
  font-family: SourceHanSansSC-Heavy;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 4px;
  color: #333333;
  margin: 0;
}


/* -----------------1201-1366之间---------------- */
@media (min-width: 1201px) and (max-width:1366px) {

  /* 实际案例标题 */
  .font6 h2 {
    font-size: 25px;
  }

}


/* -----------------1367-1600之间---------------- */
@media (min-width: 1367px) and (max-width:1600px) {

  /* 实际案例标题 */
  .font6 h2 {
    font-size: 30px;
  }
}


/* -----------------1600以上---------------- */
@media (min-width: 1600px) {

  /* 实际案例标题 */
  .font6 h2 {
    font-size: 40px;
  }


}
</style>