<template>
        <div class="caseProjectPicDiv" :style="{width:this.caseProjectPicSize.width+'%', height:this.caseProjectPicSize.height+'%'}">
            <p class="textTitle">{{ picItem.title }}</p>
            <img :src="picItem.pic" :alt="picItem.title1">
        </div>
</template>

<script>
export default {
    name: 'CaseProjectPic',
    data() {
        return {
            screenWidth: '1920',
            projectPic:'427',
        }
    },
    props:{
        picItem:{
            type: Object,
            required: true,
        },
        caseProjectPicSize:{
            type: Object,
            required: true,
        }
    },
    mounted () {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    }
}
</script>

<style>
.caseProjectPicDiv{
    /* height: 100%; 
    width: 100%;  */
    position: relative;
    overflow: hidden;
}
.caseProjectPicDiv .textTitle{
    color: #fff; 
    position: absolute; 
    left: 50%; 
    top: 50%; 
    transform: translate( -50%, -50% );
    z-index: 5;
}
.caseProjectPicDiv img{
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
    /* 向浏览器预告元素即将进行的变化，从而优化渲染性能 */
    will-change: transform;
    object-fit: cover;
}
.caseProjectPicDiv:hover img{
    transform: scale(1.1);
    transform-origin: center;
    object-fit: cover;
}
</style>