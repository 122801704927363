<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 产品概述 -->
        <ProjectMilitaryOverview :overViewText='overViewText' :isMilitary="true" />
        <!-- 应用领域 -->
        <div class="projectMilitaryApply">
            <p class="title">应用领域</p>
            <div class="projectMilitaryApplyBox">
                <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
            </div>
        </div>
        <!-- 产品特色 -->
        <div class="projectMilitaryFeaturesDiv">
            <p class="title">产品特色</p>
            <div class="projectMilitaryFeaturesArea">
                <ProjectMilitaryFeaturesRight :item="item1" />
                <ProjectMilitaryFeaturesLeft :item="item2" />
                <ProjectMilitaryFeaturesRight :item="item3" />
                <ProjectMilitaryFeaturesLeft :item="item4" />
            </div>
        </div>
        <!-- 其他案例 -->
        <Others :index="-1" :currentType="0" :arrayIndex="6" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import areaImg1 from '../assets/virtualSimulation/areaImg1.webp'
import areaImg2 from '../assets/virtualSimulation/areaImg2.webp'
import areaImg3 from '../assets/virtualSimulation/areaImg3.webp'
import areaImg4 from '../assets/virtualSimulation/areaImg4.webp'
import featureImg1 from '../assets/virtualSimulation/featureImg1.png'
import featureImg2 from '../assets/virtualSimulation/featureImg2.png'
import featureImg3 from '../assets/virtualSimulation/featureImg3.png'
import featureImg4 from '../assets/virtualSimulation/featureImg4.png'

import CaseTop from '../components/CaseTop.vue'
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
import ProjectMilitaryFeaturesRight from '../components/ProjectMilitaryFeaturesRight.vue'
import ProjectMilitaryFeaturesLeft from '../components/ProjectMilitaryFeaturesLeft.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'ProductVirtualSimulationView',
    components: {
        CaseTop,
        ProjectMilitaryOverview,
        AboutOneApplicationArea,
        ProjectMilitaryFeaturesRight,
        ProjectMilitaryFeaturesLeft,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 0,
            topTitle: '虚拟仿真软件',
            // 产品概述
            overViewText: '导调控制是指通过对工程、设备、系统等进行调度、管理和控制，以实现安全、高效、稳定运行的过程。它主要应用于电力、交通、通信、水利、石油等大型工程和系统的运行管理，优化资源利用、提高生产效率、保障设备安全、维护系统稳定；并且在导调控制由导调控制软件、便捷式导控终端、中心计算机与服务器等硬件组成，是部队演练信息采集与验证中的重要环节，对支撑演训全过程的导调控制，保障演训顺利进行具有重要作用',
            // 应用领域
            info: [
                { p1: '军事领域', span: '主要实现演习前训练计划的配置，演习中的作战态势显示、过程导调干预、实时评判裁决，演习后演练过程回放、评估分析等功能', isActive: false, backgroundImage: areaImg1 },
                { p1: '电力领域', span: '导调控制是指通过对电力生产、输送和分配进行调度来保障电网的稳定运行。导调员根据系统的负荷情况、发电设备的状态以及电力市场的需求等因素，制定合理的发电计划和输电方案，并通过监控和调整电力系统运行状态来实现电力平衡和电网稳定', isActive: false, backgroundImage: areaImg2 },
                { p1: '交通领域', span: '导调控制是指对公共交通系统进行调度和控制，以确保车辆运行的准时和顺畅。导调员通过监控车辆的运行情况、旅客的需求以及交通状况，调度车辆的发车时间和行驶路线，以最大限度地满足旅客的出行需求', isActive: false, backgroundImage: areaImg3 },
                { p1: '通信领域', span: '导调控制是指对通信网络进行调度和管理，以确保数据传输的质量和稳定性。导调员根据网络负载情况、数据流量以及故障情况，调度网络资源的分配和数据传输路径，以保障通信服务的稳定和可靠', isActive: false, backgroundImage: areaImg4 }
            ],
            // 产品特色
            item1: {title: '支持多种渲染效果', text: '渲染进度支持实时渲染\n渲染效果支持粒子效果、天气效果、扫描效果\n渲染质感支持曲线贴图、光影模式', img: featureImg1},
            item2: {title: '极致的体验感', text: '打造可持续高性能运行的系统\n实现各类场景的融合应用\n模型容量大，显示速度快，使用流畅', img: featureImg2},
            item3: {title: '支持多种接口', text: '拥有丰富的前端及数据端服务接口\n提供二次开发接口\n支持嵌入第三方接口', img: featureImg3},
            item4: {title: '支持多种功能', text: '支持漫游、角度切换、定位等操作\n支持装配、测量、拆卸、碰撞等动作\n支持二维三维联动、考试、评估等数据分析', img: featureImg4},
        }
    },
}
</script>

<style>
/* 应用领域 */
.projectMilitaryApply{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative; 
  width: 100%; 
  height: 673px; 
  background-color: #e9ecf1;
}
.projectMilitaryApply .title{
    color: #333; 
    position: absolute; 
    top: 100px; 
    left: 50%; 
    transform: translateX( -50% );
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 4px;
}
.projectMilitaryApplyBox{
  width: 1200px;
  height: 390px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  margin: 0;
  position: absolute; 
  top: 205px; 
  left: 50%; 
  transform: translateX( -50% );
}

/* 产品特色 */
.projectMilitaryFeaturesDiv{
    width: 100%; 
    height: 1958px; 
    background-color: #f6f6f6; 
    position: relative;
}
.projectMilitaryFeaturesDiv .title{
    color: #333; 
    position: absolute; 
    top: 95px; 
    left: 50%; 
    transform: translateX( -50% );
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 4px;
}
.projectMilitaryFeaturesArea{
    width: 1200px; 
    height: 1762px; 
    position: absolute; 
    bottom: 0; 
    left: 50%; 
    transform: translateX( -50% ); 
    display: flex; 
    flex-direction: column; 
    justify-content: space-between;
}
</style>