<template>
    <div>
        <div class="nav" :style="{ backgroundColor: backgroundColor }">
            <div class="logo"><img src="../assets/navigateLogo.png" /></div>
            <!-- <div class="routerSpace" /> -->
            <div class="routerDiv">
                <div class="router" @mouseover="navExpandDishover" @click="jumptoHome"><p class="textMsg">首页</p></div>
                
                <div class="routerClass" @mouseover="navProductHover" @mouseleave="changeColorProduct" :style="{ backgroundColor: productColor}"><p class="textMsg" :style="{color: productTextColor}">产品中心</p></div>
                <!-- <router-link to="/about" class="nav-title"><div class="router"> <span>解决方案</span> </div></router-link> -->
                <div class="routerClass" @mouseover="navCaseHover" @mouseleave="changeColorCase" :style="{ backgroundColor: caseColor,color: caseTextColor}"><p class="textMsg" :style="{color: caseTextColor}">客户案例</p></div>
                <div class="router" @mouseover="navExpandDishover" @click="jumptoAboutUs"><p class="textMsg">关于威迪欧</p></div>
            </div>
        </div>
        <div v-if="navExpandShow" class="expand" @mouseleave="navExpandDishover" :style="{height:this.expand+'px'}">
            <div v-if="navProductShow" class="navCustomerCases">
                <div class="caseVirtualSimulation">
                    <p class="textMsg" style="font-family: MySourceHanSansSC-Regular; color: #333;">军用软件</p>
                    <div class="navLine" />
                    <div class="list" style="width: 54%; width: 45%;">
                        <router-link exact-active-class="active" class="router-list" to="/ProductVirtualSimulationView">
                            <p class="textMsg" @click="setCurrentIndex(0)">虚拟仿真软件</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/ProductGuidanceControlView">
                            <p class="textMsg" @click="setCurrentIndex(1)">导调控制软件</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/ProductElectronicChartView">
                            <p class="textMsg" @click="setCurrentIndex(2)">电子海图</p>
                        </router-link>
                        <!-- <router-link to="/"><p class="textMsg">模拟训练软件</p></router-link> -->
                        <router-link exact-active-class="active" class="router-list" to="/ProductSportsSimulationView">
                            <p class="textMsg" @click="setCurrentIndex(3)">运动仿真模拟</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/ProductWavePredictionView">
                            <p class="textMsg" @click="setCurrentIndex(4)">海浪预测软件</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/ProductDataAnalysisVisualizationView">
                            <p class="textMsg" @click="setCurrentIndex(5)">数据分析可视化</p>
                        </router-link>
                    </div>
                    <div class="list" style="left: 54.7%; width: 45%;">
                        <router-link exact-active-class="active" class="router-list" to="/ProductDecisionSupportView">
                            <p class="textMsg" @click="setCurrentIndex(6)">辅助决策软件</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/ProductEquipmentStatusMonitoringView">
                            <p class="textMsg" @click="setCurrentIndex(7)">设备状态监测</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/ProductManagementView">
                            <p class="textMsg" @click="setCurrentIndex(8)">管理软件</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/ProductExamineView">
                            <p class="textMsg" @click="setCurrentIndex(9)">考核软件</p>
                        </router-link>
                    </div>
                </div>
                <div class="navVerticalLine" style="left: 41.25%;" />
                <div class="caseIntelligentManufacturing">
                    <p class="textMsg" style="font-family: MySourceHanSansSC-Regular; color: #333;">智能制造</p>
                    <div class="navLine" />
                    <div class="list">
                        <router-link exact-active-class="active" class="router-list" to="/aboutErp">
                            <p class="textMsg" @click="setCurrentIndex(10)">企业信息管理系统（ERP）</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/aboutOne">
                            <p class="textMsg" @click="setCurrentIndex(11)">制造管理系统（MES）</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/aboutWms">
                            <p class="textMsg" @click="setCurrentIndex(12)">仓储管理系统（WMS）</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/aboutRetail">
                            <p class="textMsg" @click="setCurrentIndex(13)">智慧零售系统</p>
                        </router-link>
                    </div>
                </div>
                <div class="navVerticalLine" style="left: 72.5%;" />
                <div class="caseRequirementCustomization">
                    <p class="textMsg" style="font-family: MySourceHanSansSC-Regular; color: #333;">实时音视频</p>
                    <div class="navLine" />
                    <div class="list">
                        <router-link exact-active-class="active" class="router-list" to="/aboutEducation">
                            <p class="textMsg" @click="setCurrentIndex(14)">在线教育</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/aboutRecruit">
                            <p class="textMsg" @click="setCurrentIndex(15)">在线招聘会</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/aboutTransactions">
                            <p class="textMsg" @click="setCurrentIndex(16)">在线交易会</p>
                        </router-link>
                        <!-- <router-link to="/"><p class="textMsg">在线交友</p></router-link> -->
                    </div>
                </div>
            </div>
            <div v-if="navCaseShow" class="navCustomerCases">
                <div class="caseVirtualSimulation">
                    <p class="textMsg" style="font-family: MySourceHanSansSC-Regular; color: #333;">军用软件</p>
                    <div class="navLine" />
                    <div class="list" style="width: 54%;">
                        <router-link exact-active-class="active" class="router-list" to="/CustomerCaseMobileStation" exact>
                            <p class="textMsg" @click="setCurrentIndex(17)">机动台站模拟训练系统</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/CaseElectronicChart" exact>
                            <p class="textMsg" @click="setCurrentIndex(18)">电子海图应用管理软件</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/CaseSoundPropagation">
                            <p class="textMsg" @click="setCurrentIndex(19)">声传播模型仿真软件</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/CaseShipMotion">
                            <p class="textMsg" @click="setCurrentIndex(20)">船舶操纵运动仿真软件</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/CaseSeaWaveInformation">
                            <p class="textMsg" @click="setCurrentIndex(21)">海浪信息及海浪预报软件</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/CaseEnvironmentTroopsGeneration">
                            <p class="textMsg" @click="setCurrentIndex(22)">环境与兵力生成软件</p>
                        </router-link>
                    </div>
                    <div class="list" style="left: 54.7%; width: 45%;">
                        <router-link exact-active-class="active" class="router-list" to="/CaseFullDimensionalData">
                            <p class="textMsg" @click="setCurrentIndex(23)">全维数据展示平台</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/CaseFaultAnalysis">
                            <p class="textMsg" @click="setCurrentIndex(24)">故障智能分析系统</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/CaseGrassrootsOrganizationalEducation">
                            <p class="textMsg" @click="setCurrentIndex(25)">基层组教系统</p>
                        </router-link>
                    </div>
                </div>
                <div class="navVerticalLine" style="left: 41.25%;" />
                <div class="caseIntelligentManufacturing">
                    <p class="textMsg" style="font-family: MySourceHanSansSC-Regular; color: #333;">智能制造</p>
                    <div class="navLine" />
                    <div class="list">
                        <router-link exact-active-class="active" class="router-list" to="/CaseERPView">
                            <p class="textMsg" @click="setCurrentIndex(26)">精密制造ERP系统</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/CaseInventoryManagementView">
                            <p class="textMsg" @click="setCurrentIndex(27)">进销存管理软件</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/CaseProductionInformatizationView">
                            <p class="textMsg" @click="setCurrentIndex(28)">生产信息化管理系统</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/CaseJingmeiView">
                            <p class="textMsg" @click="setCurrentIndex(29)">新零售自助柜机</p>
                        </router-link>
                    </div>
                </div>
                <div class="navVerticalLine" style="left: 72.5%;" />
                <div class="caseRequirementCustomization">
                    <p class="textMsg" style="font-family: MySourceHanSansSC-Regular; color: #333;">实时音视频</p>
                    <div class="navLine" />
                    <div class="list">
                        <router-link exact-active-class="active" class="router-list" to="/CaseLarkView">
                            <p class="textMsg" @click="setCurrentIndex(30)">英语在线教育</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/CaseYunzhanOnlineView">
                            <p class="textMsg" @click="setCurrentIndex(31)">云平台在线招聘</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/CaseInternationalFreightForwarderView">
                            <p class="textMsg" @click="setCurrentIndex(32)">云平台在线交易会</p>
                        </router-link>
                        <router-link exact-active-class="active" class="router-list" to="/CaseRencaiyunView">
                            <p class="textMsg" @click="setCurrentIndex(33)">在线人才招聘会</p>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
      name: 'NavigateView',
      data() {
        return {
            screenWidth: '1920',
            expand:'413',
            navExpandShow: false,
            navProductShow: false,
            navCaseShow: false,
            scrollPosition: '',
            navClass: 'navNoBgColor',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            productColor: 'rgba(0, 0, 0, 0)',
            caseColor: 'rgba(0, 0, 0, 0)',
            productTextColor: '#fff',
            caseTextColor: '#fff',
            currentIndex: -1,
        }
      },
    created() {
        this.$router.beforeEach((to, from, next) => { //前置路由守卫，在每次路由跳转时触发
            window.scrollTo(0, 0)
            this.navExpandDishover(); //收回导航栏
            next(); // 必须调用next()来resolve这个钩子
        });
        this.currentPath = this.$router.path;
    },
    mounted() {
        window.addEventListener('resize', this.calcRate);
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        setCurrentIndex(index){
            if(this.currentIndex == index){
                window.scrollTo(0, 0);
                this.navExpandDishover();
            }else{
                this.currentIndex = index;
            }
        },
        calcRate () {
        if(document.body.clientWidth <= 1200){
            this.screenWidth = 1200;
            this.expand = 258;
        }else{
            this.screenWidth = document.body.clientWidth;
            this.expand = document.body.clientWidth * 0.215;
        }
        },
        handleScroll(){
            if(window.scrollY == 0){
                this.backgroundColor = 'rgba(0, 0, 0, 0)';
            } else{
                this.backgroundColor = 'rgba(0, 0, 0, 0.5)';
            }
        },
        jumptoHome(){
            this.$router.push('/').catch(err => {
                if (!err.name === 'NavigationDuplicated') {
                    // 如果错误不是NavigationDuplicated，则重新抛出
                    throw err;
                }else{
                    window.scrollTo(0, 0)
                }
            });
        },
        jumptoAboutUs(){
            this.$router.push('/aboutUs').catch(err => {
                if (!err.name === 'NavigationDuplicated') {
                    // 如果错误不是NavigationDuplicated，则重新抛出
                    throw err;
                }else{
                    window.scrollTo(0, 0)
                }
            });
        },
        navProductHover(){
            this.navCaseShow = false;
            this.navExpandShow = true;
            this.navProductShow = true;
            this.productColor = '#fff';
            this.productTextColor = '#ff9000';
            this.caseColor = 'rgba(0,0,0,0)';
            this.caseTextColor = '#fff';
        },
        navCaseHover(){
            this.navProductShow = false;
            this.navExpandShow = true;
            this.navCaseShow = true;
            this.productColor = 'rgba(0,0,0,0)'
            this.productTextColor = '#fff';
            this.caseColor = '#fff'
            this.caseTextColor = '#ff9000';
        },
        navExpandDishover(){
            this.caseColor = 'rgba(0,0,0,0)'
            this.caseTextColor = '#fff';
            this.productColor = 'rgba(0,0,0,0)'
            this.productTextColor = '#fff';
            this.navExpandShow = false;
            this.navProductShow = false;
            this.navCaseShow = false;
        },
        changeColorProduct(){
            this.productColor = '#fff'
            this.productTextColor = '#ff9000';
            this.caseColor = 'rgba(0,0,0,0)'
            this.caseTextColor = '#fff';
        },
        changeColorCase(){
            this.productColor = 'rgba(0,0,0,0)'
            this.productTextColor = '#fff';
            this.caseColor = '#fff'
            this.caseTextColor = '#ff9000';
        },
    }
}
</script>
<style>
.nav{
    width: 100%;
    height: 90px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: space-between;
    /*吸顶效果*/
    position: fixed;
    position: -webkit-sticky;    /*!*兼容 -webkit 内核的浏览器*!*/
    top: 0px;                    /*!*必须设一个值，否则不生效*!*/
}
/* .nav:hover{
    background-color: rgba(0, 0, 0, 0.85);
} */
.logo{
    width: 11.25%;
    height: 47px;
    margin-top: 22px;
    margin-left: 40px;
    background-color: rgba(0, 0, 0, 0);
}

/* 路由部分样式 */
.routerDiv{
    position: absolute;
    left: 50%;
    transform: translateX( -50% );
    height: 100%;
    display: flex;
    justify-content: space-between;
    width: 40.2%;
}
.router{
    position: relative;
    width: 25%;
    height: 100%;
    padding: 0;
    cursor:pointer /* 光标悬浮时显示为手型 */
}
.routerClass{
    position: relative;
    width: 25%;
    height: 100%;
    padding: 0;
    cursor:pointer /* 光标悬浮时显示为手型 */
}
.routerClass p{
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 1.1px;
    position: absolute;
    top: 50%;
    transform: translateY( -50% );
    width: 100%;
    margin: 0;
    text-align: center;
}
.routerClass:hover p{
    color: #ff9000;
}
.router p{
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 1.1px;
    position: absolute;
    top: 50%;
    transform: translateY( -50% );
    width: 100%;
    margin: 0;
    color: #fff;
    text-align: center;
}
.router:hover{
    background-color: #fff;
}
.router:hover p{
    color: #ff9000;
}

/* 联系我们按钮 */
.contactBtnDiv{
    width: 10%;
}
.contactBtn{
    position: absolute;
    right: 5.2%;
    top: 50%;
    transform: translateY( -50% );
    width: 93px;
    height: 39px;
    border-radius: 8px;
    border: solid 1px #ffffff;
    background-color: rgba(0, 0, 0, 0);
    font-family: MySourceHanSansSC-Regular;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 2px;
    color: #ffffff;
}

/* 指针悬浮展开部分 */
.expand{
    position: relative;
    top: 90px;
	background-color: #fff;
	opacity: 0.95;
}

/* 产品中心 */
.navProduct{
    position: absolute;
    left: 50%;
    transform: translateX( -50% );
    height: 63.2%;
    top: 21.8%;
    display: flex;
    justify-content: space-between;
}
/* 虚拟仿真 */
.productVirtualSimulation{
    width: 20.5%; 
    height: 100%;
}
.productVirtualSimulation p{
    position: absolute;
    left: 50%;
    transform: translateX( -50% );
    bottom: 0;
    color: #333;
}
.productVirtualSimulation:hover p {
    color: #ff9000;
}
/* 智能制造 */
.productIntelligentManufacturing{
    width: 18.6%;
    height: 100%;
}
.productIntelligentManufacturing p{
    position: absolute;
    left: 50%;
    transform: translateX( -50% );
    bottom: 0;
    color: #333;
}
.productIntelligentManufacturing:hover p {
    color: #ff9000;
}
/* 需求定制 */
.productRequirementCustomization{
    width: 29.6%;
    height: 100%;
}
.productRequirementCustomization p{
    position: absolute;
    left: 50%;
    transform: translateX( -50% );
    bottom: 0;
    color: #333;
}
.productRequirementCustomization:hover p {
    color: #ff9000;
}

/* 客户案例 */
.navCustomerCases{
    width: 62.5%;
    position: absolute;
    left: 50%;
    transform: translateX( -50% );
    height: 59%;
    top: 15.5%;
    display: flex;
}
.navLine{
    position: absolute; 
    width: 35px; 
    height: 2px; 
    background-color: #ff9000; 
    top: 13%;
}
.navVerticalLine{
    position: absolute; 
    height: 80%;
    width: 1px;
    top: 50%;
    transform: translateY( -50% );
    background-color: #cdcdcd;
}
.caseVirtualSimulation{
    position: relative;
    width: 41.25%;
    height: 100%;
}
.caseIntelligentManufacturing{
    position: relative;
    width: 22.5%;
    height: 100%;
    left: 8.3%;
}
.caseRequirementCustomization{
    position: relative;
    width: 19.2%;
    height: 100%;
    left: 18.3%;
}
.list{
    position: absolute; 
    top: 25.8%; 
    left: 0;
    width: 100%;
}
.router-list,.router-list:hover{
    display: block;
    text-decoration: none; /* 移除下划线 */
}
.router-list.active p{
    display: block;
    text-decoration: none; /* 移除下划线 */
    color: #ff9000 !important;
}
.list p{
    color: #333;
}
.list p:hover {
    color: #ff9000 !important;
}
.list .textMsg{
    cursor:pointer
}

@media screen and (max-width: 1200px){
    .routerDiv{
        width: 482.5px;
        left: 360px;
        transform: translateX( 0 );
    }
    .expand{
        width: 1200px;
    }
    .navProduct{
        width: 618px;
    }
}
@media screen and (min-width: 1200px){
    .expand{
        width: 100%;
    }
    .navProduct{
        width: 51.5%;
    }
    .list .textMsg{
        line-height: 20px !important;
    }
}
@media screen and (min-width: 1450px){
    .expand{
        width: 100%;
    }
    .navProduct{
        width: 51.5%;
    }
    .list .textMsg{
        line-height: 23px !important;
    }
}
@media screen and (min-width: 1600px) {
    .expand{
        width: 100%;
    }
    .navProduct{
        width: 51.5%;
    }
    .list .textMsg{
        line-height: 26px !important;
    }
}

</style>