<template>
    <div class="menuContentDiv">
              <div class="contentText" style="width: 35%; height:100%">
                <span :style="{top:contentItem.menuSpanTop+'%'}">
                {{contentItem.menuContent}}
                </span>
                <img   :src="contentItem.backImg" :style="{width:contentItem.backWidth+'%', height:contentItem.backHeight+'%',left:contentItem.backLeft+'%',bottom:contentItem.backBottom+'%'}" style="position: absolute;">
              </div>
              <div class="contentImg" style="width: 54%; height:100%">
                <img  :src="contentItem.menuImg" :style="{width:contentItem.menuWidth+'%', height:contentItem.menuHeight+'%',marginTop:contentItem.menuTop+'%',marginLeft:contentItem.menuLeft+'%'}">
              </div>
    </div>
</template>
<script>
export default {
    name: 'AboutOneMenuContent',
    props:{
      contentItem:{
            type: Object,
            required: true,
        }
    }
}
</script>

<style>
.menuContentDiv{
    width: 100%; 
    height: 100%; 
    display: flex;
    justify-content: space-between;
}
.contentText {
  display: flex;
  position: relative;
  /* text-align: center; */
  text-align: justify;
}

.contentText span {
  position: relative;
  /* top: 16%; */
  font-family: MySourceHanSansSC-Regular;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  color: #333333;
}

.contentImg {
  display: flex;
  position: relative;
  right: 0;
}
</style>