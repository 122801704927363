<template>
    <div class="others">
        <p class="title">{{ title }}</p>
        <div class="othersDiv">
            <div v-show="isSmallShow" class="othersPicSmall" @click="changePic(-1)">
                <p class="textMsg" @click="jumpto(-1)">{{ otherLeftText }}</p>
                <img :src="otherLeftPic" style="width: 100%; height: 100%;">
            </div>
            <div class="othersPicLarge">
                <p class="textMsg" @click="jumpto(0)">{{ otherLargeText }}</p>
                <img :src="otherLargePic" style="width: 100%; height: 100%;">
            </div>
            <div v-show="isLargeShow" class="othersPicLarge">
                <p class="textMsg" @click="jumpto(1)">{{ otherLargeText2 }}</p>
                <img :src="otherLargePic2" style="width: 100%; height: 100%;">
            </div>
            <div v-show="isSmallShow" class="othersPicSmall" @click="changePic(1)">
                <p class="textMsg" @click="jumpto(1)">{{ otherRightText }}</p>
                <img :src="otherRightPic" style="width: 100%; height: 100%;">
            </div>
        </div>
    </div>
</template>

<script>
import military1 from '../assets/case/qitaanli/junyong/chuanboyiundong.png'
import military2 from '../assets/case/qitaanli/junyong/dianzihaitu.png'
import military3 from '../assets/case/qitaanli/junyong/guzhangfenxi.png'
import military4 from '../assets/case/qitaanli/junyong/hailangxinxi.png'
import military5 from '../assets/case/qitaanli/junyong/huanjingyubingli.png'
import military6 from '../assets/case/qitaanli/junyong/jicengzujiao.png'
import military7 from '../assets/case/qitaanli/junyong/jidongtaizhan.png'
import military8 from '../assets/case/qitaanli/junyong/quanweishujuzhanshi.png'
import military9 from '../assets/case/qitaanli/junyong/shengchuanbo.png'
import audio1 from '../assets/case/qitaanli/yinshipin/bailingniao.png'
import audio2 from '../assets/case/qitaanli/yinshipin/guojihuodai.png'
import audio3 from '../assets/case/qitaanli/yinshipin/rencaiyun.png'
import audio4 from '../assets/case/qitaanli/yinshipin/yunzhanzaixian.png'
import manufacture1 from '../assets/case/qitaanli/zhizao/erp.png'
import manufacture2 from '../assets/case/qitaanli/zhizao/jingmeilingshou.png'
import manufacture3 from '../assets/case/qitaanli/zhizao/jinxiaocun.png'
import manufacture4 from '../assets/case/qitaanli/zhizao/shengchanxinxihua.png'

export default {
    name: 'OthersMilitary',
    data() {
        return {
            id: 0,
            currentIndex: 0,
            length: 0,
            isSmallShow: true,
            isLargeShow: false,
            militaryTexts: ['船舶运动操纵仿真软件', '电子海图应用管理软件', '故障智能分析系统', '海浪信息及海浪预报软件', '环境与兵力生成软件', 
            '基层组教管理系统', '机动台站模拟训练系统', '全维数据展示平台', '声传播模型仿真软件'],
            militaryPics: [military1, military2, military3, military4, military5, military6, military7, military8, military9],
            militaryRouters: ['/CaseShipMotion', '/CaseElectronicChart', '/CaseFaultAnalysis', '/CaseSeaWaveInformation', '/CaseEnvironmentTroopsGeneration', 
                '/CaseGrassrootsOrganizationalEducation', '/CustomerCaseMobileStation', '/CaseFullDimensionalData', '/CaseSoundPropagation'],
            audioTexts: ['英语在线教育', '云平台在线交易会', '在线人才招聘会', '云平台在线招聘'],
            audioPics: [audio1, audio2, audio3, audio4],
            audioRouters: ['/CaseLarkView', '/CaseInternationalFreightForwarderView', '/CaseRencaiyunView', '/CaseYunzhanOnlineView'],
            manufactureTexts: ['精密制造ERP系统', '新零售自助柜机', '进销存管理软件', '生产信息化管理软件'],
            manufacturePics: [manufacture1, manufacture2, manufacture3, manufacture4],
            manufactureRouters: ['/CaseERPView', '/CaseJingmeiView', '/CaseInventoryManagementView', '/CaseProductionInformatizationView'],
            otherTexts: [],
            otherPics: [],
            otherRouters: [],
            otherLargePic: '',
            otherLargePic2: '',
            otherLeftPic: '',
            otherRightPic: '',
            otherLargeText: '',
            otherLargeText2: '',
            otherLeftText: '',
            otherRightText: '',
            title: '',
        }
    },
    props:{
        index:{
            type: Number,
            required: true,
        },
        currentType:{
            type: Number,
            required: true,
        },
        arrayIndex:{
            type: Number,
            default: -1,
        },
    },
    mounted () {
        window.addEventListener('resize', this.calcRate);
        this.otherInitPic();
    },
    methods: {
        setPic(){
            this.otherLargePic = this.otherPics[this.currentIndex];
            this.otherLeftPic = this.otherPics[(this.currentIndex - 1 + this.length) % this.length];
            this.otherRightPic = this.otherPics[(this.currentIndex + 1) % this.length];
            this.otherLargeText = this.otherTexts[this.currentIndex];
            this.otherLeftText = this.otherTexts[(this.currentIndex - 1 + this.length) % this.length];
            this.otherRightText = this.otherTexts[(this.currentIndex + 1) % this.length];
        },
        otherInitPic(){
            /* 若index为-1则说明是产品页面，需要仅显示对应案例，案例页面中显示除当前页面案例外的所有案例 */
            let other = document.getElementsByClassName('others')[0];
            let otherDiv = document.getElementsByClassName('othersDiv')[0];
            if(this.index == -1){
                this.isSmallShow = false;
                otherDiv.style.justifyContent = "center";
                other.style.backgroundColor = '#f6f6f6';
                this.title = '实际案例';
                switch (this.currentType){ //0-军用，1-音视频，2-制造
                    case 0:
                        this.otherLargeText = this.militaryTexts[this.arrayIndex];
                        this.otherLargePic = this.militaryPics[this.arrayIndex];
                        this.otherRouters = this.militaryRouters;
                        break;
                    case 1:
                        this.otherRouters = this.audioRouters;
                        if(this.arrayIndex == 2){
                            this.otherLargeText = this.audioTexts[this.arrayIndex];
                            this.otherLargeText2 = this.audioTexts[this.arrayIndex + 1];
                            this.otherLargePic = this.audioPics[this.arrayIndex];
                            this.otherLargePic2 = this.audioPics[this.arrayIndex + 1];
                            otherDiv.style.justifyContent = "space-around";
                            this.isLargeShow = true;
                            break;
                        }
                        this.otherLargeText = this.audioTexts[this.arrayIndex];
                        this.otherLargePic = this.audioPics[this.arrayIndex];
                        break;
                    case 2:
                        this.otherLargeText = this.manufactureTexts[this.arrayIndex];
                        this.otherLargePic = this.manufacturePics[this.arrayIndex];
                        this.otherRouters = this.manufactureRouters;
                        break;
                }
                return;
            }
            otherDiv.style.justifyContent = "space-between";
            other.style.backgroundColor = '#eee';
            this.title = '其他案例';
            switch (this.currentType){ //0-军用，1-音视频，2-制造
                case 0:
                    this.otherTexts = this.militaryTexts;
                    this.otherPics = this.militaryPics;
                    this.otherRouters = this.militaryRouters;
                    break;
                case 1:
                    this.otherTexts = this.audioTexts;
                    this.otherPics = this.audioPics;
                    this.otherRouters = this.audioRouters;
                    break;
                case 2:
                    this.otherTexts = this.manufactureTexts;
                    this.otherPics = this.manufacturePics;
                    this.otherRouters = this.manufactureRouters;
                    break;
            }
            this.otherTexts.splice(this.index, 1);
            this.otherPics.splice(this.index, 1);
            this.otherRouters.splice(this.index, 1);
            this.length = this.otherPics.length;
            this.setPic();
            this.autoplay();
        },
        changePic(change){
            this.currentIndex = (this.currentIndex + change + this.length) % this.length;
            this.setPic();
            /* 重置计时器 */
            clearInterval(this.id);
            this.autoplay();
        },
        carousel(){
            this.currentIndex = ++this.currentIndex % this.length;
            this.setPic();
        },
        autoplay(){
            let _this = this;
            this.id=setInterval(function(){
                _this.carousel();
            },5000)
        },
        jumpto(changeIndex){
            if(this.index == -1){
                if(this.arrayIndex == 2 && this.currentType == 1){
                    console.log(this.otherRouters[this.arrayIndex + changeIndex]);
                    this.$router.push(this.otherRouters[this.arrayIndex + changeIndex]);
                    return;
                }
                this.$router.push(this.otherRouters[this.arrayIndex]);
            }else{
                let routerIndex = (this.currentIndex + changeIndex + this.length) % this.length;
                this.$router.push(this.otherRouters[routerIndex]);
            }
        },
    }
}
</script>

<style>
.others{
    width: 100%;
    position: relative;
    height: 530px;
    /* background-color: #eee; */
}
.others .title{
    position: absolute;
    top: 18.9%;
    left: 50%;
    transform: translateX( -50% );
}
.othersDiv{
    position: absolute;
    display: flex;
    /* justify-content: space-around; */
    align-items: center; /* 水平居中 */
    bottom: -20%;
    left: 50%;
    transform: translateX( -50% );
    height: 79.2%;
    width: 1200px;
    z-index: 5;
}
.othersPicSmall{
    position: relative;
    width: 27.6%;
    height: 71.4%;
	background-color: #ffffff;
	border-radius: 8px;
}
.othersPicLarge{
    position: relative;
    width: 38.5%;
    height: 100%;
	background-color: #ffffff;
	border-radius: 8px;
}
.othersPicSmall .textMsg{
    position: absolute;
    bottom: 5%;
    left: 4.5%;
    color: #fff;
    font-size: 16px;
    line-height: 32px;
    text-shadow: 1px 1px 1px rgba(2, 4, 11, 0.24);
    cursor:pointer
}
.othersPicLarge .textMsg{
    position: absolute;
    bottom: 3.6%;
    left: 4.3%;
    color: #fff;
    font-size: 16px;
    line-height: 32px;
    text-shadow: 1px 1px 1px rgba(2, 4, 11, 0.24);
    cursor:pointer
}
</style>