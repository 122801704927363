<template>
    <div class="onlineIcon">
        <div class="online-img">
        <img :src="goodItem.icon" :style="{width:goodItem.iconWidth+'%', height:goodItem.iconHeight+'%' ,top:goodItem.iocnTop+'%',left:goodItem.iconRight+'%'}" >
        </div>

        <div class="onlineText">
            <p class="textMsg">{{goodItem.title}}</p>
        </div>

        <div class="onlineTitle">
            <p class="smallText">{{goodItem.text}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutOnlineBlue',
    props:{
        goodItem:{
            type: Object,
            required: true,
        }
    }
}
</script>

<style>
.onlineIcon{
    width:100%; 
    height: 100%; 
}

.online-img{
    position: relative; 
    height: 45%;
    width: 100%;
    /* left: 11.2%; 
    top: 9.3%; */
}

.online-img img{
    position: absolute; 
    /* width: 24.1%; 
    height: 22.8%;  */
    /* top: 24%;*/
    margin: 0;
}
.onlineText{
    display: flex;
  position: relative;
  justify-content: center;
  margin: 0;
    width: 100%; 
    height: 18%; 
}
.onlineText .textMsg{
    position: absolute;
    top: 0;
    font-family: MySourceHanSansSC-Heavy;
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 30px;
	letter-spacing: 2px;
	color: #333333;
}

.onlineTitle{
    width: 86%; 
    height: 37%; 
    display: flex;
  position: relative;
  margin: 0 auto;
}

.onlineTitle .smallText{
    position: absolute;
    top: 0;
    font-family: MySourceHanSansSC-Regular;
	font-size: 16px;
    /* text-align: justify; */
    /*文字两端对齐 */
	line-height: 27px;
	letter-spacing: 2px;
	color: #333333;
    left: 3%;
}
</style>