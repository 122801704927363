<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 产品概述 -->
        <ProjectMilitaryOverview :overViewText='overViewText' :isMilitary="true" />
        <!-- 应用领域 -->
        <div class="projectMilitaryApply">
            <p class="title">应用领域</p>
            <div class="projectMilitaryApplyBox">
                <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
            </div>
        </div>
        <!-- 产品特色 -->
        <div class="projectMilitaryFeaturesDiv">
            <p class="title">产品特色</p>
            <div class="projectMilitaryFeaturesArea">
                <ProjectMilitaryFeaturesRight :item="item1" />
                <ProjectMilitaryFeaturesLeft :item="item2" />
                <ProjectMilitaryFeaturesRight :item="item3" />
                <ProjectMilitaryFeaturesLeft :item="item4" :changeWidth="1" />
            </div>
        </div>
        <!-- 其他案例 -->
        <Others :index="-1" :currentType="0" :arrayIndex="1" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import areaImg1 from '../assets/electronicChart/areaImg1.webp'
import areaImg2 from '../assets/electronicChart/areaImg2.webp'
import areaImg3 from '../assets/electronicChart/areaImg3.webp'
import areaImg4 from '../assets/electronicChart/areaImg4.webp'
import featureImg1 from '../assets/electronicChart/featureImg1.png'
import featureImg2 from '../assets/electronicChart/featureImg2.png'
import featureImg3 from '../assets/electronicChart/featureImg3.png'
import featureImg4 from '../assets/electronicChart/featureImg4.png'

import CaseTop from '../components/CaseTop.vue'
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
import ProjectMilitaryFeaturesRight from '../components/ProjectMilitaryFeaturesRight.vue'
import ProjectMilitaryFeaturesLeft from '../components/ProjectMilitaryFeaturesLeft.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'ProductElectronicChartView',
    components: {
        CaseTop,
        ProjectMilitaryOverview,
        AboutOneApplicationArea,
        ProjectMilitaryFeaturesRight,
        ProjectMilitaryFeaturesLeft,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 0,
            topTitle: '电子海图',
            // 产品概述
            overViewText: '“电子海图”是一种数字海图，也称为矢量海图。是国际海道测量组织（IHO）制定的数字水文数据传输标准（s-57）涵盖了与海上航行相关的图纸海图的所有信息，例如海岸线、等深线、水深、信标（灯塔和灯浮标）、危险和海上航线等数据信息，我司产品支持C++、C#、vs、qt二次开发，符合国际海事组织（IHO） 制定的数据标准 S57 和显示标准 S52 ，具备业界领先的刷新显示速度，设计自定义不同样式，自由控制每个细节，可以上传和创建自定义数据，大幅度减轻配置成本，实现海图标绘、计算经纬度、航程计算、航路检测、航迹重放等功能',
            // 应用领域
            info: [
                { p1: '海洋军事', span: '海图是各级指挥机关研究海区特点，制定训练、作战计划和组织指挥合成军队登陆与抗登陆作战的重要依据，是舰船航行、训练、作战的重要资料', isActive: false, backgroundImage: areaImg1 },
                { p1: '海洋工程', span: '海图是海洋工程设计和实施规划的基本图件，如海港建设、开辟新航道、铺设海底电缆和管线、搭建油井平台、开凿海底隧道等都离不开海底地形图、海底地质构造图、海底沉积物等', isActive: false, backgroundImage: areaImg2 },
                { p1: '海洋资源开发', span: '在开发海洋资源的过程中，需要绘制海底地形图、海洋地质构造图、海洋重力图和海洋磁力图，这些图件成为开发海洋矿产资源的基本用图', isActive: false, backgroundImage: areaImg3 },
                { p1: '渔业作业', span: '渔船作业范围已经扩展到远海、远洋，渔业对海图的需求数量越来越多。水产养殖业的规划、分布等都需要海图保障', isActive: false, backgroundImage: areaImg4 }
            ],
            // 产品特色
            item1: {title: '显示功能', text: '电子海图能够以类似于纸质海图的方式显示视觉效果，包括海图放大、漫游、分层显示和特定物标的突出显示，显示海图中的所有信息或仅显示特定层的信息，有助于聚焦于关键信息', img: featureImg1},
            item2: {title: '标绘计算功能', text: '允许用户在海图上进行点、线、面、文字或其它图形符号的标绘，这主要用于航线设计、风流压差的标绘等\n常见的计算功能包括经纬度计算、两点间距离和方位计算以及航程计算，这些功能简化了航海人员的工作，并提高了航海的精确性和安全性', img: featureImg2},
            item3: {title: '航路检测报警', text: '该功能检查当前位置与预定位置的匹配情况，并在偏离计划航线时提供修正方案\n当船舶航行偏离计划航线或驶入有特殊情况的海域时，系统能以语言、图形、文字等形式报警\n系统可以根据航海通告或网上发布的数据及时更新海图数据库，保持海图数据的最新状态', img: featureImg3},
            item4: {title: '航迹重放', text: '系统能记录所有导航信息和船舶处理参数，实时接收雷达信息并与海图信息叠加，协助船舶避碰，便于事故分析和航程复核', img: featureImg4},
        }
    },
}
</script>