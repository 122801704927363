<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 产品概述 -->
        <ProjectMilitaryOverview :overViewText='overViewText' :isMilitary="true" />
        <!-- 应用领域 -->
        <div class="projectMilitaryApply">
            <p class="title">应用领域</p>
            <div class="projectMilitaryApplyBox">
                <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
            </div>
        </div>
        <!-- 产品特色 -->
        <div class="projectMilitaryFeaturesDiv">
            <p class="title">产品特色</p>
            <div class="projectMilitaryFeaturesArea">
                <ProjectMilitaryFeaturesRight :item="item1" />
                <ProjectMilitaryFeaturesLeft :item="item2" />
                <ProjectMilitaryFeaturesRight :item="item3" />
                <ProjectMilitaryFeaturesLeft :item="item4" />
            </div>
        </div>
        <!-- 其他案例 -->
        <Others :index="-1" :currentType="0" :arrayIndex="0" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import areaImg1 from '../assets/sportsSimulation/areaImg1.webp'
import areaImg2 from '../assets/sportsSimulation/areaImg2.webp'
import areaImg3 from '../assets/sportsSimulation/areaImg3.webp'
import areaImg4 from '../assets/sportsSimulation/areaImg4.webp'
import featureImg1 from '../assets/sportsSimulation/featureImg1.png'
import featureImg2 from '../assets/sportsSimulation/featureImg2.png'
import featureImg3 from '../assets/sportsSimulation/featureImg3.png'
import featureImg4 from '../assets/sportsSimulation/featureImg4.png'

import CaseTop from '../components/CaseTop.vue'
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
import ProjectMilitaryFeaturesRight from '../components/ProjectMilitaryFeaturesRight.vue'
import ProjectMilitaryFeaturesLeft from '../components/ProjectMilitaryFeaturesLeft.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'ProductSportsSimulationView',
    components: {
        CaseTop,
        ProjectMilitaryOverview,
        AboutOneApplicationArea,
        ProjectMilitaryFeaturesRight,
        ProjectMilitaryFeaturesLeft,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 0,
            topTitle: '运动仿真模型',
            // 产品概述
            overViewText: '运动仿真模型是一种通过计算机模拟来模拟和预测运动行为的方法，主要用于研究和分析各种不同类型的运动系统，从机械系统到生物系统，从交通流动到天体运动等等，基于数学建模和计算机模拟的技术将现实世界的运动系统抽象成数学模型，经过计算机编程，将数据存储在服务器中，对模拟过程中产生的大量数据进行筛选、统计、分析和可视化等处理，并且使用计算机强大的计算功能进行计算和求解后，将模拟结果进行评估和解释，让用户了解运动系统在不同条件下的行为和响应，比如受力、速度和位置等。结果分析可以验证建模的有效性的准确性，可以为后续的设计优化和决策提供参考依据',
            // 应用领域
            info: [
                { p1: '虚拟现实', span: '运动仿真技术在虚拟现实领域的应用也越来越广泛。通过对虚拟环境中物体的运动进行仿真，可以实现沉浸式体验、互动式设计等功能，提高虚拟现实系统的真实感和逼真程度', isActive: false, backgroundImage: areaImg1 },
                { p1: '工程设计', span: '运动仿真技术在工程设计中也得到了广泛的应用。通过对机械装置、汽车、飞机、船舶等的运动特性进行仿真，可以评估其性能、优化设计方案，减少试验和开发成本', isActive: false, backgroundImage: areaImg2 },
                { p1: '医学研究', span: '运动仿真技术在医学院研究中有着重要的应用。通过对人体运动、姿势、步态等进行仿真，可以帮助医生对疾病、伤病进行诊断和治疗，设计康复训练方案', isActive: false, backgroundImage: areaImg3 },
                { p1: '航天航空', span: '运动仿真几乎在航天航空领域也有着重要的应用。通过对航天器、飞机、火箭等的运动特性进行仿真，可以评估其飞行性能、设计控制系统，确保航天航空任务的成功执行', isActive: false, backgroundImage: areaImg4 }
            ],
            // 产品特色
            item1: {title: '降低成本', text: '运动仿真技术能够完全替代实物测试，避免因为试验场地限制、零部件无法调拨等原因造成的时间和金钱浪费。相比于传统试验方法，运动仿真技术的成本更低，同时又能够保证测试的精度和效率', img: featureImg1},
            item2: {title: '缩短开发周期', text: '传统的产品设计和测试过程需要经历多次试验和修改，而这一过程会消耗大量的时间和人力成本。相比之下，运动仿真技术可以在计算机上进行测试和优化，能够快速得到正确的答案，并且可以根据需求进行灵活的修改', img: featureImg2},
            item3: {title: '提高产品质量', text: '传统试验方法往往只能检验一个产品，而运动仿真技术可以针对多个产品进行测试和分析。运动仿真技术不仅能够预测设计的缺陷和问题，并且还能够优化设计，提高产品的性能和质量', img: featureImg3},
            item4: {title: '面临的挑战', text: '运动仿真技术对计算机性能、软件模型和技术人员的要求较高，需要大量的运算和处理，这也会导致成本的增加。此外，运动仿真技术只是对实物运动的模拟，与实际存在的差异也需要在应用中进行调整和纠正', img: featureImg4},
        }
    },
}
</script>