<template>
  <div class="about">
    <!-- <div class="aboutNav" /> -->
    <!-- -----------------------在线交易会 -->
    <CaseTop :topTitle="topTitle" :topType="topType" />

    <!-- ----------------------------产品概述 -->
    <ProjectMilitaryOverview :overViewText='overViewText' />


    <!-- ---------------------------应用领域 -->
    <div class="three" :style="{ width: this.threeWidth.width + 'px' }" style="height: 673px;">
      <div class="font3">
        <p>应用领域</p>
      </div>
      <div class="box">
        <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
      </div>
    </div>

    <!-- --------------------------------------- 优势特点 -->
    <div class="four" :style="{ width: this.fourWidth.width + 'px' }" style="height: 804px;">
      <div class="transactionsFont4">
        <p style="width:9% ;height:20%">优势特点</p>
      </div>
      <div class="onlineAll" style="width: 100%; height:650px;position: relative; margin-top: 60px;">
        <div class="online-all" style="width: 1206px; height:594px">
          <!-- 上面一排三个 -->
          <div class="topOnline" style="width: 1206px; height: 307px">

            <div class="top-online">
              <AboutOnlineBlue :goodItem="goodItem1"></AboutOnlineBlue>
            </div>

            <div class="top-online">
              <AboutOnlineBlue :goodItem="goodItem2"></AboutOnlineBlue>
            </div>

            <div class="top-online">
              <AboutOnlineBlue :goodItem="goodItem3"></AboutOnlineBlue>
            </div>
          </div>

          <!-- 下面一排三个 -->
          <div class="bottomOnline" style="width: 1206px; height:284px">
            <div class="bottom-online">
              <AboutOnlineBlue :goodItem="goodItem4"></AboutOnlineBlue>
            </div>

            <div class="bottom-online">
              <AboutOnlineBlue :goodItem="goodItem5"></AboutOnlineBlue>
            </div>

            <div class="bottom-online">
              <AboutOnlineBlue :goodItem="goodItem6"></AboutOnlineBlue>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- --------------------------------硬件支持 -->
    <div class="AboutOneHardwareSupport" :style="{ width: this.fiveWidth.width + 'px' }" style="height:535px">
      <!-- 实际案例 -->
      <Others :index="index" :currentType="currentType" :topType="topType" :arrayIndex="1" />
    </div>

    <!-- 关于我们 -->
    <div :style="{ width: this.sixWidth.width + 'px', height: this.sixWidth.height + 'px' }">
      <contactUs :pageType="1" />
    </div>

  </div>
</template>

<script>
// 顶部标题
import CaseTop from '../components/CaseTop.vue'
// 关于我们
import contactUs from '../components/contactUs.vue'
// 产品概述
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
// 优势特点
import AboutOnlineBlue from '../components/AboutOnlineBlue.vue'
// 应用领域
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
// 申请试用
import AboutOneApplyTryDiv from '../components/AboutOneApplyTryDiv.vue'
// 实际案例
import Others from '../components/Others.vue'


// 应用领域
import areaImg1 from '../assets/aboutOnlineTransactions/TraditionalIndustries.png'
import areaImg2 from '../assets/aboutOnlineTransactions/artCulture.png'
import areaImg3 from '../assets/aboutOnlineTransactions/InnovativeTechnology.png'
import areaImg4 from '../assets/aboutOnlineTransactions/healthcare.png'

// 优势特点
import goodImg1 from '../assets/aboutOnlineTransactions/cost1.png'
import goodImg2 from '../assets/aboutOnlineTransactions/cost2.png'
import goodImg3 from '../assets/aboutOnlineTransactions/cost3.png'
import goodImg4 from '../assets/aboutOnlineTransactions/cost4.png'
import goodImg5 from '../assets/aboutOnlineTransactions/cost5.png'
import goodImg6 from '../assets/aboutOnlineTransactions/cost6.png'


export default {
  name: 'AboutOneView',
  components: {
    CaseTop,
    ProjectMilitaryOverview,
    AboutOnlineBlue,
    contactUs,
    AboutOneApplyTryDiv,
    AboutOneApplicationArea,
    Others
  },
  mounted() {
    //加载后先计算一遍缩放比例
    this.Scale();
    //生成一个监听器：窗口发生变化从新计算计算一遍缩放比例
    window.addEventListener('resize', this.Scale);

    // 实际案例部分

    //获取图片样式
    this.slides = document.querySelectorAll('.slide');
    //根据索引居中
    this.centerSlide(this.currentIndex);
    // 定时器  自动轮播，每3秒切换一次图片
    this.startTime();

  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.startTime);
  },
  data() {
    return {
      oneWidth: {
        width: '1920',
        height: '400'
      },
      twoWidth: {
        width: '1919',
        height: '472'
      },
      threeWidth: {
        width: '1919',
        height: '614'
      },
      fourWidth: {
        width: '1920',
        height: '802'
      },
      fiveWidth: {
        width: '1920',
        height: '2001'
      },
      sixWidth: {
        width: '1920',
        height: '533'
      },
      // 顶部标题
      topTitle: '在线交易会',
      topType: 0,

      // 产品概述
      overViewText: '在数字化时代的浪潮下，线上展会展览正成为企业推广与交流的全新方式。与传统的实地展览相比，线上展会展览不受地域限制，以其独特的优势，吸引着越来越多的企业和参与者。线上展会展览是指通过互联网平台实现的虚拟展览，参与者可以在线上浏览展品、与参展商互动交流，无论是传统行业的合作洽谈，还是文化艺术领域的创意展示，都离不开在线交易平台，我司产品拥有多样化展示元素，定制化和个性化体验，以及数据统计分析功能，轻松了解参展人数和观展行为等，有助于企业后续的市场推广和策略调整',
      // 优势特点
      goodItem1: { title: '全球触达', text: '参与者无需跨越时区和地理限制，可以全球范围内参与，扩大了受众基础', icon: goodImg1, iconWidth: '18', iconHeight: '53',iocnTop:'24',iconRight:'40' },
      goodItem2: { title: '成本效益', text: '举办线上展会通常比传统展会成本更低。参展商不需要支付展位费用、搭建展台和物流费用，从而节省了开支', icon: goodImg2, iconWidth: '17.2', iconHeight: '50',iocnTop:'25',iconRight:'41' },
      goodItem3: { title: '环保可持续', text: '相对于传统的实体展览，线上展会对资源的使用更少，减少了物流、印刷和能源消耗，有利于环保和可持续发展', icon: goodImg3, iconWidth: '18', iconHeight: '55',iocnTop:'25',iconRight:'40' },
      goodItem4: { title: '多样化的展示方式', text: '线上展会可以提供图像、视频、虚拟现实等展示，使参与者能够以更富有创意和多样性的方式展示产品、服务和信息', icon: goodImg4, iconWidth: '17', iconHeight: '53',iocnTop:'26',iconRight:'41' },
      goodItem5: { title: '互动性增强', text: '通过聊天、视频会议和在线问答等功能，参与者可以获得更多信息，解决疑问，并建立联系', icon: goodImg5, iconWidth: '19', iconHeight: '50',iocnTop:'26',iconRight:'38' },
      goodItem6: { title: '数据统计与分析', text: '进行数据统计和分析，包括参展人数、观展行为等，有助于参展商评估展会效果并进行后续的市场推广策略调整', icon: goodImg6, iconWidth: '17', iconHeight: '50',iocnTop:'26',iconRight:'40' },

      // 实际案例
      index: -1,
      currentType: 1,

      carText1: '机动实操考核系统',
      carText2: '设备台站模拟训练系统',
      carText3: '红蓝双方兵力对抗系统',
      carImage1: areaImg1,
      carImage2: areaImg2,
      carImage3: areaImg3,
      carFieldImgs: [areaImg1, areaImg2, areaImg3],
      carFieldTexts: ['机动实操考核系统', '设备台站模拟训练系统', '红蓝双方兵力对抗系统'],

      currentIndex: 0,//当前显示的图片索引

      intervalId: null, //用于存储定时器ID


      // 应用领域
      info: [

        { p1: '传统行业', span: '工业制造、技术领域等可以通过线上展会展览来展示产品、招揽合作伙伴；', isActive: false, backgroundImage: areaImg1 },
        { p1: '艺术文博', span: '艺术展、博物馆等可以通过线上展览让更多人欣赏艺术品与文化遗产', isActive: false, backgroundImage: areaImg2 },
        { p1: '创新科技', span: '创新科技展览和科技峰会可以利用线上展会的形式介绍新的科技产品和技术，与科技爱好者、投资者和合作伙伴进行交流和合作', isActive: false, backgroundImage: areaImg3 },
        { p1: '医疗健康', span: '医疗器械、药品等可以通过线上展会展示科研成果与产品', isActive: false, backgroundImage: areaImg4 }
      ],
    }
  },
  methods: {
    Scale() {
      if (document.body.clientWidth < 1200) {
        this.oneWidth.width = 1200;
        this.oneWidth.height = 250;

        this.twoWidth.width = 1200;
        // this.twoWidth.height = 295

        this.threeWidth.width = 1200;
        // this.threeWidth.height = 616;

        this.fourWidth.width = 1200;
        // this.fourWidth.height = 503

        this.fiveWidth.width = 1200;
        // this.fiveWidth.height = 1251

        this.sixWidth.width = 1200;
        this.sixWidth.height = 333
      } else {
        this.oneWidth.height = 0.21 * document.body.clientWidth;
        this.oneWidth.width = document.body.clientWidth;

        // this.twoWidth.height = 0.25 * document.body.clientWidth;
        this.twoWidth.width = document.body.clientWidth;
        // 0.32 * document.body.clientWidth;
        // this.threeWidth.height = 616;
        this.threeWidth.width = document.body.clientWidth;

        // this.fourWidth.height = 0.42 * document.body.clientWidth;
        this.fourWidth.width = document.body.clientWidth;

        // this.fiveWidth.height = 1.04 * document.body.clientWidth;
        this.fiveWidth.width = document.body.clientWidth;

        this.sixWidth.height = 0.28 * document.body.clientWidth;
        this.sixWidth.width = document.body.clientWidth;
      }
    },

    /*锁定选定的盒子判断他是否选定 */
    // divClick(index) {
    //   /** 当 “点击” 一个“已被点击”的div时 使该div恢复默认色 */
    //   // 遍历info数组中的每个对象
    //   for (let i of this.info) {
    //     // 判断当前对象是否和传入的索引index对应 如果是，则取反
    //     if (index === this.info.indexOf(i)) {
    //       i.isActive = !i.isActive
    //     } else {
    //       i.isActive = false;
    //     }
    //   }
    // },

    // 鼠标滑过时，显示背景图片和修改文字颜色
    mouseOver(index) {
      this.info[index].isActive = true;
    },

    // 鼠标离开时则恢复默认
    mouseLeave(index) {
      this.info[index].isActive = false;
    },


    /*给选定的盒子修改样式 */
    colorDiv(index) {
      if (this.info[index].isActive) {
        return {
          backgroundImage: `url(${this.info[index].backgroundImage})`,
          color: 'white'
        };
      } else {
        return {
          backgroundImage: 'none',
          '.p1 p': {
            color: '#333333;'
          },
          '.span span': {
            color: 'black'
          }
        }
      }
    },

    /*
    实际案例部分轮播切换
     */
    showSlide(index) {
      this.carImage1 = this.carFieldImgs[index];
      this.carText1 = this.carFieldTexts[index];
      this.carImage2 = this.carFieldImgs[++index % 3];
      this.carText2 = this.carFieldTexts[index % 3];
      this.carText3 = this.carFieldTexts[++index % 3];
      this.carImage3 = this.carFieldImgs[index % 3];
      this.currentIndex = (++index) % 3;
    },
    //居中显示
    centerSlide(index) {
      this.currentIndex = index;
      this.showSlide(index);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.carFieldImgs.length;
      this.currentIndex = (this.currentIndex + 1) % this.carFieldTexts.length;
      this.showSlide(this.currentIndex);
    },

    // 定时器 自动轮播
    startTime() {
      this.intervalId = setInterval(this.nextSlide, 5000);
    },

    chanageImg(index) {
      /* 重置计时器 */
      clearInterval(this.intervalId);
      this.currentIndex = index;
      //  // 将被点击的图片居中显示
      // this.centerSlide(this.currentIndex);

      //计算需要移动的步数
      let steps = index - this.currentIndex;

      // 如果步数大于轮播图一半的长度，向前滚动到达目标图片
      if (Math.abs(steps) > this.slides.length / 2) {
        steps = steps > 0 ? steps - 3 : steps + 3;
      }

      // 更新 currentIndex，使目标图片移动到中间位置
      this.currentIndex = (this.currentIndex + steps + 3) % 3;

      // 将目标图片居中显示
      this.centerSlide(this.currentIndex);

      //重新启动定时器
      this.startTime();
    },

    // 主要功能部分切换功能
    changeContent() {
      // 在页面加载完成后先执行这个
      // document.addEventListener("DOMContentLoaded",function(){
      //   // 获取第一个菜单项
      //   var firstMenuItem = document.querySelector('.menu-router');
      //   // 让他点击第一个菜单项
      //   firstMenuItem.click();
      // })

      // 获取元素
      // var tab_list = document.querySelector('.menu');
      var list = document.querySelectorAll('.menu-router');
      var item = document.querySelectorAll('.contentItem');

      // item[0].style.display='block';
      // 获取第一个导航项
      var firstNavItem = document.querySelectorAll('.menu-router')[6];
      //获取第一个导航项对应的内容
      var firstContentItem = document.querySelectorAll('.contentItem')[6];
      // 给导航项添加样式
      firstNavItem.classList.add('menuActive');
      // 让第一导航项对应内容默认显示
      firstContentItem.style.display = 'block';

      // 分别绑定点击事件
      for (var i = 0; i < list.length; i++) {
        // 在这里创建属性，添加索引
        list[i].setAttribute('index', i);
        list[i].onclick = function () {
          // 1.第一大步骤，先解决导航栏的颜色切换
          // 所有的 menu-router清除class类
          for (var i = 0; i < list.length; i++) {
            list[i].classList.remove('menuActive');
          }
          // 自己添加上这个类
          this.classList.add('menuActive');
          // 2.第二大步骤：下面模块跟着选项卡一一匹配
          // 给上面的tab_list里面所有的li添加索引
          var index = this.getAttribute('index');
          console.log(index);
          // 先全部隐藏
          for (var j = 0; j < item.length; j++) {
            item[j].style.display = 'none';
          }
          // 当前的显示
          item[index].style.display = 'block';
        }
      }
    },


  },
}

</script>


<style>
.about {
  width: 100%;
  height: auto;
}

.aboutNav {
  width: 100%;
  height: 90px;
  background-color: #000;
}


.three {
  /* width: 1202px;
	height: 663px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  background-color: #f6f6f6;
}

.font3 {
  width: 100%;
  height: 116px;
}

.font3 p {
  font-family: MySourceHanSansSC-Heavy;
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 2px;
  color: #333333;
  white-space: nowrap;
  margin: 0 auto;
}

.box {
  width: 1200px;
  height: 390px;
  display: flex;
  justify-content: space-between;
  /*间隔均匀排列*/
  justify-content: center;
  /* margin: 0 auto; */
  margin: 0;
}

.four {
  background-image: url(' ../assets/mainFunctions.jpg ');
  background-size: cover;
}

.transactionsFont4 {
  width: 100%;
  height: 97px;
  display: flex;
  white-space: nowrap;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  /*水平居中 */
  /*align-items: center; 垂直居中*/
  position: relative;
  /*相对定位 */
}

.transactionsFont4 p {
  font-family: SourceHanSansSC-Heavsy;
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  /* line-height: 30px; */
  letter-spacing: 4px;
  color: #ffffff;
  margin: 0;
  position: absolute;
  /*绝对定位 */
  bottom: 15%;
  /*让他的下边距为0 */
  /* margin-top: 50%; */
  /* margin-bottom: 50%; */
}


.onlineAll{
  display: flex;
  flex-direction: column;
  /*让整个容器垂直排列 */
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
}

.online-all {
  /* margin: 0 auto; */
}


.topOnline {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.bottomOnline {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.top-online {
  width: 31.8%;
  height: 92.8%;
  background-color: #ffffff;
  border-radius: 8px;
}

.bottom-online {
  width: 31.8%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
}

.AboutOneHardwareSupport {
  /* margin: 0 auto; */
  /* display: flex;
 justify-content: center; */
  /* margin-top: 4%; */
  background-color: #f6f6f6;
}


/* 实际案例标题 */
.font6 h2 {
  font-family: SourceHanSansSC-Heavy;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 4px;
  color: #333333;
  margin: 0;
}


/* -----------------1201-1366之间---------------- */
@media (min-width: 1201px) and (max-width:1366px) {

  /* 实际案例标题 */
  .font6 h2 {
    font-size: 25px;
  }

}


/* -----------------1367-1600之间---------------- */
@media (min-width: 1367px) and (max-width:1600px) {

  /* 实际案例标题 */
  .font6 h2 {
    font-size: 30px;
  }
}


/* -----------------1600以上---------------- */
@media (min-width: 1600px) {

  /* 实际案例标题 */
  .font6 h2 {
    font-size: 40px;
  }


}
</style>