<template>
    <div style="position: absolute; display: flex; width: 1200px; height: 100%; left: 50%; transform: translateX( -50% );">
        <div class="aboutUsServiceFieldText">
            <div class="aboutUsServiceFieldTextDiv">
                <p class="title" style="color: #333;">服务领域</p>
                <p class="textMsg" style="color: #333; position: absolute; top: 17.2%;">
                    主要服务于军工、教育、制造、旅游、医疗等各个领域，凭借过硬的产品技术、良好的产品性能、优质的技术服务解决了多种项目开发中的技术问题，取得了很好效果
                </p>
            </div>
        </div>
        <div class="aboutUsServiceFieldCarousel">
            <div style="width: 31.5%; height: 100%; background-color: #233881;" />
            <div style="width: 68.5%; height: 100%;" />
            <div class="aboutUsServiceFieldCarouselImg">
                <div style="width: 25.2%; height: 100%; position: relative;">
                    <p class="textTitle" style="color: #fff; position: absolute; top: 0;">{{ serviceFieldText1 }}</p>
                    <img :src="serviceFieldImg1" style="width: 100%; height: 84%; position: absolute; left: 0; bottom: 0;">
                </div>
                <div style="width: 25.2%; height: 100%; position: relative;">
                    <img :src="serviceFieldImg2" style="width: 100%; height: 84%; position: absolute; top: 0; left: 0;">
                    <p class="textTitle" style="color: #333; position: absolute; bottom: 0;">{{ serviceFieldText2 }}</p>
                </div>
                <div style="width: 25.2%; height: 100%; position: relative;">
                    <p class="textTitle" style="color: #333; position: absolute; top: 0;">{{ serviceFieldText3 }}</p>
                    <img :src="serviceFieldImg3" style="width: 100%; height: 84%; position: absolute; bottom: 0; left: 0;">
                </div>
            </div>
            <div class="aboutUsServiceFieldCarouselProgress">
                <div style="width: 25.6%; height: 100%; display: flex;">
                    <button class="serviceFieldBtn" @click="serviceFieldChangeImg(-1)">
                        <img class="serviceFieldBtnImg" src="../assets/home/leftButton.png" style="left: 0;"/>
                        <img class="serviceFieldBtnImgHover" src="../assets/home/leftButtonHover.png" style="left: 0;"/>
                    </button>
                    <button class="serviceFieldBtn" @click="serviceFieldChangeImg(1)">
                        <img class="serviceFieldBtnImg" src="../assets/home/rightButton.png" style="left: 15.8%;"/>
                        <img class="serviceFieldBtnImgHover" src="../assets/home/rightButtonHover.png" style="left: 15.8%;"/>
                    </button>
                </div>
                <div class="aboutUsServiceFieldCarouselProgressBar">
                    <transition name="aboutUsServiceProgress">
                        <div id="aboutUsServiceFieldCarouselProgressCurrent" style="background-color: #1b297b; height: 1px; width: 20%; transition: transform 1s ease;" />
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import serviceFieldCarouselImg1 from '../assets/aboutUs/serviceFieldCarouselImg1.webp'
import serviceFieldCarouselImg2 from '../assets/aboutUs/serviceFieldCarouselImg2.webp'
import serviceFieldCarouselImg3 from '../assets/aboutUs/serviceFieldCarouselImg3.webp'
import serviceFieldCarouselImg4 from '../assets/aboutUs/serviceFieldCarouselImg4.webp'
import serviceFieldCarouselImg5 from '../assets/aboutUs/serviceFieldCarouselImg5.webp'


export default {
    name: 'AboutUsService',
    data() {
        return {
            currentServiceFieldIndex: 0,
            serviceFieldImg1: serviceFieldCarouselImg1,
            serviceFieldImg2: serviceFieldCarouselImg2,
            serviceFieldImg3: serviceFieldCarouselImg3,
            serviceFieldText1: '军工',
            serviceFieldText2: '教育',
            serviceFieldText3: '制造',
            serviceFieldCarouselImgs: [serviceFieldCarouselImg1, serviceFieldCarouselImg2, serviceFieldCarouselImg3, 
                serviceFieldCarouselImg4, serviceFieldCarouselImg5],
            serviceFieldCarouselTexts: ['军工', '教育', '制造', '医疗', '音视频'],
        }
    },
    methods: {
        serviceFieldChangeImg(changeIndex){
            //计算新的当前index
            this.currentServiceFieldIndex = (this.currentServiceFieldIndex + changeIndex + 5) % 5;
            //切换文本和图片
            this.serviceFieldImg1 = this.serviceFieldCarouselImgs[this.currentServiceFieldIndex];
            this.serviceFieldText1 = this.serviceFieldCarouselTexts[this.currentServiceFieldIndex];
            this.serviceFieldImg2 = this.serviceFieldCarouselImgs[(this.currentServiceFieldIndex + 1) % 5];
            this.serviceFieldText2 = this.serviceFieldCarouselTexts[(this.currentServiceFieldIndex + 1) % 5];
            this.serviceFieldImg3 = this.serviceFieldCarouselImgs[(this.currentServiceFieldIndex + 2) % 5];
            this.serviceFieldText3 = this.serviceFieldCarouselTexts[(this.currentServiceFieldIndex + 2) % 5];
            //移动进度条
            let bar = document.getElementById("aboutUsServiceFieldCarouselProgressCurrent");
            let x = this.currentServiceFieldIndex * 100;
            bar.style.transform = 'translateX(' + x + '%)';
        },
    },
}
</script>

<style>
.aboutUsServiceFieldText{
    position: relative;
    width: 35%;
    height: 100%;
}
.aboutUsServiceFieldTextDiv{
    position: absolute; 
    width: 62%;
    height: 60%;
    top: 30.5%;
    left: 6%;
}
.aboutUsServiceFieldTextDiv .textMsg{
    font-size: 16px;
    line-height: 32px;
}
/* 轮播部分 */
.aboutUsServiceFieldCarousel{
    position: relative;
    display: flex;
    width: 65%;
    height: 100%;
}
.aboutUsServiceFieldCarouselImg{
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 84%;
    height: 35.3%;
    top: 30.5%;
    left: 5.2%;
    z-index: 5;
}
.aboutUsServiceFieldCarouselImg .textTitle{
    font-size: 24px;
    line-height: 30px;
}
/* 轮播进度条按钮 */
.serviceFieldBtn{
    background-color: rgba(0, 0, 0, 0); 
    height: 100%; 
    border: none; 
    padding: 0;
}
.serviceFieldBtn img {
    position: absolute;
    top: 0;
    left: 0;
    width: 9.6%;
    height: 100%;
    transition: opacity 0.3s ease; /* 添加过渡效果，使图片切换更平滑 */
}
.serviceFieldBtn .serviceFieldBtnImgHover{
    opacity: 0;
}
.serviceFieldBtn:hover .serviceFieldBtnImgHover{
    opacity: 1;
}
.serviceFieldBtn:hover .serviceFieldBtnImg{
    opacity: 0;
}
/* 轮播进度条 */
.aboutUsServiceFieldCarouselProgress{
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 62.6%;
    height: 5.6%;
    top: 82.4%;
    left: 37%;
    z-index: 5;
}
.aboutUsServiceFieldCarouselProgressBar{
    width: 67.6%; 
    height: 1px; 
    display: flex;
    background-color: #96aabe; 
    position: absolute; 
    top: 50%; 
    transform: translateY( -50% ); 
    left: 33%;
}
</style>