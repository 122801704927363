<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px', height:this.mainFunctionLarge+'px'}">
            <div class="caseMainFunctionLargeDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/huanjingbinglishengcheng/computer.png">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" />
                    </div>
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem5" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem6" :isLong="true" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem7" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem8" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem5" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem6" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/huanjingbinglishengcheng/icon1.png'
import icon2 from '../assets/case/huanjingbinglishengcheng/icon2.png'
import icon3 from '../assets/case/huanjingbinglishengcheng/icon3.png'
import icon4 from '../assets/case/huanjingbinglishengcheng/icon4.png'
import icon5 from '../assets/case/huanjingbinglishengcheng/icon5.png'
import icon6 from '../assets/case/huanjingbinglishengcheng/icon6.png'
import icon7 from '../assets/case/huanjingbinglishengcheng/icon7.png'
import icon8 from '../assets/case/huanjingbinglishengcheng/icon8.png'
import pic1 from '../assets/case/huanjingbinglishengcheng/projectPic1.png'
import pic2 from '../assets/case/huanjingbinglishengcheng/projectPic2.png'
import pic3 from '../assets/case/huanjingbinglishengcheng/projectPic3.png'
import pic4 from '../assets/case/huanjingbinglishengcheng/projectPic4.png'
import pic5 from '../assets/case/huanjingbinglishengcheng/projectPic5.png'
import pic6 from '../assets/case/huanjingbinglishengcheng/projectPic6.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseEnvironmentTroopsGeneration',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '620',
            projectPic:'427',
            topTitle: '环境与兵力生成软件',
            pojectOverviewMsg: '“环境与兵力生成软件”旨在帮助军事规划者、战略决策者和军事分析人员模拟和分析战场环境与兵力的生成与演化。结合地理信息数据、军事情报数据、人工智能算法等多种信息源，提供全面、准确的战场环境模拟和兵力生成功能。同时，软件还能模拟兵力的生成和演化过程，根据用户设定的战略目标和战术策略，生成敌我双方的兵力部署和行动。用户可以观察和分析兵力在战场环境中的运动和交互，了解各个战斗单元的状态和效能，并进行战术决策和优化。软件还提供实时的情报分析和态势感知功能，帮助用户更好地理解战场动态和战局发展趋势',
            iconItem1: {title:'兵力部署', text: '用户设定兵种、数量等参数，进行兵力生成和部署', icon: icon1},
            iconItem2: {title:'环境设置', text: '用户设定环境参数，生成真实、多样的战场环境', icon: icon2},
            iconItem3: {title:'攻击路线规划', text: '在海图或者地图上绘制导弹的攻击路线', icon: icon3},
            iconItem4: {title:'武器设置', text: '配置携带的武器类型和弹量以及设备状态', icon: icon4},
            iconItem5: {title:'传感器设置', text: '配置携带的传感器以及设置开关机和探测范围', icon: icon5},
            iconItem6: {title:'航路规划', text: '规划飞机或者船的行进航路，包括经纬度，高度等参数', icon: icon6},
            iconItem7: {title:'战术决策', text: '帮助用户评估战术选择，并进行战术决策的优化', icon: icon7},
            iconItem8: {title:'态势感知', text: '提供实时的情报分析功能，帮助用户了解战场动态', icon: icon8},
            picItem1: {title:'兵力部署', pic: pic1},
            picItem2: {title:'航路规划', pic: pic2},
            picItem3: {title:'武器设置', pic: pic3},
            picItem4: {title:'传感器设置', pic: pic4},
            picItem5: {title:'环境设置', pic: pic5},
            picItem6: {title:'战术决策', pic: pic6},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 4,
            currentType: 0,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 387.5;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.322;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    },
}
</script>