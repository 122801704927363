<template>
  <div class="tryApplyIcon">

<!-- 235 242 -->
    <div class="try" style="width:100% ; height:50%">
      <h3 style="width: 7%; height:13%">申请试用</h3>
    </div>


    <div class="tryAll" style="width: 100%; height:51%">
      <!-- 左边是图片 -->
      <div class="tryImg" style="width: 240px; height:277px; margin-left:23%;">
        <img :src="applyIcon" style="width:100%; height:100%;" />
      </div>
      <!-- 右边是文本加图片 -->
      <div class="tryText" style="width:44.9%; height:79%;">
        <!-- 标题  -->
        <div class="tryTitle" style="width: 100%; height:31%">
          <h4>请告诉我们您的试用需求</h4>
        </div>

        <!-- 输入框容器 -->
        <div class="tryInputContainer" style="width:97%;height:75%">

          <!-- <input type="search" autocomplete="off"> -->
          <div class="tryInputThree">
            <input type="search" autocomplete="off"  id="name" class="red-start" placeholder="*请输入您的姓名"
              style="width: 29%;height: 58.2%; border-radius: 17px; justify-content: left;--size:0.5em; outline:none; padding-left: 5px;">
            <input type="search" autocomplete="off"  id="phone" class="red-start" placeholder="*请输入您的电话"
              style="width: 29%;height:58.2%;border-radius: 17px;--size:0.5em; outline:none;padding-left: 5px;">
            <input type="search" autocomplete="off"  id="company" class="start" placeholder="请输入您的公司名称"
              style="width: 29%;height: 58.2%;border-radius: 17px;--size:9.5em;  outline:none;padding-left: 5px;">
          </div>

          <div class="tryInputOne">
            <textarea type="text" id="demand" placeholder="请输入您的需求" style="width: 100%;height: 77%;border-radius: 8px; outline:none;padding-left: 5px;" />
          </div>


          <!-- 右侧图标按钮 -->
          <div class="tryLcon" style="width:20% ; height: 96.7%;">

            <img :src="applyIcon2" style=" width: 79px;height: 106px; margin-left: 40%;">

            <el-button type="button" id="sendEmail" @click="sendApply()"
              style="width: 120px;height: 36px; margin-left: 28%;background-color: #2189ff;border-radius: 17px; border: none; outline:none;">
              <p class="tryButtton">申请试用</p>
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

// 申请试用
import applyTry1 from '../assets/aboutOneApplyTry/icon1.png'
import applyTry2 from '../assets/aboutOneApplyTry/icon2.png'

export default {
  name: 'AboutOneApplyTryDiv',
  props: {
    // hardItem:{
    //     type: Object,
    //     required: true,
    // }
  },
  data() {
    return {
      // 申请试用
      applyIcon: applyTry1,
      applyIcon2: applyTry2,
    }
  },

  methods: {
    sendApply() {
      const name = document.getElementById('name').value;
      const phone = document.getElementById('phone').value;
      const company = document.getElementById('company').value;
      const demand = document.getElementById('demand').value;

      // 检查姓名和电话是否已填写
      if (!name || !phone) {
        // alert('请填写姓名和电话！');
        this.$message({
          message: '请填写姓名和电话！',
          type: 'warning'
        });
        return;
      }
      let nameReg = /^[\u4e00-\u9fa5a-zA-Z]+$/;
      if (!nameReg.test(name)) {
        this.$message.error("姓名输入不合法，请重新输入")
        return;
      }


      /**
       *验证多种手机号
       规则说明：
    1、可以是1开头的11位数字（手机号）
    2、可以是“区号-电话号-分机号”或者是“(区号)电话号-分机号”格式
    3、区号是0开头的3～4位数字，可以没有区号
    4、电话号是5～8位数字，不能以0开头
    5、分机号是1～8位数字，可以没有分机号
       */
      let phoneReg = /^1\d{10}$|^(0\d{2,3}-?|\(0\d{2,3}\))?[1-9]\d{4,7}(-\d{1,8})?$/;
      // 验证常规的11种手机号 /^1[3|4|5|7|8][0-9]\d{8}$/
      if (!phoneReg.test(phone)) {
        this.$message.error("请输入正确的手机号码")
        return;
      }

      const axios = require('axios')

      //第三方邮件网址https://www.sendcloud.net/home
      //https://api.sendcloud.net/apiv2/mail/send
      const url = "/mail/send"
      const API_USER = 'sc_4qtdkk_test_LVXdFS'
      const API_KEY = '2dccb2fdb378ee109ecd3e76c8ab9b2f'

      // 请求的配置
      let config = {
        timeout: 3 * 60 * 1000, // 设置超时时间 3分钟
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        }
      }

      
      // 请求的参数
      let params = {
        "apiUser": API_USER,
        "apiKey": API_KEY,
        "to": "business@dalianvideo.com",//接收邮箱地址(必填)
        "from": "2237131268@qq.com",//发送邮箱地址(必填)
        "fromName": name,
        "subject": "申请试用",//主题(必填)
        "html": "姓名:" + name +'<br/>' + "联系电话：" + phone +'<br/>'+ "公司名称:" + company +'<br/>'+ "申请需求：" + demand,//内容(必填)
      }



      // 发送请求
      axios.post(url, params, config).then((resp) => {
        this.$message.success("申请成功！您的申请我们已收到，稍后将派专业的业务人员和您沟通")
        console.log(resp.data);
        // 清空表单
        document.getElementById('name').value = '';
        document.getElementById('phone').value = '';
        document.getElementById('company').value = '';
        document.getElementById('demand').value = '';
      }).catch((err) => {
        console.log(err)
        // alert('申请失败，请稍后再试！');
       // this.$message.error("申请失败，请稍后再试！")
      })

    },


  }
}
</script>

<style>
.tryApplyIcon {
  width: 100%;
  height: 100%;
}

.try h3 {
  margin: 0;
  position: absolute;
  top: 100px;
  font-family: MySourceHanSansSC-Heavy;
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 3px;
  color: #333333;
  white-space: nowrap;
}

.tryAll {
  display: flex;
  position: relative;
  justify-content: space-between;
  background-image: linear-gradient(90deg,
      #accbee 0%,
      #e7f0fd 0%);
  border-radius: 8px;
  bottom: 0;
}

.tryImg {
  position: absolute;
  top: -16%;
}

.tryText {
  display: flex;
  position: absolute;
  bottom: 0;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 44%;
}

.tryTitle {}

.tryTitle h4 {
  margin: 0;
  font-family: MySourceHanSansSC-Bold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  /* line-height: 20px; */
  letter-spacing: 2px;
  color: #333333;
  text-align: left;
}

.tryInputContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.tryInputThree {
  width: 80%;
  height: 40%;
  display: flex;
  justify-content: space-between;
}

.tryInputOne {
  /* width: 79%;
  height: 73%; */
  width: 80%;
  height: 58%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.tryLcon {
  display: flex;
  position: absolute;
  right: 0;
  /* top: 0; */
  justify-content: space-between;
  flex-direction: column;
  bottom: 10%;
}

.tryButtton {
  font-family: SourceHanSansSC-Light;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  /* line-height: 20px; */
  letter-spacing: 2px;
  color: #ffffff;
  /* margin: 0; */
  margin-top: -5px;
}

.tryInputThree input {
  background-color: #ffffff;
  border-radius: 17px;
  padding: 0;
  position: relative;
  border: #777777; /* 移除边框 */
  text-align: left;
  font-size: 10px;
  font-family: MySourceHanSansSC-Light;
  text-indent: 2px;
  letter-spacing: 2px;
}

.tryInputOne textarea {
  background-color: #ffffff;
  border-radius: 8px;
  border: none; /* 移除边框 */
  font-size: 10px;
  font-family: MySourceHanSansSC-Light;
  letter-spacing: 2px;
}

.tryInputThree input::placeholder {
  font-family: MySourceHanSansSC-Light;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  /* line-height: 20px; */
  letter-spacing: 1px;
  color: transparent;
  position: relative;
  /* left: 6%; */
}

.red-start::placeholder {
  background: linear-gradient(to right, #ff0000 0, #ff0000 var(--size), #777777 var(--size));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.start::placeholder {
  background: linear-gradient(to right, #777777 0, #777777 var(--size), #777777 var(--size));
  background-clip: text;
  -webkit-background-clip: text;
  color: #777777;
}

.tryInputOne textarea::placeholder {
  font-family: MySourceHanSansSC-Light;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  /* line-height: 20px; */
  letter-spacing: 1px;
  color: #777777;
  position: relative;
}


/* -----------------1201-1366之间---------------- */
@media (min-width: 1201px) and (max-width:1253px) {

  .tryInputThree input::placeholder {
    font-size: 11px;
  }

  .tryInputOne textarea::placeholder {
    font-size: 11px;
  }

  .tryInputThree input {
  font-size: 11px;
}

.tryInputOne textarea {
  font-size: 11px;
}
  .tryText {
    margin-left:44%;
  }
}

@media (min-width: 1254px) and (max-width:1299px) {
  .tryInputThree input::placeholder {
    font-size: 11px;
  }

  .tryInputOne textarea::placeholder {
    font-size: 11px;
  }

  .tryInputThree input {
  font-size: 11px;
}

.tryInputOne textarea {
  font-size: 11px;
}
  .tryText {
    margin-left:43%;
  }
}

@media (min-width: 1300px) and (max-width:1334px) {
  .tryInputThree input::placeholder {
    font-size: 12px;
  }

  .tryInputOne textarea::placeholder {
    font-size: 12px;
  }

  .tryInputThree input {
  font-size: 12px;
}

.tryInputOne textarea {
  font-size: 12px;
}
  .tryText {
    margin-left:42%;
  }
}

@media (min-width: 1335px) and (max-width:1365px) {
  .tryInputThree input::placeholder {
    font-size: 12px;
  }

  .tryInputOne textarea::placeholder {
    font-size: 12px;
  }

  .tryInputThree input {
  font-size: 12px;
}

.tryInputOne textarea {
  font-size: 12px;
}
  .tryText {
    margin-left:42%;
  }
}

@media (min-width: 1355px) and (max-width:1366px) {
  .tryInputThree input::placeholder {
    font-size: 13px;
  }

  .tryInputOne textarea::placeholder {
    font-size: 13px;
  }

  .tryInputThree input {
  font-size: 13px;
}

.tryInputOne textarea {
  font-size: 13px;
}
  .tryText {
    margin-left:42%;
  }
}

/* -----------------1367-1600之间---------------- */
@media (min-width: 1367px) and (max-width:1467px) {
  .tryInputThree input::placeholder {
    font-size: 13px;
  }

  .tryInputOne textarea::placeholder {
    font-size: 13px;
  }

  .tryInputThree input {
  font-size: 13px;
}

.tryInputOne textarea {
  font-size: 13px;
}

  .tryText {
    margin-left:42%;
  }
}

@media (min-width: 1468px) and (max-width:1568px) {
  .tryInputThree input::placeholder {
    font-size: 13px;
  }

  .tryInputOne textarea::placeholder {
    font-size: 13px;
  }

  .tryInputThree input {
  font-size: 13px;
}

.tryInputOne textarea {
  font-size: 13px;
}
  .tryText {
    margin-left:40%;
  }
}

@media (min-width: 1569px) and (max-width:1599px) {
  .tryInputThree input::placeholder {
    font-size: 14px;
  }

  .tryInputOne textarea::placeholder {
    font-size: 14px;
  }

  .tryInputThree input {
  font-size: 14px;
}

.tryInputOne textarea {
  font-size: 14px;
}
  .tryText {
    margin-left:39%;
  }
}


/* -----------------1600以上---------------- */
@media (min-width: 1600px) and (max-width:1665px) {
  .tryInputThree input::placeholder {
    font-size: 14px;
  }

  .tryInputOne textarea::placeholder {
    font-size: 14px;
  }

  .tryInputThree input {
  font-size: 14px;
}

.tryInputOne textarea {
  font-size: 14px;
}
  .tryText {
    margin-left:39%;
  }

}

@media (min-width: 1666px) and (max-width:1777px) {
  .tryInputThree input::placeholder {
    font-size: 14px;
  }

  .tryInputOne textarea::placeholder {
    font-size: 14px;
  }

  .tryInputThree input {
  font-size: 14px;
}

.tryInputOne textarea {
  font-size: 14px;
}
  .tryText {
    margin-left:38%;
  }
}

@media (min-width: 1778px) {
  .tryInputThree input::placeholder {
    font-size: 14px;
  }

  .tryInputOne textarea::placeholder {
    font-size: 14px;
  }

  .tryInputThree input {
  font-size: 14px;
}

.tryInputOne textarea {
  font-size: 14px;
}
  .tryText {
    margin-left:37%;
  }
}
</style>