<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 产品概述 -->
        <ProjectMilitaryOverview :overViewText='overViewText' :isMilitary="true" />
        <!-- 应用领域 -->
        <div class="projectMilitaryApply">
            <p class="title">应用领域</p>
            <div class="projectMilitaryApplyBox">
                <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
            </div>
        </div>
        <!-- 产品特色 -->
        <div class="projectMilitaryFeaturesDiv">
            <p class="title">产品特色</p>
            <div class="projectMilitaryFeaturesArea">
                <ProjectMilitaryFeaturesRight :item="item1" :changeWidth="1" />
                <ProjectMilitaryFeaturesLeft :item="item2" />
                <ProjectMilitaryFeaturesRight :item="item3" />
                <ProjectMilitaryFeaturesLeft :item="item4" />
            </div>
        </div>
        <!-- 其他案例 -->
        <Others :index="-1" :currentType="0" :arrayIndex="7" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import areaImg1 from '../assets/dataAnalysisVisualization/areaImg1.webp'
import areaImg2 from '../assets/dataAnalysisVisualization/areaImg2.webp'
import areaImg3 from '../assets/dataAnalysisVisualization/areaImg3.webp'
import areaImg4 from '../assets/dataAnalysisVisualization/areaImg4.webp'
import featureImg1 from '../assets/dataAnalysisVisualization/featureImg1.png'
import featureImg2 from '../assets/dataAnalysisVisualization/featureImg2.png'
import featureImg3 from '../assets/dataAnalysisVisualization/featureImg3.png'
import featureImg4 from '../assets/dataAnalysisVisualization/featureImg4.png'

import CaseTop from '../components/CaseTop.vue'
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
import ProjectMilitaryFeaturesRight from '../components/ProjectMilitaryFeaturesRight.vue'
import ProjectMilitaryFeaturesLeft from '../components/ProjectMilitaryFeaturesLeft.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'ProductDataAnalysisVisualizationView',
    components: {
        CaseTop,
        ProjectMilitaryOverview,
        AboutOneApplicationArea,
        ProjectMilitaryFeaturesRight,
        ProjectMilitaryFeaturesLeft,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 0,
            topTitle: '数据可视化分析',
            // 产品概述
            overViewText: '数据可视化主要旨在借助于图形化手段，清晰有效地传达与沟通信息，为了有效地传达思想概念，美学形式与功能需要齐头并进，通过直观地传达关键的方面与特征，从而实现对于相当稀疏而又复杂的数据集的深入洞察。可视化工具可以提供多样的数据展现形式，多样的图形渲染形式，丰富的人机交互方式，支持商业逻辑的动态脚本引擎等等，我司提供的数据分析基本以表格、图形（chart）、地图等可视化元素为主，数据可进行过滤、钻取、数据联动、跳转、高亮等分析手段做动态分析',
            // 应用领域
            info: [
                { p1: '制造业和工业', span: '数据可视化可以帮助制造业和工业企业更好地了解生产效率、设备状态和能源消耗等。例如，仪表盘可以展示生产线的效率和质量指标，堆叠柱状图可以展示不同产品的生产数量和成本分配情况', isActive: false, backgroundImage: areaImg1 },
                { p1: '政府和公共事业', span: '数据可视化可以帮助政府和公共事业机构更好地了解社会经济状况、公共服务需求和基础设施建设情况等。例如，地图可以展示不同地区的人口密度和就业情况，柱状图可以展示不同行业的经济增长情况', isActive: false, backgroundImage: areaImg2 },
                { p1: '教育和研究', span: '数据可视化可以帮助教育和研究机构更好地了解学生学习情况、研究成果和科学趋势等。例如，折线图可以展示学生的学习成绩趋势，饼图可以展示不同研究领域的研究经费分配情况', isActive: false, backgroundImage: areaImg3 },
                { p1: '商业和金融', span: '数据可视化可以帮助企业更好地了解市场趋势、销售表现、客户行为等，以便做出更好的决策。例如，柱状图和折线图可以展示销售额和营收的变化趋势，热力图可以展示客户分布和活动热度', isActive: false, backgroundImage: areaImg4 }
            ],
            // 产品特色
            item1: {title: '支持丰富的数据源', text: '可连接多种类型的数据源，包括：关系型数据库，分布式大数据库，文件数据源（Excel、txt、DB）、接口数据源等，支持跨源跨库的分析', img: featureImg1},
            item2: {title: '高效的数据处理', text: '一键连接百种数据源，包括文件数据源及接口数据源。多样的建模方式和丰富的数据处理组件高效便捷地实现了数据仓库的构建及数据的抽取、清洗、转换等操作', img: featureImg2},
            item3: {title: '集成开发API', text: '提供上千个API接口，方便用户扩展，便于与第三方系统集成，缩短项目实施周期，降低成本', img: featureImg3},
            item4: {title: '支持移动应用', text: '支持通过移动端实现数据的采集与分析应用，报表一次制作即可在手机、ipad、大屏等多个终端设备上进行展示', img: featureImg4},
        }
    },
}
</script>