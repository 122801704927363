<template>
    <div style="width: 100%; height: 100%;">
        <div class="aboutUsCorporateCultureTitle">
            <p class="title" style="color: #fff; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);">企业文化</p>
        </div>
        <div class="aboutUsCorporateCultureMsg">
            <transition name="aboutUsCultureMsgDiv">
                <div id="cultureDiv1" class="aboutUsCorporateCultureDiv" @mouseover="aboutUsCorporateCultureMsgClick(1)" @mouseleave="aboutUsCorporateCultureMsgDisHover(1)">
                    <div class="aboutUsCorporateCultureIcon1">
                        <img v-if="cultureMsgShow1" src="../assets/aboutUs/aboutUsCorporateCultureHoverIcon1.png" style="width: 100%; height: 100%;">
                        <img v-else src="../assets/aboutUs/aboutUsCorporateCultureIcon1.png" style="width: 100%; height: 100%;">
                    </div>
                    <div class="aboutUsCorporateCultureText" style="width: 75%; left: 53%;">
                        <p id="cultureText1" class="textTitle">企业愿景</p>
                        <transition name="aboutUsCultureMsg">
                            <p v-if="cultureMsgShow1" class="textMsg" style="color: #ffa800; margin-top: 13.8%;">打造一流创新企业，引领数字化服务平台</p>
                        </transition>
                    </div>
                </div>
            </transition>
            <transition name="aboutUsCultureMsgDiv">
                <div id="cultureDiv2" class="aboutUsCorporateCultureDiv" @mouseover="aboutUsCorporateCultureMsgClick(2)" @mouseleave="aboutUsCorporateCultureMsgDisHover(2)">
                    <div class="aboutUsCorporateCultureIcon1">
                        <img v-if="cultureMsgShow2" src="../assets/aboutUs/aboutUsCorporateCultureHoverIcon2.png" style="width: 100%; height: 100%;">
                        <img v-else src="../assets/aboutUs/aboutUsCorporateCultureIcon2.png" style="width: 100%; height: 100%;">
                    </div>
                    <div class="aboutUsCorporateCultureText" style="width: 70%; left: 53%;">
                        <p id="cultureText2" class="textTitle">企业理念</p>
                        <transition name="aboutUsCultureMsg">
                            <p v-if="cultureMsgShow2" class="textMsg" style="color: #ffa800; margin-top: 13.8%;">科技为先、以人为本、制度为纲、文化为魂</p>
                        </transition>
                    </div>
                </div>
            </transition>
            <transition name="aboutUsCultureMsgDiv">
                <div id="cultureDiv3" class="aboutUsCorporateCultureDiv" @mouseover="aboutUsCorporateCultureMsgClick(3)" @mouseleave="aboutUsCorporateCultureMsgDisHover(3)">
                    <div class="aboutUsCorporateCultureIcon1">
                        <img v-if="cultureMsgShow3" src="../assets/aboutUs/aboutUsCorporateCultureHoverIcon3.png" style="width: 100%; height: 100%;">
                        <img v-else src="../assets/aboutUs/aboutUsCorporateCultureIcon3.png" style="width: 100%; height: 100%;">
                    </div>
                    <div class="aboutUsCorporateCultureText" style="width: 70%; left: 53%;">
                        <p id="cultureText3" class="textTitle">企业精神</p>
                        <transition name="aboutUsCultureMsg">
                            <p v-if="cultureMsgShow3" class="textMsg" style="color: #ffa800; margin-top: 13.8%;">创新、高效、合作</p>
                        </transition>
                    </div>
                </div>
            </transition>
            <transition name="aboutUsCultureMsgDiv">
                <div id="cultureDiv4" class="aboutUsCorporateCultureDiv" @mouseover="aboutUsCorporateCultureMsgClick(4)" @mouseleave="aboutUsCorporateCultureMsgDisHover(4)">
                    <div class="aboutUsCorporateCultureIcon1">
                        <img v-if="cultureMsgShow4" src="../assets/aboutUs/aboutUsCorporateCultureHoverIcon4.png" style="width: 100%; height: 100%;">
                        <img v-else src="../assets/aboutUs/aboutUsCorporateCultureIcon4.png" style="width: 100%; height: 100%;">
                    </div>
                    <div class="aboutUsCorporateCultureText" style="width: 70%; left: 53%;">
                        <p id="cultureText4" class="textTitle">企业使命</p>
                        <transition name="aboutUsCultureMsg">
                            <p v-if="cultureMsgShow4" class="textMsg" style="color: #ffa800; margin-top: 13.8%;">服务国家战略，创造客户价值，谋求员工幸福</p>
                        </transition>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUsCorporateCulture',
    data() {
        return {
            cultureMsgShow1: false,
            cultureMsgShow2: false,
            cultureMsgShow3: false,
            cultureMsgShow4: false,
            currentcultureDivIndex: 0,
        }
    },
    mounted() {
        document.getElementById('cultureText1').style.color = '#fff';
        document.getElementById('cultureText2').style.color = '#fff';
        document.getElementById('cultureText3').style.color = '#fff';
        document.getElementById('cultureText4').style.color = '#fff';
    },    
    methods: {
        aboutUsCorporateCultureMsgClick(currentIndex){
            let div1 = document.getElementById('cultureDiv1');
            let div2 = document.getElementById('cultureDiv2');
            let div3 = document.getElementById('cultureDiv3');
            let div4 = document.getElementById('cultureDiv4');
            switch (currentIndex){
                case 1:
                    this.cultureMsgShow1 = true;
                    div1.style.transform = 'translateY(0)';
                    document.getElementById('cultureText1').style.color = '#ffa800';
                    break;
                case 2:
                    this.cultureMsgShow2 = true;
                    div2.style.transform = 'translateY(0)';
                    document.getElementById('cultureText2').style.color = '#ffa800';
                    break;
                case 3:
                    this.cultureMsgShow3 = true;
                    div3.style.transform = 'translateY(0)';
                    document.getElementById('cultureText3').style.color = '#ffa800';
                    break;
                case 4:
                    this.cultureMsgShow4 = true;
                    div4.style.transform = 'translateY(0)';
                    document.getElementById('cultureText4').style.color = '#ffa800';
                    break;
            }
            this.currentcultureDivIndex = currentIndex;
        },
        aboutUsCorporateCultureMsgDisHover(currentIndex){
            let div1 = document.getElementById('cultureDiv1');
            let div2 = document.getElementById('cultureDiv2');
            let div3 = document.getElementById('cultureDiv3');
            let div4 = document.getElementById('cultureDiv4');
            switch (this.currentcultureDivIndex){
                case 1:
                    this.cultureMsgShow1 = false;
                    div1.style.transform = 'translateY(43%)';
                    document.getElementById('cultureText1').style.color = '#fff';
                    break;
                case 2:
                    this.cultureMsgShow2 = false;
                    div2.style.transform = 'translateY(43%)';
                    document.getElementById('cultureText2').style.color = '#fff';
                    break;
                case 3:
                    this.cultureMsgShow3 = false;
                    div3.style.transform = 'translateY(43%)';
                    document.getElementById('cultureText3').style.color = '#fff';
                    break;
                case 4:
                    this.cultureMsgShow4 = false;
                    div4.style.transform = 'translateY(43%)';
                    document.getElementById('cultureText4').style.color = '#fff';
                    break;
            }
        }
    }
}
</script>

<style>
.aboutUsCorporateCultureTitle{
    position: relative;
    width: 100%;
    height: 35%;
}
.aboutUsCorporateCultureMsg{
    position: absolute; 
    bottom: 0;
    left: 50%; 
    transform: translateX( -50% );
    display: flex;
    justify-content: space-between;
    width: 62.5%;
    height: 65%;
    overflow: hidden;
}
.aboutUsCorporateCultureDiv{
    position: relative;
    width: 17.5%;
    height: 100%;
    transform: translateY( 43% ); 
    transition: transform 1s ease;
	background-color: rgba(0, 0, 0, 0.3);
}
.aboutUsCorporateCultureIcon1{
    position: absolute; 
    width: 19%;
    height: 9.2%;
    top: 5.7%;
    left: 21.4%;
}
.aboutUsCorporateCultureText{
    position: absolute; 
    width: 63.3%;
    height: 70%;
    top: 24%;
    left: 50%; 
    transform: translateX( -50% );
}
/* 公司文化文本显示隐藏动效 */
.aboutUsCultureMsg-enter-to,
.aboutUsCultureMsg-leave-from{
    opacity: 1;
}
.aboutUsCultureMsg-enter,
.aboutUsCultureMsg-leave-to {
    opacity: 0;
}
.aboutUsCultureMsg-enter-active,
.aboutUsCultureMsg-leave-active {
    transition: 0.3s;
}
</style>