<template>
    <div class="projectMilitaryOverview">
        <div class="projectMilitaryOverviewDiv">
            <p class="title">产品概述</p>
            <p class="textMsg" :style="{width:this.width+'px',marginBottom:this.bottom+'px'}">{{ overViewText }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectMilitaryOverview',
    props:{
        overViewText:{
            type: String,
            required: true,
        },
        isShort:{
            type: Boolean,
            default: false,
        },
        isMilitary:{
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            width: 1200,
            bottom: 0,
        }
    },
    created() {
        this.init()
    },
    methods: {
        init () {
            if(this.isShort){
                this.width = '1190';
            }
            if(this.isMilitary){
                this.bottom = '100';
            }
        },
    },
}
</script>

<style>
.projectMilitaryOverview{
    position: relative; 
    width: 100%; 
    display: inline-block;
    background-color: #f6f6f6;
    justify-content: center;
}
.projectMilitaryOverviewDiv .title{
    color: #333; 
    width: 100%;
    text-align: center;
    margin-top: 100px;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 4px;
}
.projectMilitaryOverviewDiv{
    width: 1200px;
    display: inline-block;
}
.projectMilitaryOverviewDiv .textMsg{
    color: #333; 
    font-size: 16px;
    line-height: 32px;
    /* letter-spacing: 2px; */
    text-indent: 2em;
    margin-top: 60px;
    /* margin-bottom: 100px; */
    white-space: pre-wrap;
}
</style>