<template>
  <div class="home">

    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div @mouseover="homeTopDivHover" @mouseleave="homeTopDivDisHover" class="topDiv" :style="{width:this.titleSize.width+'px', height:this.titleSize.height+'px'}">
      <div style="width: 300%; height: 100%; display: flex; justify-content: left;position:absolute;left: -100%;">
        <div id="topDiv3" class="top" :style="{width:this.titleSize.width+'px', height:this.titleSize.height+'px'}">
          <div class="topSpan" :style="{marginTop:this.topSpanMargin.top+'px', marginLeft:this.topSpanMargin.left+'px'}">
            <p class="topTitle" style="text-align: left; width: 76%;">实时音视频</p>
            <p class="topTitle" style="text-align: right; width: 60%; margin-top: 3%;">让面对面随时随地</p>
            <p class="topText">全新一站式互动直播，主播连麦直播间多样化互动，<br>
              通过移动直播SDK打造跨平台一对多、多对多的超流畅直播体验
            </p>                                
          </div>
          <img style="width: 100%; height: 100%" src="../assets/home/banner3.webp" alt="顶部轮播图3"/>
        </div>
        <div id="topDiv1" class="top" :style="{width:this.titleSize.width+'px', height:this.titleSize.height+'px'}">
          <div class="topSpan" :style="{marginTop:this.topSpanMargin.top+'px', marginLeft:this.topSpanMargin.left+'px'}">
            <p class="topTitle" style="text-align: left; width: 76%;">虚拟仿真平台</p>
            <p class="topTitle" style="text-align: right; width: 76%; margin-top: 3%;">让操作更还原、更真实</p>
            <p class="topText">通过计算机技术和虚拟现实技术，模拟实际场景和过程， <br>
              以实现各种应用场景的模拟和仿真；为各行各业提供了高度真实、<br>
              安全高效的模拟环境，以提升效率、降低风险、培训人才</p>                
          </div>
          <img style="width: 100%; height: 100%" src="../assets/home/banner1.webp" alt="顶部轮播图1"/>
        </div>
        <div id="topDiv2" class="top" :style="{width:this.titleSize.width+'px', height:this.titleSize.height+'px'}">
          <div class="topSpan" :style="{marginTop:this.topSpanMargin.top+'px', marginLeft:this.topSpanMargin.left+'px'}">
            <p class="topTitle" style="text-align: left; width: 76%;">传统与数字的结合</p>
            <p class="topTitle" style="text-align: right; width: 76%; margin-top: 3%;">让生产更智能、更高效</p>
            <p class="topText">人工智能、大数据分析等技术，提升生产线的智能化水平，<br>
              打造一个与客户需求完全匹配的解决方案<br>
              实现生产过程的优化、灵活性和可持续发展。</p>
          </div>
          <img style="width: 100%; height: 100%" src="../assets/home/banner2.webp" alt="顶部轮播图2"/>
        </div>
      </div>
      <div style="position:absolute; right: 5.2%; bottom: 5.6%; width: 81.5%; height: 8.5%; display: flex;">
        <div style="width: 43%; display: flex; justify-content: space-between; position: relative;">
          <div style="width: 3%; position: absolute; top: 50%; transform: translateY( -50% ); color: #fff;"><span>01</span></div>
          <div style="width: 90%; display: flex; position: absolute;height: 2px; cursor:pointer; top: 50%; left: 0.5%; transform: translateY(-50%);">
            <div :class="titlePoint1" style="left: 36%;" />
            <div :class="titlePoint2" style="left: 69%;" />
            <div style="width: 33%; height: 100%; position:absolute; left: 3.5%" @click="changeCurrentTitle(0)">
              <div :class="titleLine1" />
            </div>
            <div style="width: 33%; height: 100%; position:absolute; left: 36%" @click="changeCurrentTitle(1)">
              <div :class="titleLine2" />
            </div>
            <div style="width: 33%; height: 100%; position:absolute; left: 70%" @click="changeCurrentTitle(2)">
              <div :class="titleLine3" />
            </div>
          </div>
          <div class="textMsg" style="width: 4%; position:absolute; top: 50%; transform: translateY( -50% ); color: #fff; left: 94%;"><span>03</span></div>
        </div>
      </div>
    </div>
    <!-- 公司简介 -->
    <div class="profile" :style="{width:this.titleSize.width+'px', height:this.profile+'px'}">
      <HomeCompanyIntroduction />
    </div>
    <!-- 产品中心 -->
    <div class="productCenter" :style="{width:this.titleSize.width+'px', height:this.productCenter+'px'}">
      <div :style="{width:this.titleSize.width+'px', height:this.productCenter+'px'}" style="position: absolute;">
        <p class="title" style="color: #fff; margin-top: 10%; margin-left: 11%;">产品类型</p>
        <div style="width: auto; height: auto; overflow: hidden; margin-top: 1.5%; margin-left: 11%;">
          <p class="textMsg" style="color: #fff;">为军事训练、船舶航海等领域提供仿真的模拟环境；<br>
            助力企业数字化转型，为制造业提供360°解决方案</p>
        </div>
      </div>
    </div>
    <div class="product" :style="{width:this.titleSize.width+'px', height:this.productCenter+'px'}">
      <HomeProductCarousel />
    </div>
    <!-- 为什么选择威迪欧 -->
    <div :style="{width:this.titleSize.width+'px', height:this.chooseUs+'px'}">
      <HomeChooseUs />
    </div>
    <!-- 涉及领域 -->
    <div :style="{width:this.titleSize.width+'px', height:this.involve+'px'}">
      <HomeInvolvedField />
    </div>
    <!-- 客户案例 -->
    <div :style="{width:this.titleSize.width+'px', height:this.case+'px'}" style="background-color: #f6f6f6;">
      <HomeCustomerCases />
    </div>
    <!-- 联系我们 -->
    <ContactUs :pageType="1" />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import HomeCompanyIntroduction from '../components/HomeCompanyIntroduction.vue'
import HomeProductCarousel from '../components/HomeProductCarousel.vue'
import HomeChooseUs from '../components/HomeChooseUs.vue'
import HomeInvolvedField from '../components/HomeInvolvedField.vue'
import HomeCustomerCases from '../components/HomeCustomerCases.vue'
import ContactUs from '../components/contactUs.vue'

export default {
  name: 'HomeView',
  components: {
    HomeCompanyIntroduction,
    HomeProductCarousel,
    HomeChooseUs,
    HomeInvolvedField,
    HomeCustomerCases,
    ContactUs,
  },
  data() {
    return {
      titleSize:{
        width: '1920',
        height: '893',
      },
      topSpanMargin:{
        top: '355',
        left: '256',
      },
      profile: '527',
      productCenter: '605',
      solution: '742',
      chooseUs: '1030',
      involve: '670',
      case:'620',
      // * 设计稿尺寸（px）
      baseWidth: 1920,
      baseHeight: 893,
      index: 0,
      id: 0,
      hoverID: 0,
      isHover: false,
      changeable: true,//标志是否可以切换顶部轮播图，防止切换过快出现白屏
      titleLine1: 'titleCurrentLine',
      titleLine2: 'titleLine',
      titleLine3: 'titleLine',
      titlePoint1: 'titleCurrentPoint',
      titlePoint2: 'titlePoint',
    }
  },
    beforeDestroy(){ //切换页面时销毁定时器，重置相关参数，防止报错
        if(this.id){
            clearInterval(this.id);
        }
    },
  mounted () {
    //加载后先计算一遍缩放比例
    this.calcRate()
    //生成一个监听器：窗口发生变化从新计算计算一遍缩放比例
    window.addEventListener('resize', this.calcRate)
    //开始轮播
    this.autoplay()
  },
  methods: {
    calcRate () {
      if(document.body.clientWidth <= 1200){
        this.titleSize.width = 1200;
        this.titleSize.height = 558;
        this.topSpanMargin.top = 223;
        this.topSpanMargin.left = 156;
        this.profile = 329;
        this.productCenter = 378;
        this.solution = 464;
        this.chooseUs = 644;
        this.involve = 400;
        this.case = 387;
      }else{
        this.titleSize.height = document.body.clientWidth / 2.15;
        this.titleSize.width = document.body.clientWidth;
        this.topSpanMargin.top = this.titleSize.height * 0.4;
        this.topSpanMargin.left = this.titleSize.width * 0.13;
        this.profile = document.body.clientWidth * 0.275;
        this.productCenter = document.body.clientWidth * 0.315;
        this.solution = document.body.clientWidth * 0.386;
        this.chooseUs = document.body.clientWidth * 0.536;
        this.involve = document.body.clientWidth * 0.333;
        this.case = document.body.clientWidth * 0.32;
      }
      // console.log('**************width:' + this.titleSize.width + '**************height:' + this.titleSize.height);
    },
    homeTopDivHover(){
      if(this.isHover)
        return;
      this.isHover = true;
      clearInterval(this.id);
      let _this = this;
      this.hoverID=setInterval(function(){
        _this.carousel();
      },10000)
    },
    homeTopDivDisHover(){
      this.isHover = false;
      clearInterval(this.hoverID);
      this.autoplay();
    },
    carousel(){
      let div1 = document.getElementById('topDiv1');
      let div2 = document.getElementById('topDiv2');
      let div3 = document.getElementById('topDiv3');
      /* 限制切换图片后1s内不能再次切换 */
      let _this = this;
      if( !this.changeable )
        return;
      this.changeable = false;
      setTimeout(function(){
        _this.changeable = true;
      }, 1000);
      this.index++;
      if(this.index>=3){
        this.index=0;
      }
      switch (this.index){
        case 0:
          div1.style.transform = 'translateX( 0% )'
          div1.style.transition = 'transform 1s ease';
          div2.style.transform = 'translateX( 0% )'
          div2.style.transition = 'transform 0s ease';
          div3.style.transform = 'translateX( 0% )'
          div3.style.transition = 'transform 1s ease';
          this.titleLine2 = 'titleLine';
          this.titleLine3 = 'titleLine';
          this.titlePoint2 = 'titlePoint';
          break;
        case 1:
          div1.style.transform = 'translateX( -100% )'
          div1.style.transition = 'transform 1s ease';
          div2.style.transform = 'translateX( -100% )'
          div2.style.transition = 'transform 1s ease';
          div3.style.transform = 'translateX( 200% )'
          div3.style.transition = 'transform 0s ease';
          this.titleLine2 = 'titleCurrentLine';
          this.titleLine3 = 'titleLine';
          this.titlePoint2 = 'titleCurrentPoint';
          break;
        case 2:
          div1.style.transform = 'translateX( 100% )'
          div1.style.transition = 'transform 0s ease';
          div2.style.transform = 'translateX( -200% )'
          div2.style.transition = 'transform 1s ease';
          div3.style.transform = 'translateX( 100% )'
          div3.style.transition = 'transform 1s ease';
          this.titleLine2 = 'titleCurrentLine';
          this.titleLine3 = 'titleCurrentLine';
          this.titlePoint2 = 'titleCurrentPoint';
          break;
        }
    },
    autoplay(){
      let _this = this;
      this.id=setInterval(function(){
        _this.carousel();
      },5000)
		},
    changeCurrentTitle(currentIndex){
      /* 若当前图片和选定图片是同一张，或与上次切换间隔不超过1s，切换时可能因发白屏，则不切换 */
      if(this.index == currentIndex || !this.changeable)
        return;

      /* 重置计时器，否则可能刚切换图片就被切走了 */
      clearInterval(this.id);
      if((this.index == 0 && currentIndex == 1) || (this.index == 1 && currentIndex == 2) || (this.index == 2 && currentIndex == 0)){
        this.carousel();
        return;
      }
      /* 限制切换图片后1s内不能再次切换 */
      this.changeable = false;
      let _this = this;
      setTimeout(function(){
        _this.changeable = true;
      }, 1000);
      let div1 = document.getElementById('topDiv1');
      let div2 = document.getElementById('topDiv2');
      let div3 = document.getElementById('topDiv3');
      switch (currentIndex){
        case 0:
          div1.style.transform = 'translateX( 0% )'
          div1.style.transition = 'transform 1s ease';
          div2.style.transform = 'translateX( 0% )'
          div2.style.transition = 'transform 1s ease';
          div3.style.transform = 'translateX( 0% )'
          div3.style.transition = 'transform 0s ease';
          this.titleLine2 = 'titleLine';
          this.titleLine3 = 'titleLine';
          this.titlePoint2 = 'titlePoint';
          break;
        case 1:
          div1.style.transform = 'translateX( -100% )'
          div1.style.transition = 'transform 0s ease';
          div2.style.transform = 'translateX( -100% )'
          div2.style.transition = 'transform 1s ease';
          div3.style.transform = 'translateX( 200% )'
          div3.style.transition = 'transform 1s ease';
          this.titleLine2 = 'titleCurrentLine';
          this.titleLine3 = 'titleLine';
          this.titlePoint2 = 'titleCurrentPoint';
          break;
        case 2:
          div1.style.transform = 'translateX( 100% )'
          div1.style.transition = 'transform 1s ease';
          div2.style.transform = 'translateX( -200% )'
          div2.style.transition = 'transform 0s ease';
          div3.style.transform = 'translateX( 100% )'
          div3.style.transition = 'transform 1s ease';
          this.titleLine2 = 'titleCurrentLine';
          this.titleLine3 = 'titleCurrentLine';
          this.titlePoint2 = 'titleCurrentPoint';
          break;
        }
      this.index = currentIndex;
      // this.autoplay();
    },
  },
}
</script>

<style>
  .home{
    width: 100%;
    height: auto;
  }
  /* 顶部轮播部分 */
  .topDiv{
    background-size: contain ;
    border: none;
    padding: 0px;
    overflow: hidden ;
    display: flex;
    position:relative;
    overflow: hidden;
  }
  .top{
    position:relative;
    transition: transform 1s ease;
  }
  .topSpan{
    position:absolute;
    width: 55.4%;
  }
  /* 轮播标题文本 */
  .topTitle{
    font-family: MySourceHanSansSC-Heavy;
    font-weight: bold;
    font-stretch: normal;
    color: #ffffff;
    line-break: anywhere;
  }
  /* 轮播普通文本 */
  .topText{
    font-family: MySourceHanSansSC-Regular;
    color: #ffffff;
    text-align: left;
  }
  /* 轮播进度条 */
  .titleLine{
    width: 100%; 
    height: 2px; 
    background-color: #a6a6a6; 
    position:absolute; 
    top: 50%; 
    transform: translateY( -50% );
    opacity: 0.5;
    z-index: 1;
  }
  .titlePoint{
    position:absolute; 
    left: 36%; 
    top: 50%; 
    transform: translateY( -50% ); 
    border-radius: 5px; 
    width: 10px; 
    height: 10px; 
    background-color: #a6a6a6;
    z-index: 3;
  }
  .titleCurrentLine{
    width: 100%; 
    height: 2px; 
    background-color: #fff; 
    position:absolute; 
    top: 50%; 
    transform: translateY( -50% );
    z-index: 1;
  }
  .titleCurrentPoint{
    position:absolute; 
    left: 36%; 
    top: 50%; 
    transform: translateY( -50% ); 
    border-radius: 5px; 
    width: 10px; 
    height: 10px; 
    background-color: #fff;
    z-index: 3;
  }

  /* 基础信息 */
  .profile{
    background-color: #f6f6f6; 
    position: relative; 
    z-index: 10;
  }
  /* 基础信息文本部分 */
  .title{
    margin: 0px;
    font-family: MySourceHanSansSC-Heavy;
    font-weight: bold;
    font-stretch: normal;
    color: #333333;
    text-align: left;
    line-break: anywhere;
  }
  .textMsg{
    font-family: MySourceHanSansSC-Regular;
    margin: 0px;
    color: #333333;
    text-align: left;
    line-break: anywhere;
  }
  /* 产品中心 */
  .productCenter{
    position: relative;
    z-index: 8;
    background-color: #9bc3da; 
    background-image: url('../assets/home/caseProfileBackgroundImg.jpg');
    /* 背景图像覆盖整个容器 */
    background-size: cover;
    /* 背景图像居中显示 */
    background-position: center;
  }
  .product{
    background-color: #f6f6f6; 
    position: relative;
    z-index: 10;
    line-break: anywhere;
  }
  
.textTitle{
    font-family: MySourceHanSansSC-Heavy;
    margin: 0;
    text-align: left;
    line-break: anywhere;
}

button{
  cursor:pointer
}

/*分辨率小于1200*/
@media screen and (max-width: 1200px) {
  .topTitle{
    margin: 0px;
    line-height: 40px;
    font-size: 40px;
    letter-spacing: 2px;
  }
  .topText{
    margin-top: 38px;
    width: 506px;
    height: 59px;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 1px;
  }
  .title{
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 2px;
  }
  .textMsg{
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1px;
  }
  .textTitle{
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1px;
  }
}
/*分辨率在1200-1360之间*/
@media screen and (min-width: 1200px) {
  .topTitle{
    margin: 0px;
    line-height: 42px;
    font-size: 42px;
    letter-spacing: 3px;
  }
  .topText{
    margin-top: 43px;
    width: 574px;
    height: 59px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
  .title{
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 2px;
  }
  .textMsg{
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.8px;
  }
  .textTitle{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2px;
  }
}
/*分辨率在1360-1600之间*/
@media screen and (min-width: 1360px) {
  .topTitle{
    margin: 0px;
    line-height: 44px;
    font-size: 44px;
    letter-spacing: 4px;
  }
  .topText{
    margin-top: 51px;
    width: 675px;
    height: 70px;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 1.1px;
  }
  .title{
    font-size: 35px;
    line-height: 35px;
    letter-spacing: 2px;
  }
  .textMsg{
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 1.1px;
  }
  .textTitle{
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 2px;
  }
}
@media screen and (min-width: 1450px) {
  .topTitle{
    margin: 0px;
    line-height: 46px;
    font-size: 46px;
    letter-spacing: 4px;
  }
  .topText{
    margin-top: 51px;
    width: 675px;
    height: 70px;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 1.1px;
  }
  .title{
    font-size: 35px;
    line-height: 35px;
    letter-spacing: 2px;
  }
  .textMsg{
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 1.1px;
  }
  .textTitle{
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 2px;
  }
}
@media screen and (min-width: 1500px) {
  .topTitle{
    margin: 0px;
    line-height: 48px;
    font-size: 48px;
    letter-spacing: 4px;
  }
  .topText{
    margin-top: 51px;
    width: 675px;
    height: 70px;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 1.1px;
  }
  .title{
    font-size: 35px;
    line-height: 35px;
    letter-spacing: 2px;
  }
  .textMsg{
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.2px;
  }
  .textTitle{
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}
/*分辨率大于1600*/
@media screen and (min-width: 1600px) {
  .topTitle{
    margin: 0px;
    line-height: 56px;
    font-size: 56px;
    letter-spacing: 5px;
  }
  .topText{
    margin-top: 62px;
    width: 640px;
    height: 83px;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1.1px;
  }
  .title{
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 3px;
  }
  .textMsg{
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 1.1px;
  }
  .textTitle{
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 2px;
  }
}
@media screen and (min-width: 1800px){
  .topTitle{
    margin: 0px;
    line-height: 60px;
    font-size: 60px;
    letter-spacing: 5px;
  }
  .topText{
    margin-top: 62px;
    width: 640px;
    height: 83px;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1.1px;
  }
  .title{
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 3px;
  }
  .textMsg{
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 1.1px;
  }
  .textTitle{
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 2px;
  }
}
</style>