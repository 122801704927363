<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 产品概述 -->
        <ProjectMilitaryOverview :overViewText='overViewText' :isShort="true" :isMilitary="true" />
        <!-- 应用领域 -->
        <div class="projectMilitaryApply">
            <p class="title">应用领域</p>
            <div class="projectMilitaryApplyBox">
                <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
            </div>
        </div>
        <!-- 产品特色 -->
        <div class="projectMilitaryFeaturesDiv" style="height: 1434px;">
            <p class="title">产品特色</p>
            <div class="projectMilitaryFeaturesArea" style="height: 1240px;">
                <ProjectMilitaryFeaturesRight :item="item1" />
                <ProjectMilitaryFeaturesLeft :item="item2" />
                <ProjectMilitaryFeaturesRight :item="item3" />
            </div>
        </div>
        <!-- 其他案例 -->
        <!-- <Others :index="-1" :currentType="0" /> -->
        <div style="width: 100%; height: 100px; background-color: #f6f6f6;" />
        <!-- 联系我们 -->
        <ContactUs :pageType="0" />
    </div>
</template>

<script>
import areaImg1 from '../assets/decisionSupport/areaImg1.webp'
import areaImg2 from '../assets/decisionSupport/areaImg2.webp'
import areaImg3 from '../assets/decisionSupport/areaImg3.webp'
import areaImg4 from '../assets/decisionSupport/areaImg4.webp'
import featureImg1 from '../assets/decisionSupport/featureImg1.png'
import featureImg2 from '../assets/decisionSupport/featureImg2.png'
import featureImg3 from '../assets/decisionSupport/featureImg3.png'

import CaseTop from '../components/CaseTop.vue'
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
import ProjectMilitaryFeaturesRight from '../components/ProjectMilitaryFeaturesRight.vue'
import ProjectMilitaryFeaturesLeft from '../components/ProjectMilitaryFeaturesLeft.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'ProductDecisionSupportView',
    components: {
        CaseTop,
        ProjectMilitaryOverview,
        AboutOneApplicationArea,
        ProjectMilitaryFeaturesRight,
        ProjectMilitaryFeaturesLeft,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 0,
            topTitle: '辅助决策软件',
            // 产品概述
            overViewText: '辅助决策是一种在决策过程中提供的支持，旨在帮助决策者更科学、更有效地做出选择和决定。可以通过多种方式实现，包括但不限于计算机信息管理系统、管理信息系统、战略思维和决策逻辑等。这种决策支持系统可以包括数据和信息检索、处理、方案策划、多元策略选择、整合分歧等多方面内容。它不仅在管理咨询和秘书工作中发挥作用，而且在政策制定、企业管理等众多领域都有广泛应用。辅助决策系统通常基于互联网技术、信息管理技术和自然语言处理等技术，能够提供针对性和多层次的决策分析报告，为决策主体提供全方位的决策支持和知识服务',
            // 应用领域
            info: [
                { p1: '军事领域', span: '军用监测设备在军事领域上的应用非常广泛，例如武器、传感器、飞机船舶的运行等安装可以检测故障的设备，保障使用时的设备可以正常运转，有利于国防安全', isActive: false, backgroundImage: areaImg1 },
                { p1: '制造业', span: '辅助决策系统可用于生产计划、供应链管理、质量控制等方面的决策', isActive: false, backgroundImage: areaImg2 },
                { p1: '医疗行业', span: '辅助决策系统可以辅助医生进行诊断、治疗方案选择等医疗决策', isActive: false, backgroundImage: areaImg3 },
                { p1: '市场营销', span: '辅助决策系统能够帮助企业进行目标市场选择、市场调研等决策', isActive: false, backgroundImage: areaImg4 }
            ],
            // 产品特色
            item1: {title: '提高决策效率', text: '辅助决策系统能够快速处理和分析大量的数据，加快决策的速度', img: featureImg1},
            item2: {title: '提升决策质量', text: '通过数据分析和决策模型，辅助决策系统能够提供准确、科学的决策建议', img: featureImg2},
            item3: {title: '降低决策风险', text: '辅助决策系统能够帮助决策者综合考虑各种因素和情况，减少决策风险', img: featureImg3},
        }
    },
}
</script>