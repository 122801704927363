<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px'}">
            <div class="caseMainFunctionLargeDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/jinxiaocun/computer.png">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" :isLong="true" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" :isLong="true" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" />
                    </div>
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem5" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem6" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem7" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem8" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem5" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem6" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/jinxiaocun/icon1.png'
import icon2 from '../assets/case/jinxiaocun/icon2.png'
import icon3 from '../assets/case/jinxiaocun/icon3.png'
import icon4 from '../assets/case/jinxiaocun/icon4.png'
import icon5 from '../assets/case/jinxiaocun/icon5.png'
import icon6 from '../assets/case/jinxiaocun/icon6.png'
import icon7 from '../assets/case/jinxiaocun/icon7.png'
import icon8 from '../assets/case/jinxiaocun/icon8.png'
import pic1 from '../assets/case/jinxiaocun/projectPic1.png'
import pic2 from '../assets/case/jinxiaocun/projectPic2.png'
import pic3 from '../assets/case/jinxiaocun/projectPic3.png'
import pic4 from '../assets/case/jinxiaocun/projectPic4.png'
import pic5 from '../assets/case/jinxiaocun/projectPic5.png'
import pic6 from '../assets/case/jinxiaocun/projectPic6.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseInventoryManagementView',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '620',
            projectPic:'427',
            topTitle: '进销存管理软件',
            pojectOverviewMsg: '“进销存管理软件”是一种用于帮助企业管理产品进货、销售和库存的系统，通过集成采购、销售和库存管理功能，实现对产品流动和库存状态的实时监控和跟踪，以提高企业的运营效率、降低库存成本、优化供应链管理，进而提升企业竞争力和客户满意度的工具。该软件能够自动化处理销售订单、采购订单、库存盘点、库存调拨等流程，提供数据分析、报表生成和预警提醒等功能，帮助企业实现库存精确控制、订单准确配送、供需平衡以及成本控制，从而更好地满足市场需求并获得持续发展',
            iconItem1: {title:'采购管理', text: '支持采购订单的创建、发货跟踪和供应商管理', icon: icon1},
            iconItem2: {title:'销售管理', text: '实现销售订单的创建、发货和收款跟踪等流程', icon: icon2},
            iconItem3: {title:'库存管理', text: '实时监控库存状况，支持库存预警、库存盘点和库存调拨', icon: icon3},
            iconItem4: {title:'计划与预测', text: '基于历史销售数据和趋势分析，以优化库存管理和供应链调配', icon: icon4},
            iconItem5: {title:'供应商管理', text: '维护供应商资料，并能够评估供应商的绩效和信誉', icon: icon5},
            iconItem6: {title:'客户管理', text: '记录客户资料、历史交易和偏好，支持客户订单管理', icon: icon6},
            iconItem7: {title:'报表与数据分析', text: '生成各类经营报表和数据分析图表，帮助管理层监控业务状况', icon: icon7},
            iconItem8: {title:'系统集成与扩展', text: '能够与其他企业系统进行集成，支持定制化需求和扩展功能', icon: icon8},
            picItem1: {title:'采购管理', pic: pic1},
            picItem2: {title:'销售管理', pic: pic2},
            picItem3: {title:'库存管理', pic: pic3},
            picItem4: {title:'供应商管理', pic: pic4},
            picItem5: {title:'客户管理', pic: pic5},
            picItem6: {title:'数据报表', pic: pic6},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 2,
            currentType: 2,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 387.5;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.322;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    },
}
</script>