<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px'}">
            <div class="caseMainFunctionLargeDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/rencaiyun/computer.png">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" />
                    </div>
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem5" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem6" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem7" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem8" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem5" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem6" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/rencaiyun/icon1.png'
import icon2 from '../assets/case/rencaiyun/icon2.png'
import icon3 from '../assets/case/rencaiyun/icon3.png'
import icon4 from '../assets/case/rencaiyun/icon4.png'
import icon5 from '../assets/case/rencaiyun/icon5.png'
import icon6 from '../assets/case/rencaiyun/icon6.png'
import icon7 from '../assets/case/rencaiyun/icon7.png'
import icon8 from '../assets/case/rencaiyun/icon8.png'
import pic1 from '../assets/case/rencaiyun/projectPic1.png'
import pic2 from '../assets/case/rencaiyun/projectPic2.png'
import pic3 from '../assets/case/rencaiyun/projectPic3.png'
import pic4 from '../assets/case/rencaiyun/projectPic4.png'
import pic5 from '../assets/case/rencaiyun/projectPic5.jpg'
import pic6 from '../assets/case/rencaiyun/projectPic6.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseRencaiyunView',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '620',
            projectPic:'427',
            topTitle: '在线人才招聘会',
            pojectOverviewMsg: '“在线人才招聘会”是一个基于云计算和人工智能技术的招聘平台，旨在为企业和求职者提供便捷高效的招聘和求职服务。通过虚拟招聘会场、在线简历投递、职位发布和搜索、实时在线面试等功能，人才云招聘会实现了企业和人才之间的互动和匹配，帮助企业快速找到合适的人才，同时也让求职者更容易找到适合自己的职位机会。人才云招聘会致力于打造一个全面、高效、智能的人才招聘平台，为企业和求职者搭建起连接的桥梁，促进人才市场的发展和共赢',
            iconItem1: {title:'虚拟招聘会场', text: '提供在线虚拟招聘会场，方便人才和企业进行互动和洽谈', icon: icon1},
            iconItem2: {title:'在线简历投递', text: '在线投递个人简历，便捷地将自己的信息发送给感兴趣的企业', icon: icon2},
            iconItem3: {title:'职位发布和搜索', text: '企业发布招聘职位，人才搜索符合自己兴趣和技能的职位', icon: icon3},
            iconItem4: {title:'实时在线面试', text: '提供实时在线面试功能，方便企业与人才进行远程面试', icon: icon4},
            iconItem5: {title:'数据分析匹配', text: '为企业推荐最适合的人才，并为人才推荐最适合的职位', icon: icon5},
            iconItem6: {title:'企业品牌展示', text: '企业展示自己的品牌形象和核心价值观，吸引人才的关注', icon: icon6},
            iconItem7: {title:'多渠道推广和招募', text: '提供多渠道的推广和招募服务，帮助企业更广泛地吸引人才', icon: icon7},
            iconItem8: {title:'资源整合和推进', text: '提供资源整合和合作推进的机会，促进企业间的合作共赢', icon: icon8},
            picItem1: {title:'应聘者找工作', pic: pic1},
            picItem2: {title:'应聘者查看职位信息', pic: pic2},
            picItem3: {title:'招聘者发布录用信息', pic: pic3},
            picItem4: {title:'招聘者发布职位', pic: pic4},
            picItem5: {title:'人才库', pic: pic5},
            picItem6: {title:'数据分析', pic: pic6},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 2,
            currentType: 1,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 387.5;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.322;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    },
}
</script>