<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px'}" style="height: 445px;">
            <div class="casecaseMainFunctionSmallDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/zujiao/computer.png">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLineHigh">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" :isLong="true" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem5" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem6" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/zujiao/icon1.png'
import icon2 from '../assets/case/zujiao/icon2.png'
import icon3 from '../assets/case/zujiao/icon3.png'
import icon4 from '../assets/case/zujiao/icon4.png'
import pic1 from '../assets/case/zujiao/projectPic1.png'
import pic2 from '../assets/case/zujiao/projectPic2.png'
import pic3 from '../assets/case/zujiao/projectPic3.png'
import pic4 from '../assets/case/zujiao/projectPic4.png'
import pic5 from '../assets/case/zujiao/projectPic5.png'
import pic6 from '../assets/case/zujiao/projectPic6.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseGrassrootsOrganizationalEducation',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            caseMainFunctionSmall: '445',
            projectPic:'427',
            topTitle: '基层组教管理系统',
            pojectOverviewMsg: '“基层组教系统”是教育领域的一项创新举措，旨在为基层教育提供全面、高效的组织和管理解决方案。通过集成多种功能和工具，系统能够帮助教育机构实现课程和教学的科学管理，优化人员的分配和管理，完善教学评价和监督机制，实现教育数据的精确分析和报告生成，以及促进教育机构与家庭的紧密沟通与协作。基层组教系统的运用将有效提升基层教育工作的效率和质量，为教育事业的发展注入新的活力和动力',
            iconItem1: {title:'课程管理', text: '系统可以帮助教育机构制定和管理课程表、教材资源，确保课程的科学性和合理性', icon: icon1, iconDivType: 'high'},
            iconItem2: {title:'人员管理', text: '管理学生、教师以及其他教育工作者的信息，包括个人档案、考勤记录、评价和绩效等', icon: icon2, iconDivType: 'high'},
            iconItem3: {title:'教学评价和监督', text: '帮助教育机构进行教学评价和监督，包括教学观摩、评估和反馈等，提供及时的反馈和改进建议', icon: icon3, iconDivType: 'high'},
            iconItem4: {title:'数据分析和报告', text: '收集、整理和分析基层教育工作的各种数据，生成相关的报告和分析结果', icon: icon4, iconDivType: 'high'},
            picItem1: {title:'数据统计', pic: pic1},
            picItem2: {title:'课程管理', pic: pic2},
            picItem3: {title:'人员管理', pic: pic3},
            picItem4: {title:'调查问卷', pic: pic4},
            picItem5: {title:'制定计划', pic: pic5},
            picItem6: {title:'教学监督', pic: pic6},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 5,
            currentType: 0,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.caseMainFunctionSmall = 278;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.caseMainFunctionSmall = document.body.clientWidth * 0.232;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    },
}
</script>