<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px', height:this.mainFunctionLarge+'px'}">
            <div class="caseMainFunctionLargeDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/chuanbo/computer.png" alt="">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                    </div>
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem5" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem6" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPicOne" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem" :caseProjectPicSize="caseProjectPicSize" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" :topType="topType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/chuanbo/icon1.png'
import icon2 from '../assets/case/chuanbo/icon2.png'
import icon3 from '../assets/case/chuanbo/icon3.png'
import icon4 from '../assets/case/chuanbo/icon4.png'
import icon5 from '../assets/case/chuanbo/icon5.png'
import icon6 from '../assets/case/chuanbo/icon6.png'
import pic from '../assets/case/chuanbo/projectPic.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseShipMotion',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '620',
            projectPic:'852',
            topTitle: '船舶运动操纵仿真软件',
            pojectOverviewMsg: '“船舶运动操纵仿真软件”结合了现代计算机技术和多媒体技术的进步，船舶操作员可以模拟和分析船舶在不同环境条件下的运动行为，以及评估船舶的操纵性能。该软件提供全面且准确的船舶运动仿真功能，可以模拟船舶在不同水域、不同气象条件下的运动行为。通过仿真结果，用户可以观察船舶的运动轨迹、姿态稳定性等，并对船舶的操纵性能进行评估。我们的船舶运动仿真工具采用直观友好的用户界面，使用户能够轻松设定仿真参数、观察仿真结果，并进行数据分析。同时，该工具还可提供可视化的仿真结果展示，如运动轨迹图、速度图等，以帮助用户更好地理解船舶运动行为',
            iconItem1: {title:'船舶操舵、操车的鼠标操作模拟', text: '', icon: icon1},
            iconItem2: {title:'水面环境对船舶运动状态模拟', text: '', icon: icon2},
            iconItem3: {title:'船舶运动状态及轨迹的实时显示', text: '', icon: icon3},
            iconItem4: {title:'船体本身参数设置及船舶运动过程中的效应模拟', text: '', icon: icon4},
            iconItem5: {title:'自动记录船舶在整个仿真实验的所有数据', text: '', icon: icon5},
            iconItem6: {title:'可以输入每个时刻的连续的船舶运动参数进行自动模拟', text: '', icon: icon6},
            picItem: {title:'船舶操纵运动', pic: pic},
            caseProjectPicSize: {width: '100', height: '100'},
            index: 0,
            currentType: 0,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 387.5;
                this.projectPic = 532.5;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.322;
                this.projectPic = document.body.clientWidth * 0.444;
            }
        },
    },
}
</script>

<style>
.caseProjectPicOne{
    position: relative;
}
</style>