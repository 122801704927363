<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" :isLong="true" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px'}">
            <div class="caseMainFunctionLargeDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/yunzhanzhaopin/computer.png">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" :isLong="true" />
                    </div>
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem5" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem6" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem7" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem8" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem5" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem6" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/yunzhanzhaopin/icon1.png'
import icon2 from '../assets/case/yunzhanzhaopin/icon2.png'
import icon3 from '../assets/case/yunzhanzhaopin/icon3.png'
import icon4 from '../assets/case/yunzhanzhaopin/icon4.png'
import icon5 from '../assets/case/yunzhanzhaopin/icon5.png'
import icon6 from '../assets/case/yunzhanzhaopin/icon6.png'
import icon7 from '../assets/case/yunzhanzhaopin/icon7.png'
import icon8 from '../assets/case/yunzhanzhaopin/icon8.png'
import pic1 from '../assets/case/yunzhanzhaopin/projectPic1.png'
import pic2 from '../assets/case/yunzhanzhaopin/projectPic2.png'
import pic3 from '../assets/case/yunzhanzhaopin/projectPic3.png'
import pic4 from '../assets/case/yunzhanzhaopin/projectPic4.jpg'
import pic5 from '../assets/case/yunzhanzhaopin/projectPic5.png'
import pic6 from '../assets/case/yunzhanzhaopin/projectPic6.jpg'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseYunzhanOnlineView',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '620',
            projectPic:'427',
            topTitle: '云平台在线招聘',
            pojectOverviewMsg: '“云平台在线招聘”是一个创新的招聘平台，专注于为国际货代行业提供招聘和人才培养解决方案。云平台在线招聘还提供专业行业讲座和培训、数据分析和智能推荐等服务，提升人才素质和供应链行业的整体竞争力。通过多语言支持和跨国人才的招聘，云平台在线招聘促进了国际货代行业的人才流动和合作，推动全球货代市场的发展。云平台在线招聘致力于打造一个高效、多元、专业的人才招聘平台，为国际货代行业的企业和人才提供更好的连接和发展机会，共同推动货代行业的创新和进步',
            iconItem1: {title:'在线简历投递', text: '人才可以方便地在线投递个人简历，与供应链企业建立联系', icon: icon1},
            iconItem2: {title:'职位发布和搜索', text: '供应链企业发布招聘职位，人才通过搜索功能寻找适合的职位', icon: icon2},
            iconItem3: {title:'实时互动交流', text: '提供实时在线交流功能，人才可以与企业进行即时沟通和面试', icon: icon3},
            iconItem4: {title:'人才数据库管理', text: '提供人才数据库管理，帮助企业进行人才信息的整理和管理', icon: icon4},
            iconItem5: {title:'数据分析和报告', text: '提供招聘数据分析和报告功能，帮助评估招聘效果和改进策略', icon: icon5},
            iconItem6: {title:'薪资福利信息', text: '提供薪资和福利信息参考，帮助人才了解市场行情和企业待遇', icon: icon6},
            iconItem7: {title:'多语言支持', text: '提供多语言支持，方便国际货代企业和跨国人才的沟通和招聘', icon: icon7},
            iconItem8: {title:'人才推荐和邀约', text: '根据企业需求和人才条件，为企业推荐潜在的合适人选', icon: icon8},
            picItem1: {title:'招聘会', pic: pic1},
            picItem2: {title:'直播间互动交流', pic: pic2},
            picItem3: {title:'在线简历', pic: pic3},
            picItem4: {title:'应聘者列表', pic: pic4},
            picItem5: {title:'招聘岗位发布', pic: pic5},
            picItem6: {title:'求职意向', pic: pic6},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 3,
            currentType: 1,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 387.5;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.322;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    },
}
</script>