<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" :isLong="true" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px'}" style="height: 445px;">
            <div class="casecaseMainFunctionSmallDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/guzhangfenxi/computer.png">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLineHigh">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" :isLong="true" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem5" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem6" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" :topType="topType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/guzhangfenxi/icon1.png'
import icon2 from '../assets/case/guzhangfenxi/icon2.png'
import icon3 from '../assets/case/guzhangfenxi/icon3.png'
import icon4 from '../assets/case/guzhangfenxi/icon4.png'
import pic1 from '../assets/case/guzhangfenxi/projectPic1.png'
import pic2 from '../assets/case/guzhangfenxi/projectPic2.png'
import pic3 from '../assets/case/guzhangfenxi/projectPic3.png'
import pic4 from '../assets/case/guzhangfenxi/projectPic4.png'
import pic5 from '../assets/case/guzhangfenxi/projectPic5.png'
import pic6 from '../assets/case/guzhangfenxi/projectPic6.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseFaultAnalysis',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            caseMainFunctionSmall: '445',
            projectPic:'427',
            topTitle: '故障智能分析系统',
            pojectOverviewMsg: '“故障智能分析系统”旨在为军事领域提供一种智能化的故障分析和解决方案，通过实时监测和分析军事设备和系统的数据，系统能够快速检测、诊断和预测潜在的故障情况，为军事部门提供准确的预警信息和解决方案推荐。借助丰富的知识库和先进的算法，系统能够帮助军事部门快速解决故障，提高军事装备的可靠性和维护效率。同时，系统注重保密性和安全性，确保敏感信息的合理使用和保护。通过故障智能分析系统的运用，军事部门能够更加高效地管理和维护设备和系统，确保战备状态，提升军事行动的成功率和效果',
            iconItem1: {title:'异常检测和诊断', text: '通过实时监测和分析设备或系统的数据，系统能够检测出异常情况并进行准确的故障诊断，快速定位故障发生的位置和原因', icon: icon1, iconDivType: 'high'},
            iconItem2: {title:'故障预测和预警', text: '历史数据和先进的机器学习算法，预测潜在的故障风险，并提前发出预警信息，帮助用户采取措施，避免设备停机和损失', icon: icon2, iconDivType: 'high'},
            iconItem3: {title:'解决方案推荐', text: '根据用户的故障描述和情境，推荐最佳的解决方案，帮助用户快速解决故障，降低停机时间和维修成本', icon: icon3, iconDivType: 'high'},
            iconItem4: {title:'数据可视化', text: '提供直观的数据可视化功能，结果以图表、图形和报表等形式展示，帮助用户直观了解故障情况和趋势，进行决策和监控', icon: icon4, iconDivType: 'high'},
            picItem1: {title:'故障预估', pic: pic1},
            picItem2: {title:'设备参数', pic: pic2},
            picItem3: {title:'设备状态', pic: pic3},
            picItem4: {title:'设备效率', pic: pic4},
            picItem5: {title:'效率预警', pic: pic5},
            picItem6: {title:'故障分析', pic: pic6},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 2,
            currentType: 0,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.caseMainFunctionSmall = 278;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.caseMainFunctionSmall = document.body.clientWidth * 0.232;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    },
}
</script>

<style>
.casecaseMainFunctionSmallDiv{
    position: absolute;
    display: flex;
    justify-content: space-between;
    /* width: 62%; */
    width: 1200px;
    height: 86.5%;
    left: 50%;
    transform: translateX( -50% );
    bottom: 0;
}
.casecaseMainFunctionSmallDiv .caseMainFunctionComputer{
    width: 35%;
    height: 100%;
}
.casecaseMainFunctionSmallDiv .caseMainFunctionComputer img{
    position: absolute;
    bottom: 0;
    left: 5px;
    width: 25.5%;
    height: 65.5%;
}
.casecaseMainFunctionSmallDiv .caseMainFunctionIcon{
    display: block;
    width: 65%;
    height: 76.6%;
}
.caseMainFunctionIconLineHigh{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}
</style>