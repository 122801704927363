<template>
    <div style="width: 100%; height: 100%;">
        <div class="chooseUsTitle">
          <p class="title" style="color: #fff; position: absolute; left: 50%; top: 19%; transform: translateX(-50%);">为什么选择威迪欧</p>
          <p class="textMsg" style="color: #fff; position: absolute; left: 50%; top: 35.7%; transform: translateX(-50%);">匠心打造产品，实力打造口碑</p>
        </div>
        <div style=" width: 100%; height: 59%; position: relative; background-color: #f6f6f6;">
          <div class="chooseUsDiv">
            <div class="chooseUsInline" style="top: 0;">
              <div class="chooseUsIconDiv">
                <img src="../assets/home/chooseUsIcon1.png" alt="案例-全面且多" style="width: 22.2%; height: 20%;">
                <p class="textTitle">案例-全面且多</p>
                <p class="textMsg">打造多元化的产品类型，拥有不同领域的客户</p>
                <p class="textMsg" style="top: 76%;">案例</p>
              </div>
              <div class="chooseUsIconDiv">
                <img src="../assets/home/chooseUsIcon2.png" alt="案例-全面且多" style="width: 17.6%; height: 21%;">
                <p class="textTitle">技术-专业性强</p>
                <p class="textMsg">拥有专业团队，十几年的软件解决方案经验，</p>
                <p class="textMsg" style="top: 76%;">覆盖面广</p>
              </div>
              <div class="chooseUsIconDiv">
                <img src="../assets/home/chooseUsIcon3.png" alt="案例-全面且多" style="width: 20.4%; height: 21%;">
                <p class="textTitle">质量-品质过关</p>
                <p class="textMsg">产品符合ISO9001产品质量体系认证，产品交</p>
                <p class="textMsg" style="top: 76%;">付前进行测试验收，层层把关</p>
              </div>
            </div>
            <div class="chooseUsInline" style="bottom: 0;">
              <div class="chooseUsIconDiv">
                <img src="../assets/home/chooseUsIcon4.png" alt="案例-全面且多" style="width: 17.1%; height: 21.3%;">
                <p class="textTitle">价格-公平普惠</p>
                <p class="textMsg">透明化项目管理，按需求报价，让您省时、省</p>
                <p class="textMsg" style="top: 76%;">心、省力</p>
              </div>
              <div class="chooseUsIconDiv">
                <img src="../assets/home/chooseUsIcon5.png" alt="案例-全面且多" style="width: 17.8%; height: 21.1%;">
                <p class="textTitle">周期-时短无忧</p>
                <p class="textMsg">专人团队一对一负责，专职专项，减少对接成</p>
                <p class="textMsg" style="top: 76%;">本，工期缩短，提升交付速度</p>
              </div>
              <div class="chooseUsIconDiv">
                <img src="../assets/home/chooseUsIcon6.png" alt="案例-全面且多" style="width: 18.2%; height: 18.4%;">
                <p class="textTitle">服务-周到贴心</p>
                <p class="textMsg">热情服务，响应迅速，知客户之所急，急客户</p>
                <p class="textMsg" style="top: 76%;">之所急</p>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeChooseUs',
}
</script>

<style>
/* 为什么选择我们 */
.chooseUsTitle{
width: 100%;
height: 41%;
position: relative;
background-color: #060a23;
}
.chooseUsDiv{
width: 73.2%;
height: 129%;
position: absolute;
left: 50%; 
bottom: 0; 
transform: translateX(-50%);
}
.chooseUsInline{
position: absolute;
width: 100%;
height: 48.3%;
display: flex;
justify-content: space-between;
}
.chooseUsIconDiv{
position: relative;
height: 100%;
width: 32%;
background-color: #fff;
border-radius: 8px;
}
.chooseUsIconDiv img{
position: absolute; 
bottom: 61.8%; 
left: 50%; 
transform: translateX(-50%);
}
.chooseUsIconDiv .textTitle{
color: #333; 
text-align: center; 
position: absolute; 
top: 50%; 
left: 50%; 
transform: translateX(-50%);
}
.chooseUsIconDiv .textMsg{
color: #333; 
text-align: center; 
position: absolute; 
top: 65%; 
left: 50%;
transform: translateX(-50%);
/* width: 75%;  */
white-space: nowrap; /* 强制不换行 */
}
</style>