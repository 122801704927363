<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 产品概述 -->
        <ProjectMilitaryOverview :overViewText='overViewText' :isMilitary="true" />
        <!-- 应用领域 -->
        <div class="projectMilitaryApply">
            <p class="title">应用领域</p>
            <div class="projectMilitaryApplyBox">
                <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
            </div>
        </div>
        <!-- 产品特色 -->
        <div class="projectMilitaryFeaturesDiv">
            <p class="title">产品特色</p>
            <div class="projectMilitaryFeaturesArea">
                <ProjectMilitaryFeaturesRight :item="item1" :changeWidth="1" />
                <ProjectMilitaryFeaturesLeft :item="item2" :changeWidth="1" />
                <ProjectMilitaryFeaturesRight :item="item3" />
                <ProjectMilitaryFeaturesLeft :item="item4" />
            </div>
        </div>
        <!-- 其他案例 -->
        <Others :index="-1" :currentType="0" :arrayIndex="4" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import areaImg1 from '../assets/guidanceControl/areaImg1.webp'
import areaImg2 from '../assets/guidanceControl/areaImg2.webp'
import areaImg3 from '../assets/guidanceControl/areaImg3.webp'
import areaImg4 from '../assets/guidanceControl/areaImg4.webp'
import featureImg1 from '../assets/guidanceControl/featureImg1.png'
import featureImg2 from '../assets/guidanceControl/featureImg2.png'
import featureImg3 from '../assets/guidanceControl/featureImg3.png'
import featureImg4 from '../assets/guidanceControl/featureImg4.png'

import CaseTop from '../components/CaseTop.vue'
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
import ProjectMilitaryFeaturesRight from '../components/ProjectMilitaryFeaturesRight.vue'
import ProjectMilitaryFeaturesLeft from '../components/ProjectMilitaryFeaturesLeft.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'ProductGuidanceControlView',
    components: {
        CaseTop,
        ProjectMilitaryOverview,
        AboutOneApplicationArea,
        ProjectMilitaryFeaturesRight,
        ProjectMilitaryFeaturesLeft,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 0,
            topTitle: '军事导调控制软件',
            // 产品概述
            overViewText: '导调控制是指通过对工程、设备、系统等进行调度、管理和控制，以实现安全、高效、稳定运行的过程。它主要应用于电力、交通、通信、水利、石油等大型工程和系统的运行管理，优化资源利用、提高生产效率、保障设备安全、维护系统稳定；并且在导调控制由导调控制软件、便捷式导控终端、中心计算机与服务器等硬件组成，是部队演练信息采集与验证中的重要环节，对支撑演训全过程的导调控制，保障演训顺利进行具有重要作用',
            // 应用领域
            info: [
                { p1: '军事与国防', span: '用于训练士兵、制定战术和策略，以及评估武器系统的性能。提供逼真的虚拟环境，使人员能够进行实战模拟', isActive: false, backgroundImage: areaImg1 },
                { p1: '教育行业', span: '通过模拟各种真实场景和过程，使学生在安全的氛围中实践操作，提高自己的技能水平，帮助他们根据自己的学习进度和需求进行学习', isActive: false, backgroundImage: areaImg2 },
                { p1: '建筑和城市规划', span: '利用人工智能，机器学习。计算机图形学和软件工程等可以设计不同的建筑，配合光影效果以了解不同设计和规划决策的影响，从而打造更舒适的规划方案', isActive: false, backgroundImage: areaImg3 },
                { p1: '试验领域', span: '适用于各种研发场所，如飞行器研发，可以利用虚拟仿真进行优化设计和模拟试飞，大大的减少试飞次数和时间，降低试飞风险和成本，包括汽车研发，交通规划，工艺生产等试验领域', isActive: false, backgroundImage: areaImg4 }
            ],
            // 产品特色
            item1: {title: '计划配置', text: '配置演练地域地图\n根据参演兵力兵器和演习想定，设置编队编组方案，配对参演兵力与设备器材间的对应关系并进行兵力兵器种类、队标、弹量等初始化设置，设置作战时间，做好设备器材使用前的准备工作', img: featureImg1},
            item2: {title: '态势展示', text: '启动演习后，即开始计时，利用无线网络平台，系统数据库实时记录武器装备态势、打击、导调、裁决等各种交互信息。通过导调控制软件，能够在电子地图上实时监视红蓝双方的态势、控制远程炮火打击区域的启动与杀伤效果，设置战术队标的显示方式等', img: featureImg2},
            item3: {title: '导调评判', text: '模拟各种火力交战，对兵力兵器实施裁决，实时下达导调指令，诱导演习进程的推进\n从系统数据库中实时读取对抗双方装备和人员的最新数据信息，实时统计人员装备的射击信息、战损信息等，自动生成战损统计报告', img: featureImg3},
            item4: {title: '回放评估', text: '调用存储在数据库中的演习过程信息，回放演习过程。通过对演习数据的客观分析，生成兵力兵器作战运用建议报告，为演习后复盘检讨和有针对性的讲评提供依据和建议', img: featureImg4},
        }
    },
}
</script>