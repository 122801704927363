<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 产品概述 -->
        <ProjectMilitaryOverview :overViewText='overViewText' :isMilitary="true" />
        <!-- 应用领域 -->
        <div class="projectMilitaryApply">
            <p class="title">应用领域</p>
            <div class="projectMilitaryApplyBox">
                <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
            </div>
        </div>
        <!-- 产品特色 -->
        <div class="projectMilitaryFeaturesDiv" style="height: 1434px;">
            <p class="title">产品特色</p>
            <div class="projectMilitaryFeaturesArea" style="height: 1240px;">
                <ProjectMilitaryFeaturesRight :item="item1" />
                <ProjectMilitaryFeaturesLeft :item="item2" />
                <ProjectMilitaryFeaturesRight :item="item3" />
            </div>
        </div>
        <!-- 其他案例 -->
        <Others :index="-1" :currentType="0" :arrayIndex="2" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import areaImg1 from '../assets/equipmentStatusMonitoring/areaImg1.webp'
import areaImg2 from '../assets/equipmentStatusMonitoring/areaImg2.webp'
import areaImg3 from '../assets/equipmentStatusMonitoring/areaImg3.webp'
import areaImg4 from '../assets/equipmentStatusMonitoring/areaImg4.webp'
import featureImg1 from '../assets/equipmentStatusMonitoring/featureImg1.png'
import featureImg2 from '../assets/equipmentStatusMonitoring/featureImg2.png'
import featureImg3 from '../assets/equipmentStatusMonitoring/featureImg3.png'

import CaseTop from '../components/CaseTop.vue'
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
import ProjectMilitaryFeaturesRight from '../components/ProjectMilitaryFeaturesRight.vue'
import ProjectMilitaryFeaturesLeft from '../components/ProjectMilitaryFeaturesLeft.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'ProductEquipmentStatusMonitoringView',
    components: {
        CaseTop,
        ProjectMilitaryOverview,
        AboutOneApplicationArea,
        ProjectMilitaryFeaturesRight,
        ProjectMilitaryFeaturesLeft,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 0,
            topTitle: '设备状态监测',
            // 产品概述
            overViewText: '对运转中的设备整体或其零部件的技术状态进行检查鉴定，以判断其运转是否正常，有无异常与劣化征兆，或对异常情况进行追踪，预测其劣化趋势，确定其劣化及磨损程度等，状态检测的目的在于掌握设备发生故障之前的异常征兆与劣化信息，以便事前采取针对性措施控制和防止故障地发生，从而减少故障停机时间与停机损失，降低维修费用和提高设备有效利用率。对于在使用状态下的设备进行不停机或在线监测，能够确切掌握设备的实际特性有助于判定需要修复或更换的零部件和元器件，充分利用设备和零件的潜力，避免过剩维修，节约维修费用，减少停机损失',
            // 应用领域
            info: [
                { p1: '军事领域', span: '军用监测设备在军事领域上的应用非常广泛，例如武器、传感器、飞机船舶的运行等安装可以检测故障的设备，保障使用时的设备可以正常运转，有利于国防安全', isActive: false, backgroundImage: areaImg1 },
                { p1: '生产安全', span: '工业监测设备在生产安全方面的应用非常广泛，例如在油气管道、热电站、化工厂等危险场所安装温度、压力、震动等监测设备，能够快速预警发生的事故，保障生产安全', isActive: false, backgroundImage: areaImg2 },
                { p1: '环境保护', span: '环境监测设备在城市空气、水质、土壤等领域应用广泛，例如在城市内部和周边地区安装气体分析仪器、水质分析仪器等设备，能够实时监测大气污染和水体污染情况，有利于环境保护', isActive: false, backgroundImage: areaImg3 },
                { p1: '医疗诊断', span: '生物监测设备在医疗领域的应用越来越广泛，例如在生命科学研究、药物研发、临床诊断等方面都需要生物监测设备。例如，在疾病研究和治疗方面，生物监测设备可以监测生物体的各种参数，包括心跳、呼吸、体温、血氧、血糖等参数，有利于疾病的诊断和治疗', isActive: false, backgroundImage: areaImg4 }
            ],
            // 产品特色
            item1: {title: '设备运行情况监测', text: '这涉及设备的运行时间、负载情况、运行效率等指标，通过这些指标可以了解设备的运行状况和存在的问题', img: featureImg1},
            item2: {title: '设备安全性能监测', text: '这主要涉及设备的温度、压力、电气性能等安全指标，通过对这些指标的监测，可以及时发现设备的潜在安全隐患并进行安全性能评估', img: featureImg2},
            item3: {title: '设备能耗状况监测', text: '这主要涉及设备的能源消耗和能耗效率等指标，通过监测和分析这些指标，可以减少能源浪费，提高能源利用效率', img: featureImg3},
        }
    },
}
</script>