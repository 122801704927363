<template>
  <div class="text"  :style="colorDiv()"  @mousemove="mouseOver" @mouseleave="mouseLeave">
    <div class="overlay"></div>
    <div class="p1">
      <p>{{ item.p1 }}</p>
    </div>
    <div class="span">
      <span>{{ item.span }}</span>
    </div>
  </div>
</template>

<script>
export default {
    name: 'AboutOneApplicationArea',
    props:{
      item: Object,
      required: true
    },
    data(){
      return{
        isActive: false
      }
    },
    methods: {
    mouseOver() {
      this.isActive = true;
    },
    mouseLeave() {
      this.isActive = false;
    },
    /*给选定的盒子修改样式 */
    colorDiv() {
      if (this.isActive) {
        return {
          backgroundImage: `url(${this.item.backgroundImage})`,
          color: 'white'
        };
      } else {
        return {
          backgroundImage: 'none',
          '.p1 p': {
            color: ''
          },
          '.span span': {
            color: ''
          }
        }
      }
    },
  },
}
</script>

<style>
.areaBox{
    width: 100%; 
    height: 100%; 
}
.text {
  width: 22%;
  height: 94.6%;
  /* box-shadow: 0px 0px 10px rgba(215, 215, 215, 0.5); */
  border-radius: 8px;
  /*添加阴影效果 */
  /* cursor: pointer; */
  z-index: 1;
  /*使文字位于图片下方 */
  margin-right: 30px;
  background-size: contain;
  /*想让背景图片完整显示在盒子内用这个 cover是要裁剪一部分 */
  position: relative;
  /*相对定位 为叠加层提供参照物 */
  background-color: white;
}

.overlay {
  position: absolute;
  /*绝对定位，叠加在文本上方 */
  z-index: 1;
  /*确保在文本之上 */

}

.p1 {
  width: 100%;
  height: 20%;
  margin-left: 9%;
  /* margin-top: 8%; */
  /* margin-bottom: 9%; */
  display: flex;
  /* justify-content: center; */
  align-items: center;

  position: relative;
  /*为了让文本能够再叠加层之上 */
  z-index: 2;
}

#app{
  color:#333333; ;
}

.p1 p {
  font-family: MySourceHanSansSC-Heavy;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 2px;
  /* color: #333333; */
  white-space: nowrap;
  text-align: left;
  margin: 0;
}

.span {
  width: 80%;
  height: 23%;
  margin: 0 auto;
  display: flex;
  text-align: justify;
}

.span span {
  font-family: MySourceHanSansSC-Regular;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
  /* color: #333333; */
}

</style>