<template>
    <div style="width: 100%; height: 100%;">
        <div class="aboutUsHonorDiv">
            <div class="aboutUsHonorTextDiv">
                <p class="title">荣誉资质/知识产权</p>
                <div class="aboutUsHonorText">
                    <p class="textMsg">公司于2017年获得“国家高新技术企业”认证，拥有完善的知识产权管理体系，并通过了知识产权管理体系认证、
                        ISO9001管理体系认证、武器装备科研生产单位三级保密资格认定，公司累计登记软件著作权50余项</p>
                </div>
            </div>
            <!-- <div class="aboutUsHonorLineDiv">
                <div class="aboutUsHonorPoint" style="background-color: #ffa800;" />
                <div class="aboutUsHonorLine" style="background-color: #ffa800;" />
                <div class="aboutUsHonorPoint" style="top: 33.3%; background-color: #ffa800;" />
                <div class="aboutUsHonorLine" style="top: 33.3%;" />
                <div class="aboutUsHonorPoint" style="top: 66.6%;" />
                <div class="aboutUsHonorLine" style="top: 66.6%;" />
                <div class="aboutUsHonorPoint" style="top: 100%;" />
            </div> -->
            <div class="aboutUsQualification">
                <div class="aboutUsQualificationTopImg" style="width: 69.6%; height: 27.8%;" @click="changeLeft(2)">
                    <img src="../assets/aboutUs/qualificationImg2.jpg" style="width: 100%; height: 100%;">
                </div>
                <!-- <div :class="aboutUsQualificationImg1" style="width: 100%; height: 27.8%;" @click="changeLeft(0)">
                   <img src="../assets/aboutUs/qualificationImg1.jpg" style="width: 69.6%; height: 100%;"> 
                </div> -->
                <div class="aboutUsQualificationBottomImg" style="width: 69.6%; height: 55.7%;" @click="changeLeft(1)">
                    <img src="../assets/aboutUs/qualificationImg3.jpg" style="width: 100%; height: 100%;">
                </div>
            </div>
            <div class="aboutUsIP">
                <div class="aboutUsIPImgDiv" @mouseover="aboutUsIPhover(0)" @mouseleave="aboutUsIPDishover(0)">
                    <img src="../assets/aboutUs/IPImg1.jpg" style="width: 100%; height: 100%;">
                </div>
                <div class="aboutUsIPImgDiv" @mouseover="aboutUsIPhover(1)" @mouseleave="aboutUsIPDishover(1)">
                   <img src="../assets/aboutUs/IPImg2.jpg" style="width: 100%; height: 100%;"> 
                </div>
                <div class="aboutUsIPImgDiv" @mouseover="aboutUsIPhover(2)" @mouseleave="aboutUsIPDishover(2)">
                    <img src="../assets/aboutUs/IPImg3.jpg" style="width: 100%; height: 100%;">
                </div>
                <div class="aboutUsIPImgDiv" @mouseover="aboutUsIPhover(3)" @mouseleave="aboutUsIPDishover(3)">
                    <img src="../assets/aboutUs/IPImg4.jpg" style="width: 100%; height: 100%;">
                </div>
                <div class="aboutUsIPImgDiv" @mouseover="aboutUsIPhover(4)" @mouseleave="aboutUsIPDishover(4)">
                    <img src="../assets/aboutUs/IPImg5.jpg" style="width: 100%; height: 100%;">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUsHonor',
    data() {
        return {
            indexQualificationImg: 0,
            indexIPimg: 0,
            aboutUsIPid: 0,
            aboutUsid: 0,
            aboutUsQualificationImg1: 'aboutUsQualificationMiddleImg',
            aboutUsQualificationImg2: 'aboutUsQualificationTopImg',
            aboutUsQualificationImg3: 'aboutUsQualificationBottomImg',
            aboutUsQualificationClass: ['aboutUsQualificationMiddleImg', 'aboutUsQualificationTopImg', 'aboutUsQualificationBottomImg'],
        }
    },
    mounted() {
        // this.autoplay();
        this.autoplayIP();
    },
    beforeDestroy(){ //切换页面时销毁定时器，重置相关参数，防止报错
        if(this.aboutUsIPid){
            clearInterval(this.aboutUsIPid);
            clearInterval(this.aboutUsid);
            this.indexQualificationImg = 0;
            this.indexIPimg = 0;
        }
    },
    methods: {
        autoQualificationImgCarousel(){
            this.indexQualificationImg = ++this.indexQualificationImg % 3;
            this.QualificationImgCarousel();
        },
        changeLeft(currentIndex){
            if(currentIndex == this.indexQualificationImg){
                return;
            }
            clearInterval(this.aboutUsid);
            this.indexQualificationImg = currentIndex;
            this.QualificationImgCarousel();
            this.autoplay();
        },
        QualificationImgCarousel(){
            /* 荣誉资质轮播部分 */
            this.aboutUsQualificationImg1 = this.aboutUsQualificationClass[this.indexQualificationImg % 3];
            this.aboutUsQualificationImg2 = this.aboutUsQualificationClass[(this.indexQualificationImg + 1) % 3];
            this.aboutUsQualificationImg3 = this.aboutUsQualificationClass[(this.indexQualificationImg + 2) % 3];
            /* 进度条样式 */
            let lines = document.getElementsByClassName('aboutUsHonorLine');
            let points = document.getElementsByClassName('aboutUsHonorPoint');
            for( let i = 0 ; i < lines.length ; ++i){
                if(i == this.indexQualificationImg){
                    lines[i].style.backgroundColor = '#ffa800';
                    points[i].style.backgroundColor = '#ffa800';
                }else{
                    lines[i].style.backgroundColor = 'rgba(205, 205, 205, 0.5)';
                    points[i].style.backgroundColor = '#a6a6a6';
                }
            }
            points[this.indexQualificationImg + 1].style.backgroundColor = '#ffa800';
            if(this.indexQualificationImg != 2){
                points[3].style.backgroundColor = '#a6a6a6';
            }
        },
        aboutUsIPhover(hoverIndex){
            let imgs = document.getElementsByClassName('aboutUsIPImgDiv');
            let IPimslength = imgs.length;
            let index = (this.indexIPimg + 2) % IPimslength;
            /* 控制滑动向下的图片在底部，防止遮挡其他图片 */
            for( let i = 0 ; i < IPimslength ; ++i){
                if(i == hoverIndex){
                    imgs[i].style.zIndex = '3';
                }else{
                    imgs[i].style.zIndex = '-3';
                }
            }
            if( index == hoverIndex){
                clearInterval(this.aboutUsIPid);
                return;
            }
            let ydown = 0;
            let yup = 0;
            /* 控制每张图片移动 */
            if( hoverIndex == (index + 1) % IPimslength || hoverIndex == (index + 2) % IPimslength){
                if((hoverIndex - 2 + IPimslength) % IPimslength != 0){
                    ydown = (IPimslength - (hoverIndex - 2 + IPimslength) % IPimslength) * 106.5;
                    yup = (hoverIndex - 2 + IPimslength) % IPimslength * (-106.5);
                }else{
                    ydown = 0;
                    yup = 0;
                }
                for( let i = 0 ; i < IPimslength ; ++i){
                    if(i < (hoverIndex - 2 + IPimslength) % IPimslength){
                        imgs[i].style.transform = 'translateY( ' + ydown + '% )';
                    }else{
                        imgs[i].style.transform = 'translateY( ' + yup + '% )';
                    }
                }
            }else{
                ydown = (IPimslength + 2 -hoverIndex) % IPimslength * 106.5;
                yup = (hoverIndex + 3) % IPimslength * -106.5;
                for( let i = 0 ; i < IPimslength ; ++i){
                    if(i >= (hoverIndex + 3) % IPimslength){
                        imgs[i].style.transform = 'translateY( ' + yup + '% )';
                    }else{
                        imgs[i].style.transform = 'translateY( ' + ydown + '% )';
                    }
                }
            }
            
            this.indexIPimg = (hoverIndex - 2 + IPimslength) % IPimslength;
            clearInterval(this.aboutUsIPid);
        },
        aboutUsIPDishover(hoverIndex){
            this.autoplayIP();
        },
        IPimgCarousel(){
            /* 知识产权轮播部分 */
            let imgs = document.getElementsByClassName('aboutUsIPImgDiv');
            let IPimslength = imgs.length;
            let ydown = 0;
            let yup = 0;
            /* 控制滑动向下的图片在底部，防止遮挡其他图片 */
            for( let i = 0 ; i < IPimslength ; ++i){
                if(i == this.indexIPimg){
                    imgs[i].style.zIndex = '-3';
                }else{
                    imgs[i].style.zIndex = '3';
                }
            }
            this.indexIPimg = (this.indexIPimg + 1) % IPimslength;
            if(this.indexIPimg != 0){
                ydown = (IPimslength-this.indexIPimg) * 106.5;
                yup = this.indexIPimg * (-106.5);
            }
            /* 控制每张图片移动 */
            for( let i = 0 ; i < IPimslength ; ++i){
                if(i < this.indexIPimg){
                    imgs[i].style.transform = 'translateY( ' + ydown + '% )';
                }else{
                    imgs[i].style.transform = 'translateY( ' + yup + '% )';
                }
            }
        },
        autoplayIP(){
            let _this = this;
            this.aboutUsIPid=setInterval(function(){
                _this.IPimgCarousel();
            },3000)
        },
        autoplay(){
            let _this = this;
            this.aboutUsid=setInterval(function(){
                _this.autoQualificationImgCarousel();
            },5000)
        },
    }
}
</script>

<style>
.aboutUsHonorDiv{
    width: 68.9%; 
    height: 100%; 
    position: absolute; 
    left: 50%; 
    transform: translateX( -50% );
    display: flex;
    overflow: hidden;
}
.aboutUsHonorTextDiv{
    position: absolute; 
    width: 32%;
    height: 55%;
    top: 31.4%;
    left: 4.5%;
}
.aboutUsHonorText{
    position: absolute; 
    top: 26%;
    width: 87.2%;
}
.aboutUsHonorLineDiv{
    position: absolute; 
    top: 34.3%;
    left: 40%;
    width: 6px;
    height: 28.6%;
}
.aboutUsHonorPoint{
    position: absolute; 
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: #a6a6a6;
    z-index: 3;
}
.aboutUsHonorLine{
    position: absolute; 
    left: 50%; 
    transform: translateX( -50% );
    height: 33.3%;
    width: 1px;
    /* cursor:pointer;  */
    background-color: rgba(205, 205, 205, 0.5);
}

/* 荣誉资质 */
.aboutUsQualification{
    position: absolute; 
    left: 44.7%;
    width: 29.4%;
    height: 100%;
    top: 50%; 
    transform: translateY( -50% ); 
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
/* .aboutUsQualificationMiddleImg{
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate( -50%, -50% ); 
    z-index: 5;
}
.aboutUsQualificationMiddleImg img{
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: scale(1.44) translate( -50%, -50% );
    transform-origin: top left;
    object-fit: cover;
} */
.aboutUsQualificationTopImg{
    position: absolute; 
    top: 5%; 
    z-index: 3;
    transition: all 0.8s;
}
.aboutUsQualificationBottomImg{
    position: absolute; 
    bottom: 5%; 
    z-index: 3;
    transition: all 0.8s;
}
/* .aboutUsQualificationTopImg img,.aboutUsQualificationBottomImg img{
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate( -50%, -50% ); 
} */
.aboutUsQualificationTopImg:hover{
    /* position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate( -50%, -50% ); */
    transform: scale(1.44);
    transform-origin: top;
    z-index: 5;
}
.aboutUsQualificationBottomImg:hover{
    transform: scale(1.44);
    transform-origin: bottom;
    z-index: 5;
}
/* .aboutUsQualificationTopImg:hover img{
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: scale(1.44) translate( -50%, -50% );
    transform-origin: bottom left;
    object-fit: cover;
}
.aboutUsQualificationBottomImg:hover img{
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: scale(1.44) translate( -50%, -50% );
    transform-origin: top left;
    object-fit: cover;
} */

/* 知识产权 */
.aboutUsIP{
    position: absolute; 
    top: 50%; 
    transform: translateY( -50% );
    right: 0;
    width: 29.2%;
    height: 155%;
    overflow-y: hidden;
    overflow-x: visible;
    display: flex;
    flex-direction: column;  /* 设置主轴方向为垂直 */
    align-items: center;  /* 垂直居中 */
    justify-content: space-between;
}
.aboutUsIPImgDiv img{
    transition: transform 0.3s;
}
.aboutUsIPImgDiv img:hover{
    max-width: none;
    transform: scale(2.5);
    transform-origin: center;
    z-index: 5;
}
.aboutUsIPImgDiv{
    position: relative;
    width: 40%;
    height: 19%;
    transition: transform 1s;
    overflow: visible;
}
</style>