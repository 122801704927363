<template>
    <div :style="{width:this.width+'px', height:this.contactUs+'px'}" style="background-color: #121621; position: relative;">
        <div class="contactUs">
          <!-- <div class="contactDiv"> -->
            <div style="width: 153px; height: 100%;">
              <img src="../assets/home/logo.png" alt="video_logo" style="width: 100%; height: 100%;">
            </div>
            <div class="dividerLineDiv">
              <div class="dividerLine" />
            </div>
            <div class="contact">
              <div class="contactMsg">
                <div class="contactIconDiv">
                  <img class="contactIcon" src="../assets/home/phoneLogo.png" alt="电话logo">
                </div>
                <div class="contactText">
                  <p class="textTitle" style="color: #ff9000; font-family: MySourceHanSansSC-Regular;">0411-88649925</p>
                </div>
              </div>
              <div class="contactMsg">
                <div class="contactIconDiv">
                  <img class="contactIcon" src="../assets/home/atLogo.png" alt="atLogo">
                </div>
                <div class="contactText">
                  <p class="textMsg" style="color: #b9b9b9;">business@dalianvideo.com</p>
                </div>
              </div>
              <div class="contactMsg">
                <div class="contactIconDiv">
                  <img class="contactIcon" src="../assets/home/placeLogo.png" alt="地址logo">
                </div>
                <div class="contactText">
                  <p class="textMsg" style="color: #b9b9b9;">大连市高新区希贤街29号B座八层801室</p>
                </div>
              </div>
            </div>
            <div class="dividerLineDiv">
                <div class="dividerLine" />
            </div>
            <div class="QRcodeDiv">
              <div style="writing-mode: vertical-lr; width: 8.6%; height: 100%;">
                <p class="textMsg" style="text-align: center; color: #b9b9b9;">扫描二维码</p>
              </div>
              <div style="width: 75%; height: 100%;">
                <img src="../assets/QRcode.png" alt="二维码" style="width: 100%; height: 100%;">
              </div>
            <!-- </div> -->
          </div>
        </div>
        <div class="copyRight">
          <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);">
            <p class="textMsg" style="color: #b9b9b9; white-space: pre;">版权所有©2024 &nbsp;&nbsp;&nbsp;&nbsp;大连威迪欧信息技术有限公司 &nbsp;&nbsp;&nbsp;辽ICP备2023007020号</p>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactUs',
    data() {
        return {
            width: '1920',
            contactUs: '535',
        }
    },
  mounted () {
    this.calcRate()
    window.addEventListener('resize', this.calcRate)
    this.init()
  },
  props:{
      pageType:{
          type: Number,
          default: 1,
      },
  },
  methods: {
    init(){
      if(this.pageType == 1){
        this.contactUs = 535;
      }else{
        this.contactUs = 375
      }
    },
    calcRate () {
      if(document.body.clientWidth <= 1200){
        this.width = 1200;
        // this.contactUs = 335;
      }else{
        this.width = document.body.clientWidth;
        // this.contactUs = document.body.clientWidth * 0.28;
      }
    },
  }
}
</script>

<style>
/* .contactUs{
  width: 100%; 
  height: 87%; 
  background-color: #121621;
  position: relative;
} */
.contactUs{
  position: absolute; 
  bottom: 130px;
  left:50%; 
  transform: translateX( -50% ); 
  width: 1195px; 
  height: 185px; 
  display: flex; 
  align-items:center;
  justify-content: space-between; 
}
.dividerLineDiv{
  width: 250px;
  height: 97.8%;
  position: relative;
}
.dividerLine{
  width: 1px; 
  height: 100%; 
  position: absolute; 
  left:50%; 
  transform: translateX( -50% ); 
  background-color: #3e3e3e;
}
.contact{
  width: 380px; 
  height: 72.5%; 
  display: flex; 
  flex-wrap: wrap; 
  align-content: space-between;
  border: none;
}
.contactMsg{
  width: 100%; 
  height: 22%; 
  display: flex;
}
.contactIconDiv{
  width: 13.2%; 
  height: 100%;
}
.contactIcon{
  width: 60%; 
  height: 100%; 
  display: inline; 
  float: left;
}
.contactText{
  width: 87%; 
  height: 100%;
}
.contactText .textTitle{
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 2px;
}
.QRcodeDiv{
  width: 15%; 
  height: 74%;
  display: flex; 
  align-items:center;
  justify-content: space-between; 
}
.QRcodeText{
  font-family: MySourceHanSansSC-Regular;
  color: #b9b9b9;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 2px;
}
.QRcodeDiv .textMsg,.contactText .textMsg,.copyRight .textMsg{
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 2px;
}
.copyRight{
  width: 100%; 
  height: 70px; 
  bottom: 0;
  background-color: #000;
  position: absolute;
}
</style>