import { render, staticRenderFns } from "./CaseInternationalFreightForwarderView.vue?vue&type=template&id=6ee4c0ac"
import script from "./CaseInternationalFreightForwarderView.vue?vue&type=script&lang=js"
export * from "./CaseInternationalFreightForwarderView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports