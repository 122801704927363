<template>
    <div class="hardWare">

        <div class="tool-title">
        <h3>{{hardItem.text}}</h3>
      </div>
      <div class="tool-text">
        <p>
         {{ hardItem.content }}
        </p>
      </div>
      <div class="tool-img">
        <img  :src="hardItem.hard" :style="{width:hardItem.hardWidth+'%', height:hardItem.hardHeight+'%',marginRight:hardItem.iconRight+'%',marginBottom:hardItem.iconBottom+'%'}">
      </div>
    </div>
</template>

<script>
export default {
    name: 'AboutOneSupportOnlyDiv',
    props:{
        hardItem:{
            type: Object,
            required: true,
        }
    }
}
</script>

<style>
.hardWare{
    width: 345px; 
    height: 382px; 
    background-color: #fff;
  border-radius: 5px;
  border: solid 2px #d3d3d3;
}
.hardWare:hover {
  box-shadow: 0px 5px 9px 1px rgba(2, 4, 11, 0.5);
  border-radius: 8px;
}
.tool-img{
    position: relative; 
    height: 47%;
    /* left: 11.2%; 
    top: 9.3%; */
}
.tool-img img{
    bottom: 0;
    right: 0;
    position: absolute;
}
.tool-title {
  width: 100%;
  height: 18%;
  display: flex;
  position: relative;
  margin: 0;
}

.tool-title h3 {
  /* margin: 0; */
  font-family: MySourceHanSansSC-Heavy;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 2px;
  color: #333333;
  margin-top: 5%;
  margin-left: 6%;
  text-align: left;
  margin-bottom: 0%;
  z-index: 1
}

.tool-text {
  width: 100%;
  height: 35%
}

.tool-text p {
  margin-left: 6%;
  margin-top: -4%;
  margin-bottom: 0;
  text-align: justify;
  /*文字两端对齐 */
  margin-right: 7%;
  font-family: MySourceHanSansSC-Regular;
	font-size: 16px;
	line-height: 30px;
	letter-spacing: 2px;
	color: #333333;
}
</style>