<template>
    <div style="width: 80%; height:113%; position: absolute; left:50%; transform: translateX( -50% ); top: -30%;">
        <div style="width: 100%; height: 87.5%; display: flex; justify-content: space-between;">
            <div @mouseover="productImgHover(1)" @mouseleave="productImgDishover(1)" class="imgDark" style="width: 23.6%; height: 100%; position: relative;">
                <div style="width: 100%; height: 100%; display: flex; position: absolute;">
                    <div style="width: 20%; height: 100%; writing-mode: vertical-lr; position: relative;">
                        <p class="productText">{{ projectText1 }}</p>
                    </div>
                    <div v-if="projectRouterShow1" style="width: 80%; height: 100%;">
                        <router-link class="productRouter" :to="projectRouter1">探索  →</router-link>
                    </div>
                </div>
                <img class="projectImg" :src="projectImg1" alt="产品中心轮播图1">
            </div>
            <div @mouseover="productImgHover(2)" @mouseleave="productImgDishover(2)" class="imgDark" style="width: 23.6%; height: 100%; position: relative;">
                <div style="width: 100%; height: 100%; display: flex; position: absolute;">
                    <div style="width: 20%; height: 100%; writing-mode: vertical-lr; position: relative;">
                        <p class="productText">{{ projectText2 }}</p>
                    </div>
                    <div v-if="projectRouterShow2" style="width: 80%; height: 100%;">
                        <router-link class="productRouter" :to="projectRouter2">探索  →</router-link>
                    </div>
                </div>
                <img class="projectImg" :src="projectImg2" alt="产品中心轮播图2">
            </div>
            <div @mouseover="productImgHover(3)" @mouseleave="productImgDishover(3)" class="imgDark" style="width: 23.6%; height: 100%; position: relative;">
                <div style="width: 100%; height: 100%; display: flex; position: absolute;">
                    <div style="width: 20%; height: 100%; writing-mode: vertical-lr; position: relative;">
                        <p class="productText">{{projectText3}}</p>
                    </div>
                    <div v-if="projectRouterShow3" style="width: 80%; height: 100%;">
                        <router-link class="productRouter" :to="projectRouter3">探索  →</router-link>
                    </div>
                </div>
                <img class="projectImg" :src="projectImg3" alt="产品中心轮播图3">
            </div>
            <div @mouseover="productImgHover(4)" @mouseleave="productImgDishover(4)" class="imgDark" style=" width: 23.6%; height: 100%; position: relative;">
                <div style="width: 100%; height: 100%; display: flex; position: absolute;">
                    <div style="width: 20%; height: 100%; writing-mode: vertical-lr; position: relative;">
                        <p class="productText">{{projectText4}}</p>
                    </div>
                    <div v-if="projectRouterShow4" style="width: 80%; height: 100%;">
                        <router-link class="productRouter" :to="projectRouter4">探索  →</router-link>
                    </div>
                </div>
                <img class="projectImg" :src="projectImg4" alt="产品中心轮播图4">
            </div>
        </div>
        <div style="width: 81.5%; height: 7%; bottom: 0; position: absolute; 
        display: flex; justify-content: space-between; left:50%; transform: translateX( -50% );">
          <div style="width: 10%; height: 100%; display: flex; justify-content: space-between; position: relative;">
            <button id="productLeft" style="height: 100%; width: 38%; border: none; padding: 0; background-color: rgba(0, 0, 0, 0); " @click="productChangeImg(-1)">
                <img id="leftImg" src="../assets/home/leftButton.png"/>
                <img id="leftImgHover" src="../assets/home/leftButtonHover.png"/>
            </button>
            <button id="productRight" style="height: 100%; width: 38%; border: none; padding: 0; background-color: rgba(0, 0, 0, 0); " @click="productChangeImg(1)">
                <img id="rightImg" src="../assets/home/rightButton.png"/>
                <img id="rightImgHover" src="../assets/home/rightButtonHover.png"/>
            </button>
          </div>
          <div style="left: 13%; width: 87%; height: 2px; background-color: #96aabe; position: absolute; top:50%; transform: translateY( -50% );">
              <!-- 进度条 -->
              <transition name="aboutUsServiceProgress">
                <div id="homeProducProgressCurrent" style="background-color: #1b297b; height: 2px; width: 14.3%; transition: transform 1s ease;" />
              </transition>
          </div>
        </div>
    </div>
</template>

<script>
import img1 from '../assets/home/homeProjectImg1.webp'
import img2 from '../assets/home/homeProjectImg2.webp'
import img3 from '../assets/home/homeProjectImg3.webp'
import img4 from '../assets/home/homeProjectImg4.webp'
import img5 from '../assets/home/homeProjectImg5.webp'
import img6 from '../assets/home/homeProjectImg6.webp'
import img7 from '../assets/home/homeProjectImg7.webp'

export default {
    name: 'HomeProductCarousel',
    data() {
      return {
        currentIndex: 0,
        projectImg1: img1,
        projectImg2: img2,
        projectImg3: img3,
        projectImg4: img4,
        projectText1: '导调控制',
        projectText2: '仿真软件',
        projectText3: '企业信息管理系统',
        projectText4: '制造管理系统',
        projectRouter1: '/ProductGuidanceControlView',
        projectRouter2: '/ProductSportsSimulationView',
        projectRouter3: '/aboutErp',
        projectRouter4: '/aboutOne',
        projectImgs: [img1, img2, img3, img4, img5, img6, img7],
        projectTexts: ['导调控制', '仿真软件', '企业信息管理系统', '制造管理系统', '在线交易会', '海浪预报软件', '虚拟仿真'],
        projectRouters: ['/ProductGuidanceControlView', '/ProductSportsSimulationView', '/aboutErp', '/aboutOne', '/aboutTransactions', 
                          '/ProductWavePredictionView', '/ProductVirtualSimulationView'],
        projectRouterShow1: false,
        projectRouterShow2: false,
        projectRouterShow3: false,
        projectRouterShow4: false,
      }
    },
    methods: {
      productChangeImg(changeIndex){//进度条滚动及切换图片文本方法
        let length = this.projectImgs.length;
        //计算新的当前index
        this.currentIndex = (this.currentIndex + changeIndex + length) % length;
        //切换文本和图片
        this.projectImg1 = this.projectImgs[this.currentIndex];
        this.projectText1 = this.projectTexts[this.currentIndex];
        this.projectRouter1 = this.projectRouters[this.currentIndex];
        this.projectImg2 = this.projectImgs[(this.currentIndex + 1) % length];
        this.projectText2 = this.projectTexts[(this.currentIndex + 1) % length];
        this.projectRouter2 = this.projectRouters[(this.currentIndex + 1) % length];
        this.projectImg3 = this.projectImgs[(this.currentIndex + 2) % length];
        this.projectText3 = this.projectTexts[(this.currentIndex + 2) % length];
        this.projectRouter3 = this.projectRouters[(this.currentIndex + 2) % length];
        this.projectImg4 = this.projectImgs[(this.currentIndex + 3) % length];
        this.projectText4 = this.projectTexts[(this.currentIndex + 3) % length];
        this.projectRouter4 = this.projectRouters[(this.currentIndex + 3) % length];
        //移动进度条
        let bar = document.getElementById("homeProducProgressCurrent");
        let x = this.currentIndex * 100;
        bar.style.transform = 'translateX(' + x + '%)';
      },
      productImgHover(currentShow){
        switch (currentShow){
          case 1:
          this.projectRouterShow1 = true;
          break;
          case 2:
          this.projectRouterShow2 = true;
          break;
          case 3:
          this.projectRouterShow3 = true;
          break;
          case 4:
          this.projectRouterShow4 = true;
          break;
        }
      },
      productImgDishover(currentShow){
        switch (currentShow){
          case 1:
          this.projectRouterShow1 = false;
          break;
          case 2:
          this.projectRouterShow2 = false;
          break;
          case 3:
          this.projectRouterShow3 = false;
          break;
          case 4:
          this.projectRouterShow4 = false;
          break;
        }
      },
    },
}
</script>

<style>
  /* 轮播进度条按钮 */
  #productLeft img {
    position: absolute;
    top: 0;
    left: 0;
    width: 38%;
    height: 100%;
    transition: opacity 0.3s ease; /* 添加过渡效果，使图片切换更平滑 */
  }
  #productRight img {
    position: absolute;
    top: 0;
    left: 62%;
    width: 38%;
    height: 100%;
    transition: opacity 0.3s ease; /* 添加过渡效果，使图片切换更平滑 */
  }
  #productLeft #leftImgHover{
    opacity: 0;
  }
  #productLeft:hover #leftImgHover{
    opacity: 1;
  }
  #productLeft:hover #leftImg{
    opacity: 0;
  }
  #productRight #rightImgHover{
    opacity: 0;
  }
  #productRight:hover #rightImgHover{
    opacity: 1;
  }
  #productRight:hover #rightImg{
    opacity: 0;
  }
  /* 公司产品轮播图悬浮变暗 */
  .imgDark .projectImg{
    width: 100%;
    height: 100%;
    transition: filter 0.3s ease;
  }
  .imgDark:hover .projectImg{
    width: 100%;
    height: 100%;
    filter: brightness(50%);
  }
  /* 公司产品轮播图上方文字 */
  .productText{
    font-family: MySourceHanSansSC-Regular;
    color: #ffffff;
    margin: 0;
    right: 0;
    bottom: 5%;
    position:absolute; 
    z-index: 3;
  }
  .productRouter{
    text-decoration: none;
    font-family: MySourceHanSansSC-Regular;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 2px;
    color: #ffffff;
    position:absolute; 
    right: 12%;
    bottom: 4%;
    z-index: 3;
  }

/*分辨率小于1200*/
@media screen and (max-width: 1200px) {
  .productText{
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
/*分辨率在1200-1360之间*/
@media screen and (min-width: 1200px) {
  .productText{
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
  }
}
/*分辨率在1360-1600之间*/
@media screen and (min-width: 1360px) {
  .productText{
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1px;
  }
}
@media screen and (min-width: 1500px) {
  .productText{
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 2px;
  }
}
/*分辨率大于1600*/
@media screen and (min-width: 1600px) {
  .productText{
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 2px;
  }
}
@media screen and (min-width: 1800px) {
  .productText{
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 3px;
  }
}
</style>