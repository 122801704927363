<template>
    <div>
        <!-- 导航栏 -->
        <!-- <div class="aboutUsFirst" /> -->
        <!-- 顶部标题 -->
        <div class="aboutUsTop" :style="{width:this.screenWidth+'px', height:this.top+'px'}">
            <div class="aboutUsTopText">
                <p class="topTitle" style="width: 100%; height: 100%; color: #fff;">关于威迪欧</p>
            </div>
        </div>
        <!-- 公司简介 -->
        <div class="aboutUsIntroduction" :style="{width:this.screenWidth+'px', height:this.introduction+'px'}">
            <AboutUsIntroduction />
        </div>
        <!-- 服务领域 -->
        <div class="aboutUsServiceField" :style="{width:this.screenWidth+'px'}">
            <AboutUsService />
        </div>
        <!-- 企业理念 -->
        <div class="aboutUsCorporateCulture" :style="{width:this.screenWidth+'px', height:this.corporateCulture+'px'}">
            <AboutUsCorporateCulture />
        </div>
        <!-- 荣誉 -->
        <div class="aboutUsHonor" :style="{width:this.screenWidth+'px', height:this.honor+'px'}">
            <AboutUsHonor />
        </div>
        <!-- 合作伙伴 -->
        <div class="aboutUsPartners" :style="{width:this.screenWidth+'px'}">
            <AboutUsPartners />
        </div>
        <!-- 联系我们 -->
        <ContactUs  :pageType="0" />
    </div>
</template>

<script>
import AboutUsIntroduction from '../components/AboutUsIntroduction.vue'
import AboutUsService from '../components/AboutUsServiceField.vue'
import AboutUsCorporateCulture from '../components/AboutUsCorporateCulture.vue'
import AboutUsPartners from '../components/AboutUsPartners.vue'
import AboutUsHonor from '../components/AboutUsHonor.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'AboutUsView',
    components:{
        AboutUsIntroduction,
        AboutUsService,
        AboutUsCorporateCulture,
        AboutUsPartners,
        AboutUsHonor,
        ContactUs
    },
    data() {
        return {
            screenWidth: '1920',
            top:'400',
            introduction: '920',
            serviceField: '835',
            corporateCulture: '670',
            partners: '643',
            honor: '700',
            contactUs: '535',
        }
    },
    mounted() {
        window.addEventListener('resize', this.calcRate);
        this.calcRate();
    },    
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.top = 250;
                this.introduction = 575;
                this.serviceField = 521;
                this.corporateCulture = 418;
                this.partners = 402;
                this.honor = 437;
                this.contactUs = 335;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.top = document.body.clientWidth * 0.208;
                this.introduction = document.body.clientWidth * 0.48;
                this.serviceField = document.body.clientWidth * 0.435;
                this.corporateCulture = document.body.clientWidth * 0.349;
                this.partners = document.body.clientWidth * 0.335;
                this.honor = document.body.clientWidth * 0.365;
                this.contactUs = document.body.clientWidth * 0.28;
            }
        },
    }
}
</script>

<style>
/* 导航栏背景的黑色区域 */
.aboutUsFirst{
    height: 90px;
    background-color: black;
}
/* 顶部标题区域 */
.aboutUsTop{
    position: relative;
    background-image: url('../assets/aboutUs/aboutUsBanner.webp');
    /* 背景图像覆盖整个容器 */
    background-size: cover;
    /* 背景图像居中显示 */
    background-position: center;
}
.aboutUsTopText{
    width: auto; 
    height: auto; 
    position: absolute; 
    left: 50%; 
    top: 50%;
    transform: translate( -50%,-50% ); 
    /* top: 47.5%; 
    transform: translateX( -50% ); */
}
/* 公司简介区域 */
.aboutUsIntroduction{
    position: relative;
    background-color: #fff;
}
/* 服务领域 */
.aboutUsServiceField{
    position: relative;
    display: flex;
    height: 835px;
    background-color: #fff;
    background-image: url('../assets/aboutUs/AboutUsServiceBackgroundImg.png');
    background-size: cover;
    background-position: center;
}
/* 企业理念 */
.aboutUsCorporateCulture{
    position: relative;
    background-image: url('../assets/aboutUs/corporateCultureBackground.jpg');
    background-size: cover;
    background-position: center;
}
/* 合作伙伴 */
.aboutUsPartners{
    position: relative;
    background-color: #fff;
    height: 668px;
}
/* 荣誉 */
.aboutUsHonor{
    position: relative;
    background-image: url('../assets/aboutUs/AboutUsHonorBackgroundImg.png'); 
    background-size: cover; 
    background-position: center;
    overflow: hidden;
}
</style>