<template>
  <div class="about">
    <!-- <div class="aboutNav" /> -->
    <!-- -----------------------智慧零售系统 -->
    <CaseTop :topTitle="topTitle" :topType="topType" />

    <!-- ----------------------------产品概述 -->
    <ProjectMilitaryOverview :overViewText='overViewText' :isShort="true" />


    <!-- ---------------------------应用领域 -->
    <div class="three" :style="{ width: this.threeWidth.width + 'px' }" style="height: 673px;">
      <div class="font3">
        <p>应用领域</p>
      </div>
      <div class="box">
        <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
      </div>
    </div>

    <!-- --------------------------------------- 主要功能 -->
    <div class="four" :style="{ width: this.fourWidth.width + 'px' }" style="height: 802px;">
      <div class="retailFont4">
        <p style="width:9% ;height:20%">主要功能</p>
      </div>
      <div class="multi-purpose" style="width: 100%; height:75%;position: relative;margin-top: 60px;">
        <div class="all" style="width: 1096px; height:537px">

          <!-- 导航栏 -->
          <nav class="menu" style="width: 100%; height:11%">
            <div class="menu-router" style=" margin-left:2%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem1" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem2" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem3" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem4" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem5" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 12%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem6" />
            </div>
            <div class="menu-router" style="margin-right: 4%;width: 14%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem7" />
            </div>
          </nav>

          <!-- 点击导航项显示下面不同的内容 -->
          <div class="menu-content" style="width: 86%; height:89%">

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem1" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem2" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem3" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem4" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem5" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem6" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem7" />
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- --------------------------------硬件支持 -->
    <div class="AboutOneHardwareSupport"
      :style="{ width: this.fiveWidth.width + 'px' }" style="height:1521px">
      <!-- 硬件支持第一大部分 -->
      <div style="width:100% ;height: 994px;">

<div class="supportFont5" style="width: 100%; height:197px">
<h2>硬件支持</h2>
</div>

<div class="supportOnly" style="width:100%;height: 80.2%;">

<div class="tool" style="width:1071px;height: 797px">

<!-- 上面一排三个 -->
<div class="topTool" style="width: 1071px; height: 408px">

<div class="top-box">
<AboutOneSupportOnlyDiv  :hardItem="wmsItem1"></AboutOneSupportOnlyDiv>
</div>

<div class="top-box">
<AboutOneSupportOnlyDiv  :hardItem="wmsItem2"></AboutOneSupportOnlyDiv>
</div>

<div class="top-box">
<AboutOneSupportOnlyDiv  :hardItem="wmsItem3"></AboutOneSupportOnlyDiv>
</div>
</div>

<!-- 下面一排三个 -->
<div class="bottomTool" style="width: 1071px; height:387px">
<div class="bottom-box">
<AboutOneSupportOnlyDiv  :hardItem="wmsItem4"></AboutOneSupportOnlyDiv>
</div>

<div class="bottom-box">
<AboutOneSupportOnlyDiv  :hardItem="wmsItem5"></AboutOneSupportOnlyDiv>
</div>

<div class="bottom-box">
<AboutOneSupportOnlyDiv  :hardItem="wmsItem6"></AboutOneSupportOnlyDiv>
</div>
</div>


</div>
</div>
</div>

      <!-- 实际案例 -->
      <Others  :index="index" :currentType="currentType" :topType="topType" :arrayIndex="1" />
    </div>

    <!-- 关于我们 -->
    <div :style="{ width: this.sixWidth.width + 'px', height: this.sixWidth.height + 'px' }">
      <contactUs :pageType="1" />
    </div>

  </div>
</template>

<script>
// 顶部标题
import CaseTop from '../components/CaseTop.vue'
// 关于我们
import contactUs from '../components/contactUs.vue'
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
// 硬件支持
import AboutOneSupportOnlyDiv from '../components/AboutOneSupportOnlyDiv.vue'
// 主要功能导航栏
import AboutOneMenu from '../components/AboutOneMenu.vue'
// 主要功能导航栏对应内容
import AboutOneMenuContent from '../components/AboutOneMenuContent.vue'
// 应用领域
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
// 申请试用
import AboutOneApplyTryDiv from '../components/AboutOneApplyTryDiv.vue'
// 实际案例
import Others from '../components/Others.vue'


// 应用领域
import areaImg1 from '../assets/aboutOneApplicationArea/convenieceIcon.webp'
import areaImg2 from '../assets/aboutOneApplicationArea/shoppingIcon.webp'
import areaImg3 from '../assets/aboutOneApplicationArea/supermarketIcon.webp'
import areaImg4 from '../assets/aboutOneApplicationArea/machineIcon.webp'

// 硬件支持
import wmsWare1 from '../assets/aboutOneHardware/shouYinJi.png'
import wmsWare2 from '../assets/aboutOneHardware/saoMiaoQiang1.png'
import wmsWare3 from '../assets/aboutOneHardware/Dayinji.png'
import wmsWare4 from '../assets/aboutOneHardware/mobileTerminal.png'
import wmsWare5 from '../assets/aboutOneHardware/ScanCode.png'
import wmsWare6 from '../assets/aboutOneHardware/cabinetMachine.png'

// 主要功能
import retailFunction1 from '../assets/AboutLingShouMainFunction/function1.png'
import retailFunction2 from '../assets/AboutLingShouMainFunction/function2.png'
import retailFunction3 from '../assets/AboutLingShouMainFunction/function3.png'
import retailFunction4 from '../assets/AboutLingShouMainFunction/function4.png'
import retailFunction5 from '../assets/AboutLingShouMainFunction/function5.png'
import retailFunction6 from '../assets/AboutLingShouMainFunction/function6.png'
import retailFunction7 from '../assets/AboutLingShouMainFunction/function7.png'
import retailBackground from '../assets/AboutLingShouMainFunction/background.png'


export default {
  name: 'AboutOneView',
  components: {
    CaseTop,
    contactUs,
    ProjectMilitaryOverview,
    AboutOneSupportOnlyDiv,
    AboutOneMenu,
    AboutOneMenuContent,
    AboutOneApplyTryDiv,
    AboutOneApplicationArea,
    Others
  },
  mounted() {
    //加载后先计算一遍缩放比例
    this.Scale();
    //生成一个监听器：窗口发生变化从新计算计算一遍缩放比例
    window.addEventListener('resize', this.Scale);

    // 实际案例部分

    //获取图片样式
    this.slides = document.querySelectorAll('.slide');
    //根据索引居中
    this.centerSlide(this.currentIndex);
    // 定时器  自动轮播，每3秒切换一次图片
    this.startTime();


    // 主要功能部分点击导航切换内容
    this.changeContent();

  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.startTime);
  },
  data() {
    return {
      oneWidth: {
        width: '1920',
        height: '400'
      },
      twoWidth: {
        width: '1919',
        height: '472'
      },
      threeWidth: {
        width: '1919',
        height: '614'
      },
      fourWidth: {
        width: '1920',
        height: '802'
      },
      fiveWidth: {
        width: '1920',
        height: '2001'
      },
      sixWidth: {
        width: '1920',
        height: '533'
      },
      // 顶部标题
      topTitle:'智慧零售系统',
      topType:0,

       // 产品概述
       overViewText: '智慧零售，是指运用互联网、物联网技术，感知消费习惯，预测消费趋势，引导生产制造。在任何地点、任何渠道、任何产品服务，不管是什么类型的客户，都能通过智能化的手段买到称心如意的个性化产品。就是用互联网等技术感知消费习惯，更准确的为消费者提供多样化个性化的产品和服务\n\t 以消费者为本：掌握了消费者的需求，并且无限逼近消费者的内心需求，重新构筑人，货物，场地等重要因素，最终可以实现以消费者为体验中心\n\t 发展多元化：新的零售形态和新的零售物种被包含其中，物流产业，文化娱乐产业，餐饮业等多元业态都闪亮登场，未来我们每一个人都有可能去零售\n\t 降低损耗，实现价值链重塑：随着消费模式的不断发展，在未来零售就将会发展到人人零售，超越时间、空间、主题、或本身“内容”的限制，所以在企业内部与企业之间的流通损耗是可能达到无限接近于零的状态，此时，价值链将获得重塑',

      // 硬件支持
      wmsItem1: {text:'台式收银机', content: '集屏幕、打印机、键盘及钱箱四合一一体机，配备打印、钱箱、键盘等功能', hard: wmsWare1, hardWidth: '46', hardHeight: '107',iconRight:'7',iconBottom:'1'},
      wmsItem2: {text:'扫描枪', content: '扫描快而准、收银效率高，商品码/支付码快速读取，识别异常码、双灯设计，蜂鸣提示、震动提示、防震抗摔、回弹按键，兼容多系统', hard: wmsWare2, hardWidth: '61', hardHeight: '83',iconRight:'5',iconBottom:'0'},
      wmsItem3: {text:'标签打印机', content: '票据标签双模式，随心所欲打印不干胶标签及票据，可选蓝牙接口，轻松接入APP/小程序', hard: wmsWare3, hardWidth: '45', hardHeight: '89',iconRight:'6',iconBottom:'2'},
      wmsItem4: {text:'移动终端', content: '外卖接单、排队叫号、移动支付、点餐、打印小票、卡券核销、营销管理', hard: wmsWare4, hardWidth: '31', hardHeight: '140',iconRight:'7',iconBottom:'0'},
      wmsItem5: {text:'扫码盒子', content: '即插即用，扫码支付45°人性化扫码角度感应灯，支付看得见，蜂鸣声，听得到确认', hard: wmsWare5, hardWidth: '53.8', hardHeight: '104.6',iconRight:'5',iconBottom:'5'},
      wmsItem6: {text:'自助柜机', content: '扫码或者刷脸开门，风扇制冷，保证存储食品的品质，开门后选取商品，关门后自动扣款', hard: wmsWare6, hardWidth: '77', hardHeight: '131',iconRight:'3',iconBottom:'1'},
      
      // 主要功能导航栏
      menuItem1:{menuMsg:'数据管理'},
      menuItem2:{menuMsg:'财务管理'},
      menuItem3:{menuMsg:'库存管理'},
      menuItem4:{menuMsg:'人员管理'},
      menuItem5:{menuMsg:'用户管理'},
      menuItem6:{menuMsg:'商品管理'},
      menuItem7:{menuMsg:'营销推广'},

      // 主要功能内容
      contentItem1:{menuContent:' 店铺经营的数据，实时了解每日的销售额和成本核算尤为重要。大而全、精而准的数据统计和分析，有助于掌握商品的销售情况，因此，收银系统后台是否集成这样一个数据采集和储存体系，在智慧零售中扮演串联消费现场和决策端的关键角色是非常重要的',menuImg:retailFunction1, menuWidth: '115', menuHeight: '55',menuLeft:'-7',menuSpanTop:'16',menuTop:'18',backImg:retailBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},
      contentItem2:{menuContent:' 零售系统的收银方式分为：台式收银和移动收银两种方式 \n 台式收银：PC收银客户端主要支持日常收银，且支持无码商品直输价格销售、扫码商品直接销售、扫电子秤码销售、输助词码销售。  \n  移动收银：零售收银台既能支持店员扫码枪录入顾客购买的商品，也能通过手动搜索或从商品列表中选择商品。线上线下商品数据互通（比如售价、规格、库存等等），实现门店商品统一、高效管理',menuImg:retailFunction2, menuWidth: '93', menuHeight: '63',menuLeft:'-3',menuSpanTop:'16',menuTop:'12',backImg:retailBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},
      contentItem3:{menuContent:' 库存管理分为进货管理、采购退货、产品盘点、库存调拨四个大模块。支持采购管理、库存盘点、库存调拨、库存预警等，从而加强门店库存周转，减轻库存压力',menuImg:retailFunction3, menuWidth: '113', menuHeight: '53',menuLeft:'-13',menuSpanTop:'16',menuTop:'15',backImg:retailBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},
      contentItem4:{menuContent:'系统能够协助商场管理者进行人员管理。包括员工排班管理、考勤管理、薪资结算等。通过系统的自动化处理，减轻了管理者的工作压力，提高了人力资源的利用和管理效率',menuImg:retailFunction4, menuWidth: '105', menuHeight: '65',menuLeft:'-7',menuSpanTop:'16',menuTop:'12',backImg:retailBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},
      contentItem5:{menuContent:' 管理系统通过用户管理模块，能够帮助管理者更好地了解和用户沟通。系统会自动采集消费者的购物偏好、消费习惯等信息，从而为消费者提供更个性化的服务。管理者可以基于这些数据进行市场推广和用户关系维护',menuImg:retailFunction5, menuWidth: '123', menuHeight: '57',menuLeft:'-8',menuSpanTop:'16',menuTop:'18',backImg:retailBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},
      contentItem6:{menuContent:' 商品管理有商品分类、新增商品、商品列表模块。轻松实现设置商品分类，商品成本价、零售价、会员价的管理，以及热销商品统计等',menuImg:retailFunction6, menuWidth: '100', menuHeight: '61',menuLeft:'-5',menuSpanTop:'16',menuTop:'15',backImg:retailBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},
      contentItem7:{menuContent:' 提供了营销推广的功能模块。可以通过系统推送优惠信息、折扣券等促销方式，吸引更多消费者到店消费。同时，系统也能够根据消费者的购买行为和偏好，进行个性化的推荐和优惠活动',menuImg:retailFunction7, menuWidth: '100', menuHeight: '61',menuLeft:'-5',menuSpanTop:'16',menuTop:'16',backImg:retailBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},

      // 实际案例
      index:-1,
      currentType: 2,

      carText1: '机动实操考核系统',
      carText2: '设备台站模拟训练系统',
      carText3: '红蓝双方兵力对抗系统',
      carImage1: areaImg1,
      carImage2: areaImg2,
      carImage3: areaImg3,
      carFieldImgs: [areaImg1, areaImg2, areaImg3],
      carFieldTexts: ['机动实操考核系统', '设备台站模拟训练系统', '红蓝双方兵力对抗系统'],

      currentIndex: 0,//当前显示的图片索引

      intervalId: null, //用于存储定时器ID

      // 应用领域
      info: [

        { p1: '智能便利店', span: '智能便利店是便利店与智能技术相结合的新型零售模式，通过无人便利店、人脸支付、智能识别等技术实现自助选购、自由结账等功能', isActive: false, backgroundImage: areaImg1 },
        { p1: '智能购物中心', span: '智能购物中心是一种结合智能科技和购物中心的新兴产业，通过智能导航、精准推荐等技术，为消费者带来便捷的购物服务', isActive: false, backgroundImage: areaImg2 },
        { p1: '智能超市', span: '智能超市是一种将人工智能、RFID、物联网等技术应用于超市的新型超市模式。智能超市通过RFID识别、人脸识别、语音识别等技术实现自助购物和快速结账，大大缩短了消费者的购物时间，提高了购物效率', isActive: false, backgroundImage: areaImg3 },
        { p1: '智能售货机', span: '智能售货机是一种结合人工智能、互联网技术和物联网技术的新型零售模式，通过智能硬件和智能算法提供便捷的购物体验', isActive: false, backgroundImage: areaImg4 }
      ],
    }
  },
  methods: {
    Scale() {
      if (document.body.clientWidth < 1200) {
        this.oneWidth.width = 1200;
        this.oneWidth.height = 250;

        this.twoWidth.width = 1200;
        // this.twoWidth.height = 295

        this.threeWidth.width = 1200;
        // this.threeWidth.height = 616;

        this.fourWidth.width = 1200;
        // this.fourWidth.height = 503

        this.fiveWidth.width = 1200;
        // this.fiveWidth.height = 1251

        this.sixWidth.width = 1200;
        this.sixWidth.height = 333
      } else {
        this.oneWidth.height = 0.21 * document.body.clientWidth;
        this.oneWidth.width = document.body.clientWidth;

        // this.twoWidth.height = 0.25 * document.body.clientWidth;
        this.twoWidth.width = document.body.clientWidth;
        // 0.32 * document.body.clientWidth;
        // this.threeWidth.height = 616;
        this.threeWidth.width = document.body.clientWidth;

        // this.fourWidth.height = 0.42 * document.body.clientWidth;
        this.fourWidth.width = document.body.clientWidth;

        // this.fiveWidth.height = 1.04 * document.body.clientWidth;
        this.fiveWidth.width = document.body.clientWidth;

        this.sixWidth.height = 0.28 * document.body.clientWidth;
        this.sixWidth.width = document.body.clientWidth;
      }
    },

    /*锁定选定的盒子判断他是否选定 */
    // divClick(index) {
    //   /** 当 “点击” 一个“已被点击”的div时 使该div恢复默认色 */
    //   // 遍历info数组中的每个对象
    //   for (let i of this.info) {
    //     // 判断当前对象是否和传入的索引index对应 如果是，则取反
    //     if (index === this.info.indexOf(i)) {
    //       i.isActive = !i.isActive
    //     } else {
    //       i.isActive = false;
    //     }
    //   }
    // },

    // 鼠标滑过时，显示背景图片和修改文字颜色
    mouseOver(index) {
      this.info[index].isActive = true;
    },

    // 鼠标离开时则恢复默认
    mouseLeave(index) {
      this.info[index].isActive = false;
    },


    /*给选定的盒子修改样式 */
    colorDiv(index) {
      if (this.info[index].isActive) {
        return {
          backgroundImage: `url(${this.info[index].backgroundImage})`,
          color: 'white'
        };
      } else {
        return {
          backgroundImage: 'none',
          '.p1 p': {
            color: '#333333;'
          },
          '.span span': {
            color: 'black'
          }
        }
      }
    },

    /*
    实际案例部分轮播切换
     */
    showSlide(index) {
      this.carImage1 = this.carFieldImgs[index];
      this.carText1 = this.carFieldTexts[index];
      this.carImage2 = this.carFieldImgs[++index % 3];
      this.carText2 = this.carFieldTexts[index % 3];
      this.carText3 = this.carFieldTexts[++index % 3];
      this.carImage3 = this.carFieldImgs[index % 3];
      this.currentIndex = (++index) % 3;
    },
    //居中显示
    centerSlide(index) {
      this.currentIndex = index;
      this.showSlide(index);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.carFieldImgs.length;
      this.currentIndex = (this.currentIndex + 1) % this.carFieldTexts.length;
      this.showSlide(this.currentIndex);
    },

    // 定时器 自动轮播
    startTime() {
      this.intervalId = setInterval(this.nextSlide, 5000);
    },

    chanageImg(index) {
      /* 重置计时器 */
      clearInterval(this.intervalId);
      this.currentIndex = index;
      //  // 将被点击的图片居中显示
      // this.centerSlide(this.currentIndex);

      //计算需要移动的步数
      let steps = index - this.currentIndex;

      // 如果步数大于轮播图一半的长度，向前滚动到达目标图片
      if (Math.abs(steps) > this.slides.length / 2) {
        steps = steps > 0 ? steps - 3 : steps + 3;
      }

      // 更新 currentIndex，使目标图片移动到中间位置
      this.currentIndex = (this.currentIndex + steps + 3) % 3;

      // 将目标图片居中显示
      this.centerSlide(this.currentIndex);

      //重新启动定时器
      this.startTime();
    },

    // 主要功能部分切换功能
    changeContent() {
      // 在页面加载完成后先执行这个
      // document.addEventListener("DOMContentLoaded",function(){
      //   // 获取第一个菜单项
      //   var firstMenuItem = document.querySelector('.menu-router');
      //   // 让他点击第一个菜单项
      //   firstMenuItem.click();
      // })

      // 获取元素
      // var tab_list = document.querySelector('.menu');
      var list = document.querySelectorAll('.menu-router');
      var item = document.querySelectorAll('.contentItem');

      // item[0].style.display='block';
      // 获取第一个导航项
      var firstNavItem = document.querySelector('.menu-router');
      //获取第一个导航项对应的内容
      var firstContentItem = document.querySelector('.contentItem');
      // 给导航项添加样式
      firstNavItem.classList.add('menuActive');
      // 让第一导航项对应内容默认显示
      firstContentItem.style.display = 'block';

      // 分别绑定点击事件
      for (var i = 0; i < list.length; i++) {
        // 在这里创建属性，添加索引
        list[i].setAttribute('index', i);
        list[i].onclick = function () {
          // 1.第一大步骤，先解决导航栏的颜色切换
          // 所有的 menu-router清除class类
          for (var i = 0; i < list.length; i++) {
            list[i].classList.remove('menuActive');
          }
          // 自己添加上这个类
          this.classList.add('menuActive');
          // 2.第二大步骤：下面模块跟着选项卡一一匹配
          // 给上面的tab_list里面所有的li添加索引
          var index = this.getAttribute('index');
          console.log(index);
          // 先全部隐藏
          for (var j = 0; j < item.length; j++) {
            item[j].style.display = 'none';
          }
          // 当前的显示
          item[index].style.display = 'block';
        }
      }
    },


  },
}

</script>


<style>
.about {
  width: 100%;
  height: auto;
}

.aboutNav{
  width: 100%;
    height: 90px;
    background-color: #000;
}

.three {
  /* width: 1202px;
	height: 663px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  background-color: #f6f6f6;
}

.font3 {
  width: 100%;
  height: 116px;
}

.font3 p {
  font-family: MySourceHanSansSC-Heavy;
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 2px;
  color: #333333;
  white-space: nowrap;
  margin: 0 auto;
}

.box {
  width: 1200px;
  height: 390px;
  display: flex;
  justify-content: space-between;
  /*间隔均匀排列*/
  justify-content: center;
  /* margin: 0 auto; */
  margin: 0;
}

.four {
  background-image: url(' ../assets/mainFunctions.jpg ');
  background-size: cover;
}
.retailFont4 {
  width: 100%;
  height: 120px;
  display: flex;
  white-space: nowrap;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  /*水平居中 */
  /*align-items: center; 垂直居中*/
  position: relative;
  /*相对定位 */
}

.retailFont4 p {
  font-family: SourceHanSansSC-Heavsy;
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  /* line-height: 30px; */
  letter-spacing: 4px;
  color: #ffffff;
  margin: 0;
  position: absolute;
  /*绝对定位 */
  bottom: 0;
  /*让他的下边距为0 */
  /* margin-top: 50%; */
  /* margin-bottom: 50%; */
}


.multi-purpose {
  /* top: 9%; */

}

.all {
  background-color: white;
  margin: 0 auto;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid rgb(112, 112, 112); */
  border-bottom: solid 1px #d3d3d3;
  border-bottom-width: 50%;
}
.menu-router {
  cursor: pointer;
  /* writing-mode: vertical-lr; */
  /*将文字垂直排列 */
  white-space: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  line-height: 1;
}


.menu-content {
  /* margin-top: 5%; */
  margin-left: 5%;
  /* margin-right: 0; */
  display: flex;
  justify-content: space-between;
}

.contentItem {
  display: none;
}

.menuActive {
  /* background-color: green; */
  border-bottom: solid 2px #ff9000;
  color: #fff;
}



.AboutOneHardwareSupport {
  /* margin: 0 auto; */
  /* display: flex;
 justify-content: center; */
  /* margin-top: 4%; */
  background-color: #f6f6f6;
}

.supportFont5 {
  display: flex;
  position: relative;
  justify-content: center;

}

.supportFont5 h2 {
  /* width: 9%;
  height: 23%; */
  font-family: MySourceHanSansSC-Heavy;
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  /* line-height: 10px; */
  letter-spacing: 2px;
  color: #333333;
  margin: 0;
  white-space: nowrap;
  position: absolute;
  top: 100px;
}

.supportOnly {
  display: flex;
  flex-direction: column;
  /*让整个容器垂直排列 */
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  /* margin: 0 auto; */
  /* margin-left: 17%;
  margin-right: 11%; */
  margin: 0 auto;
}

.tool {
  /* display: flex;
  justify-content: space-between; */
  /*间隔均匀排列*/
  /* justify-content: center; */
}

.topTool {
  display: flex;
  justify-content: space-between;
}

.bottomTool {
  display: flex;
  justify-content: space-between;
}

.top-box {
  width: 32%;
  height: 95%;
  /* opacity: 0.2; */
  /*加阴影 调整阴影的弧度和颜色 */
}

.bottom-box {
  width: 32%;
  height: 100%;
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); */
  /* box-shadow: 0px 5px 9px 1px 
		rgba(2, 4, 11, 0.5); */
  /*加阴影 调整阴影的弧度和颜色 */
}


/* 实际案例标题 */
.font6 h2 {
  font-family: SourceHanSansSC-Heavy;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 4px;
  color: #333333;
  margin: 0;
}


/* -----------------1201-1366之间---------------- */
@media (min-width: 1201px) and (max-width:1366px) {
  /* 实际案例标题 */
  .font6 h2 {
    font-size: 25px;
  }

}


/* -----------------1367-1600之间---------------- */
@media (min-width: 1367px) and (max-width:1600px) {

  /* 实际案例标题 */
  .font6 h2 {
    font-size: 30px;
  }
}


/* -----------------1600以上---------------- */
@media (min-width: 1600px) {
  /* 实际案例标题 */
  .font6 h2 {
    font-size: 40px;
  }


}</style>