<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px', height:this.mainFunctionLarge+'px'}">
            <div class="caseMainFunctionLargeDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/dianzihaitu/computer.png" alt="">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" :isLong="true" />
                    </div>
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem5" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem6" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem7" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem8" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPicFour" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
                <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize1" />
                <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize2" />
            <div style="height: 100%; width: 33.3%;">
                <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize3" />
                <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize3" />
            </div>
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" :topType="topType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/dianzihaitu/icon1.png'
import icon2 from '../assets/case/dianzihaitu/icon2.png'
import icon3 from '../assets/case/dianzihaitu/icon3.png'
import icon4 from '../assets/case/dianzihaitu/icon4.png'
import icon5 from '../assets/case/dianzihaitu/icon5.png'
import icon6 from '../assets/case/dianzihaitu/icon6.png'
import icon7 from '../assets/case/dianzihaitu/icon7.png'
import icon8 from '../assets/case/dianzihaitu/icon8.png'
import pic1 from '../assets/case/dianzihaitu/projectPic1.png'
import pic2 from '../assets/case/dianzihaitu/projectPic2.png'
import pic3 from '../assets/case/dianzihaitu/projectPic3.png'
import pic4 from '../assets/case/dianzihaitu/projectPic4.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseElectronicChart',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '620',
            projectPic:'854',
            topTitle: '电子海图应用管理软件',
            pojectOverviewMsg: '我们的电子海图应用管理软件旨在为海事从业人员提供高效、便捷的电子海图管理和应用功能，助力船舶导航和海上作业实现更高的安全性和效率。在项目中，我们致力于实现高度准确的电子海图显示功能，为用户提供多种显示模式和图层，以满足他们在不同场景下的需求。同时，我们还提供强大的海图查询和分析功能，包括航线规划、测距、测深等，以便用户能够实时获取关键信息。为了提供更好的用户体验，我们采用现代化的用户界面设计，使操作简单直观。即使对于非专业人士，他们也能轻松上手，无需专业培训或指导',
            iconItem1: {title:'数据读取', text: '可以加载多种海图形式以及数据', icon: icon1},
            iconItem2: {title:'海图操作', text: '海图支持拼接显示\n缩放和平移操作', icon: icon2},
            iconItem3: {title:'分层显示', text: '海图支持分层显示标绘层、航线层等', icon: icon3},
            iconItem4: {title:'计算功能', text: '支持坐标，方位距离、面积等计算', icon: icon4},
            iconItem5: {title:'标绘功能', text: '支持标绘定制，鼠标与键盘操作', icon: icon5},
            iconItem6: {title:'数据库', text: '支持存储海图数据和调用', icon: icon6},
            iconItem7: {title:'接口', text: 'S57标准的电子海图应用相关接口', icon: icon7},
            iconItem8: {title:'二次开发', text: '支持海图相关功能的二次开发', icon: icon8},
            picItem1: {title:'电子海图', pic: pic1},
            picItem2: {title:'海图操作', pic: pic2},
            picItem3: {title:'数据计算', pic: pic3},
            picItem4: {title:'海图标绘', pic: pic4},
            caseProjectPicSize1: {width: '44.8', height: '100'},
            caseProjectPicSize2: {width: '21.9', height: '100'},
            caseProjectPicSize3: {width: '100', height: '50'},
            index: 1,
            currentType: 0,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 387.5;
                this.projectPic = 534;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.322;
                this.projectPic = document.body.clientWidth * 0.445;
            }
        },
    },
}
</script>

<style>
.caseProjectPicFour{
    display: flex;
    justify-content: space-between;
    position: relative;
}
.caseProjectPicFour div{
    position: relative;
}
.caseProjectPicFour .textTitle{
    color: #fff; 
    position: absolute; 
    left: 50%; 
    top: 50%; 
    transform: translate( -50%, -50% );
}
.caseProjectPicFour img{
    width: 100%;
    height: 100%;
}
</style>