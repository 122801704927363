<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 产品概述 -->
        <ProjectMilitaryOverview :overViewText='overViewText' :isShort="true" :isMilitary="true" />
        <!-- 应用领域 -->
        <div class="projectMilitaryApply">
            <p class="title">应用领域</p>
            <div class="projectMilitaryApplyBox">
                <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
            </div>
        </div>
        <!-- 产品特色 -->
        <div class="projectMilitaryFeaturesDiv">
            <p class="title">产品特色</p>
            <div class="projectMilitaryFeaturesArea">
                <ProjectMilitaryFeaturesRight :item="item1" />
                <ProjectMilitaryFeaturesLeft :item="item2" :changeWidth="1" />
                <ProjectMilitaryFeaturesRight :item="item3" :changeWidth="1" />
                <ProjectMilitaryFeaturesLeft :item="item4" />
            </div>
        </div>
        <!-- 其他案例 -->
        <Others :index="-1" :currentType="0":arrayIndex="5" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import areaImg1 from '../assets/management/areaImg1.webp'
import areaImg2 from '../assets/management/areaImg2.webp'
import areaImg3 from '../assets/management/areaImg3.webp'
import areaImg4 from '../assets/management/areaImg4.webp'
import featureImg1 from '../assets/management/featureImg1.png'
import featureImg2 from '../assets/management/featureImg2.png'
import featureImg3 from '../assets/management/featureImg3.png'
import featureImg4 from '../assets/management/featureImg4.png'

import CaseTop from '../components/CaseTop.vue'
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
import ProjectMilitaryFeaturesRight from '../components/ProjectMilitaryFeaturesRight.vue'
import ProjectMilitaryFeaturesLeft from '../components/ProjectMilitaryFeaturesLeft.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'ProductManagementView',
    components: {
        CaseTop,
        ProjectMilitaryOverview,
        AboutOneApplicationArea,
        ProjectMilitaryFeaturesRight,
        ProjectMilitaryFeaturesLeft,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 0,
            topTitle: '管理软件',
            // 产品概述
            overViewText: '管理软件是一种应用软件，主要用于帮助组织或个人管理信息，通常涵盖了多种管理职能，如计划、组织、领导和控制等。我司主要针对于“军用管理软件”，主要用于军事组织、军事部门使用的软件，如军事组织使用的财务管理、人事管理、后勤保障等软件，军队使用的作战指挥、情报侦察、武器装备管理等软件。要求可靠性、安全性、保密性等，主要应用于人员管理、专家管理、设备管理、物资管理等的记录和分析，必要时还需要审批流程相关功能',
            // 应用领域
            info: [
                { p1: '军用管理软件', span: '用于军事管理的软件平台。它通过集成各种先进的技术和工具，为军事管理提供全面的信息化管理解决方案', isActive: false, backgroundImage: areaImg1 },
                { p1: '人力资源管理', span: '人力资源管理软件是指对人力资源管理方方面面进行分析、规划、实施、调整，包括人力规划、人事管理、薪酬、保险、考勤、招聘、培训、考核、计件等各个功能', isActive: false, backgroundImage: areaImg2 },
                { p1: '客户关系管理', span: '客户关系管理软件为了满足每个客户的特殊需求，同每个客户建立联系，客户关系管理软件包括销售管理、商务管理，数据汇总，市场营销管理、客户服务系统以及呼叫中心等方面', isActive: false, backgroundImage: areaImg3 },
                { p1: '供应链管理', span: '供应链管理是指把供应商、制造商、经销商、零售商、客户等一条供应链上的各个节点联系起来并进行优化，使生产资料快速高效地加工、分销成为增值的商品，最后到达到客户手上。使整条供应链的节点的总体利益最大化的过程', isActive: false, backgroundImage: areaImg4 }
            ],
            // 产品特色
            item1: {title: '任务协同', text: '有效解决团队协作问题。任务拆解、分配，责任有效落实。时间动态、交流讨论，整体任务进度直观可控', img: featureImg1},
            item2: {title: '行政办公', text: '完善的办公用品、会议室、车辆等行政管理功能，为您降低运营管理成本，提升行政管理效率', img: featureImg2},
            item3: {title: '流程审批', text: '细致缜密的办理权限和课件权限控制，数据更安全，管理更清晰，开发流程能力，各种内部和外部系统都可打造“流水线”', img: featureImg3},
            item4: {title: '报表功能', text: '提供数据统计、汇总、分析、打印、输出、预警等核心功能，助力企业挖掘数据价值、提升应用系统价值，为企业管理和领导决策提供数据决策支持', img: featureImg4},
        }
    },
}
</script>