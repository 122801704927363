<template>
    <div class="casePojectOverview">
        <div class="casePojectOverviewDiv">
            <p class="title">项目概况</p>
            <p id="text" class="textMsg" :style="{width:this.width+'px', left:this.left+'px'}">{{ pojectOverviewMsg }}</p> 
        </div>
    </div>
</template>

<script>
export default {
    name: 'CasePojectOverview',
    data() {
        return {
            width: '800',
            left: '380',
        }
    },
    props:{
        pojectOverviewMsg:{
            type: String,
            required: true,
        },
        isLong:{
            type: Boolean,
            default: false,
        },
        isShort:{
            type: Boolean,
            default: false,
        }
    },
    created() {
        this.init()
    },
    methods: {
        init () {
            if(this.isLong){
                this.width = '810';
                this.left = '370';
            }
            if(this.isShort){
                this.width = '795';
                this.left = '385';
            }
        },
    },
}
</script>

<style>   
.casePojectOverview{
    width: 100%;
    position: relative;
    display: flex;
    background-color: #eee;
    height: 300px;
}
.casePojectOverviewDiv{
    position: absolute; 
    left: 50%; 
    transform:translateX( -50% ); 
    top: 0; 
    width: 1200px; 
    height: 300px; 
    display: flex;
}
.casePojectOverview .title{
    color: #333; 
    position: absolute; 
    left: 0;
    top: 80px;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 2px;
}
.casePojectOverview .textMsg{
    color: #333; 
    position: absolute; 
    top: 50%; 
    transform: translateY( -50% ); 
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 2px;
}
</style>