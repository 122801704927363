<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" :isLong="true" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px'}" style="height: 447px;">
            <div class="caseMainFunctionLargeDiv" style="height: 86.6%;">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/quanweishujv/computer.png" style="height: 65.1%; ">
                </div>
                <div class="caseMainFunctionIconLine" style="height: 55.6%; width: 65%;">
                    <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                    <CaseMainFunctionIconDiv :iconItem="iconItem2" :isLong="true" />
                    <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                    <CaseMainFunctionIconDiv :iconItem="iconItem4" />
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem5" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem6" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/quanweishujv/icon1.png'
import icon2 from '../assets/case/quanweishujv/icon2.png'
import icon3 from '../assets/case/quanweishujv/icon3.png'
import icon4 from '../assets/case/quanweishujv/icon4.png'
import pic1 from '../assets/case/quanweishujv/projectPic1.png'
import pic2 from '../assets/case/quanweishujv/projectPic2.png'
import pic3 from '../assets/case/quanweishujv/projectPic3.png'
import pic4 from '../assets/case/quanweishujv/projectPic4.png'
import pic5 from '../assets/case/quanweishujv/projectPic5.png'
import pic6 from '../assets/case/quanweishujv/projectPic6.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseFullDimensionalData',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '447',
            projectPic:'427',
            topTitle: '全维数据展示平台',
            pojectOverviewMsg: '“全维数据展示平台”旨在帮助用户以全面、直观的方式展示和分析各种类型的数据。提供了多种数据可视化和交互功能，能够将多维、大规模的数据转化为易于理解和分析的图表、图形和地图等形式。用户可以根据自己的需求选择合适的数据展示方式，并进行数据筛选、排序和聚合等操作。全平台支持多种数据类型，利用平台提供的示例数据集进行展示和分析。用户可以进行统计分析、趋势分析、关联分析等操作，从数据中发现规律和洞察，并支持用户进行决策和规划。全维数据展示平台注重用户体验和可定制性，提供了友好的用户界面和灵活的配置选项，以满足不同领域和场景的需求',
            iconItem1: {title:'数据可视化', text: '条形图/折线图/散点图/饼图/地图等，以展示数据的不同方面', icon: icon1},
            iconItem2: {title:'数据交互', text: '可以通过筛选、排序、聚合等操作与数据进行互动', icon: icon2},
            iconItem3: {title:'多数据类型支持', text: '支持多种数据类型的展示和分析，包括数值/文本/地理数据等', icon: icon3},
            iconItem4: {title:'数据分析', text: '提供统计分析、趋势分析、关联分析等功能', icon: icon4},
            picItem1: {title:'多数据类型', pic: pic1},
            picItem2: {title:'数据展示', pic: pic2},
            picItem3: {title:'数据可视化', pic: pic3},
            picItem4: {title:'图表展示', pic: pic4},
            picItem5: {title:'数据交互', pic: pic5},
            picItem6: {title:'数据分析', pic: pic6},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 6,
            currentType: 0,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 278;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.233;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    },
}
</script>