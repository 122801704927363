<template>
  <div class="about">
    <!-- <div class="aboutNav" /> -->
    <!-- -----------------------生产执行系统(MES) -->
    <CaseTop :topTitle="topTitle" :topType="topType" />

    <!-- ----------------------------产品概述 -->

    <ProjectMilitaryOverview :overViewText='overViewText' :isShort="true" />


    <!-- ---------------------------应用领域 -->
    <div class="three" :style="{ width: this.threeWidth.width + 'px'}" style="height: 673px;">
      <div class="font3">
        <p>应用领域</p>
      </div>
      <div class="box">
        <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
      </div>
    </div>

    <!-- --------------------------------------- 主要功能 -->
    <div class="four" :style="{ width: this.fourWidth.width + 'px'}" style="height: 802px;">
      <div class="font4">
        <p style="width:9% ;height:20%">主要功能</p>
      </div>
      <div class="multi-purpose" style="width: 100%; height:75%;position: relative;margin-top: 60px;">
        <div class="all"  style="width: 1096px; height:537px">

          <!-- 导航栏 -->
          <nav class="menu" style="width: 100%; height:11%">
            <div class="menu-router" style=" margin-left:2%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem1" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem2" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem3" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem4" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem5" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 12%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem6" />
            </div>
            <div class="menu-router" style="margin-right: 4%;width: 14%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem7" />
            </div>
          </nav>

          <!-- 点击导航项显示下面不同的内容 -->
          <div class="menu-content" style="width: 86%; height:89%">

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem1"/>
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem2"/>
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem3" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem4"/>
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem5" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem6"/>
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem7" />
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- --------------------------------硬件支持 -->
    <div class="AboutOneHardwareSupport"
      :style="{ width: this.fiveWidth.width + 'px'}"  style="height:2005px">
      <!-- 硬件支持第一大部分 -->
      <div style="width:100% ;height: 992px">

        <div class="supportFont5" style="width: 100%; height:195px">
        <h2>硬件支持</h2>
      </div>

        <div class="supportOnly" style="width:100%;height: 80%;">

     <div class="tool" style="width:1071px;height: 789px">

  <!-- 上面一排三个 -->
  <div class="topTool" style="width: 1071px; height: 404px">

    <div class="top-box">
      <AboutOneSupportOnlyDiv  :hardItem="hardItem1"></AboutOneSupportOnlyDiv>
    </div>

    <div class="top-box">
      <AboutOneSupportOnlyDiv  :hardItem="hardItem2"></AboutOneSupportOnlyDiv>
    </div>

    <div class="top-box">
      <AboutOneSupportOnlyDiv  :hardItem="hardItem3"></AboutOneSupportOnlyDiv>
    </div>
  </div>

  <!-- 下面一排三个 -->
  <div class="bottomTool" style="width: 1071px; height:385px">
    <div class="bottom-box">
      <AboutOneSupportOnlyDiv  :hardItem="hardItem4"></AboutOneSupportOnlyDiv>
    </div>

    <div class="bottom-box">
      <AboutOneSupportOnlyDiv  :hardItem="hardItem5"></AboutOneSupportOnlyDiv>
    </div>

    <div class="bottom-box">
      <AboutOneSupportOnlyDiv  :hardItem="hardItem6"></AboutOneSupportOnlyDiv>
    </div>
  </div>


</div>
</div>
      </div>
      <!-- 申请试用 -->
      <div class="apply" style="width:100% ;height:24%">
        <div class="total" style="width:100% ;height:100%">
          <AboutOneApplyTryDiv />
        </div>
      </div>

      <!-- 实际案例 -->
      <Others  :index="index" :currentType="currentType" :topType="topType" :arrayIndex="3" />
    </div>

    <!-- 关于我们 -->
    <div :style="{ width: this.sixWidth.width + 'px', height: this.sixWidth.height + 'px' }">
      <contactUs :pageType="1" />
    </div>

  </div>
</template>

<script>
// 顶部标题
import CaseTop from '../components/CaseTop.vue'
// 关于我们
import contactUs from '../components/contactUs.vue'
// 主要功能导航栏
import AboutOneMenu from '../components/AboutOneMenu.vue'
// 主要功能导航栏对应内容
import AboutOneMenuContent from '../components/AboutOneMenuContent.vue'
// 产品概述
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
// 应用领域
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
// 硬件支持
import AboutOneSupportOnlyDiv from '../components/AboutOneSupportOnlyDiv.vue'
// 申请试用
import AboutOneApplyTryDiv from '../components/AboutOneApplyTryDiv.vue'
// 实际案例
import Others from '../components/Others.vue'


// 应用领域
import areaImg1 from '../assets/aboutOneApplicationArea/makeIcon.webp'
import areaImg2 from '../assets/aboutOneApplicationArea/energyIcon.webp'
import areaImg3 from '../assets/aboutOneApplicationArea/carIcon.webp'
import areaImg4 from '../assets/aboutOneApplicationArea/foodIcon.webp'

// 硬件支持
import hardWare1 from '../assets/aboutOneHardware/Dayinji2.png'
import hardWare2 from '../assets/aboutOneHardware/Dianzikanban.png'
import hardWare3 from '../assets/aboutOneHardware/PDA.png'
import hardWare4 from '../assets/aboutOneHardware/RAID.png'
import hardWare5 from '../assets/aboutOneHardware/Saomiaoqiang.png'
import hardWare6 from '../assets/aboutOneHardware/server.png'


// 主要功能
import oneFunction1 from '../assets/AboutOneMainFunction/function1.png'
import oneFunction2 from '../assets/AboutOneMainFunction/function2.png'
import oneFunction3 from '../assets/AboutOneMainFunction/function3.png'
import oneFunction4 from '../assets/AboutOneMainFunction/function4.png'
import oneFunction5 from '../assets/AboutOneMainFunction/function5.png'
import oneFunction6 from '../assets/AboutOneMainFunction/function6.png'
import oneFunction7 from '../assets/AboutOneMainFunction/function7.png'
import oneBackground from '../assets/AboutOneMainFunction/background.png'


export default {
  name: 'AboutOneView',
  components: {
    CaseTop,
    contactUs,
    AboutOneMenu,
    ProjectMilitaryOverview,
    AboutOneMenuContent,
    AboutOneSupportOnlyDiv,
    AboutOneApplyTryDiv,
    AboutOneApplicationArea,
    Others
  },
  mounted() {
    //加载后先计算一遍缩放比例
    this.Scale();
    //生成一个监听器：窗口发生变化从新计算计算一遍缩放比例
    window.addEventListener('resize', this.Scale);

    // 实际案例部分

    //获取图片样式
    this.slides = document.querySelectorAll('.slide');
    //根据索引居中
    this.centerSlide(this.currentIndex);
    // 定时器  自动轮播，每3秒切换一次图片
    this.startTime();


    // 主要功能部分点击导航切换内容
    this.changeContent();
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.startTime);
  },
  data() {
    return {
      oneWidth: {
        width: '1920',
        height: '400'
      },
      twoWidth: {
        width: '1919',
        height: '472'
      },
      threeWidth: {
        width: '1919',
        height: '614'
      },
      fourWidth: {
        width: '1920',
        height: '805'
      },
      fiveWidth: {
        width: '1920',
        height: '2001'
      },
      sixWidth: {
        width: '1920',
        height: '533'
      },
      // 顶部标题
      topTitle:'生产执行系统(MES)',
      topType:0,
      // 产品概述
      overViewText: 'MES系统是一套面向制造企业车间执行层的生产信息化管理系统。为企业提供包括制造数据管理、计划排程管理、生产调度管理、库存管理、质量管理、人力资源管理、工作中心、设备管理、工具工装管理、采购管理、成本管理、项目看板管理、生产过程控制、底层数据集成分析、上层数据集成分解等管理模块，为企业打造一个扎实、可靠、全面、可行的制造协同管理平台\n\t MES系统是现代制造业中不可或缺的一种生产管理工具。通过实时监控、追踪和控制生产过程，MES系统可以帮助企业提高生产效率、降低生产成本，提高产品质量，实现绿色生产等目标',
      // 硬件支持
      hardItem1: {text:'服务器', content: '服务器是ERP和MES系统的数据中心，需要24小时连续运行进行数据存储以及数据处理，我公司提供的服务器品牌有IBM，lenovo，HP等等，都是国际企业', hard: hardWare6, hardWidth: '46.7', hardHeight: '90',iconRight:'0',iconBottom:'-2' },
      hardItem2: {text:'RAID', content: 'RAID是一种硬盘技术，简单来说就是，使用RAID技术的硬盘数据更安全，坏硬盘也不打紧，数据不会丢失', hard: hardWare4, hardWidth: '55', hardHeight: '95',iconRight:'7',iconBottom:'-2'},
      hardItem3: {text:'车间电子看板', content: '看板是专门为生产型企业设计生产，用于显示当天或当班的计划生产量、实际生产量、差异值、不良品、达成率、不良率等参数，实现mes系统中的生产管控，实时掌握生产现场的状态', hard: hardWare2, hardWidth: '75', hardHeight: '93',iconRight:'2',iconBottom:'-2'},
      hardItem4: {text:'条码扫描枪', content: '条码扫描枪的功能是基于条码打印机的功能之上，通过打印出来的物料编号，进行扫描保存至系统，进行保存。对每一个物料进行管控。使用位置包括仓库、物料、车间、出货等', hard: hardWare5, hardWidth: '31.6', hardHeight: '94.4',iconRight:'6',iconBottom:'-2'},
      hardItem5: {text:'条码打印机', content: '车间仓库使用专业条码打印机，用来打印label(标签)，可以直接撕下粘贴在物料上，跟随物料一起在生产线上流转、入库或者发货', hard: hardWare1, hardWidth: '45.7', hardHeight: '100',iconRight:'7',iconBottom:'-2'},
      hardItem6: {text:'PDA', content: '数据采集终端pda是对物料的数据进行数据采集、数据处理的功能、对生产现场的数据及时采集，自动存储、及时显示、反馈处理、传输等功能。可以当手持电脑用，也可以扫条码', hard: hardWare3, hardWidth: '22.6', hardHeight: '94.1',iconRight:'6',iconBottom:'-3'},
      
      // 主要功能导航栏
      menuItem1:{menuMsg:'生产计划'},
      menuItem2:{menuMsg:'实时监控'},
      menuItem3:{menuMsg:'工单管理'},
      menuItem4:{menuMsg:'质量管理'},
      menuItem5:{menuMsg:'库存管理'},
      menuItem6:{menuMsg:'数据分析报表'},
      menuItem7:{menuMsg:'与其他系统集成'},

      // 主要功能内容
      contentItem1:{menuContent:' MES系统可以管理和优化生产计划，包括生产任务的排程、分配和追踪。它能够根据订单需求和资源情况生成合理的生产计划，确保生产流程的流畅性',menuImg:oneFunction1, menuWidth: '107', menuHeight: '83',menuLeft:'-5',menuSpanTop:'19',menuTop:'5',backImg:oneBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},
      contentItem2:{menuContent:' MES系统通过与设备、传感器和仪器的连接，实时监测制造过程中的关键参数和指标，如温度、压力、速度等。它能够及时发现生产异常和问题，并通过提供警报指导操作员进行调整，保证生产过程的稳定和可控',menuImg:oneFunction2, menuWidth: '100', menuHeight: '65.4',menuLeft:'-3',menuSpanTop:'19',menuTop:'15',backImg:oneBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},
      contentItem3:{menuContent:' MES系统可以管理和追踪每个工单的状态和进度。它记录每个工单的开始时间、结束时间、产量和质量数据等，提供实时的工单追踪和生产报表，帮助生产管理者了解工单执行情况和生产效率',menuImg:oneFunction3, menuWidth: '100',menuLeft:'-5', menuHeight: '77',menuSpanTop:'19',menuTop:'10',backImg:oneBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},
      contentItem4:{menuContent:'MES系统可以管理和控制产品质量。它收集和分析产品质量数据，进行实时的质量监控和统计，帮助找出产品质量问题的根本原因，并采取改进措施',menuImg:oneFunction4, menuWidth: '72', menuHeight: '85',menuLeft:'10',menuSpanTop:'19',menuTop:'12',backImg:oneBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},
      contentItem5:{menuContent:'MES系统可以对原材料和成品的库存进行管理和监控。它跟踪原材料的进货、消耗和库存量，提供及时的库存预警和报表，确保生产过程不会出现原料短缺或过剩的情况',menuImg:oneFunction5, menuWidth: '95', menuHeight: '72',menuSpanTop:'19',menuTop:'15',backImg:oneBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},
      contentItem6:{menuContent:' MES系统收集大量的生产数据，包括生产过程中的各种参数、指标和事件记录。它对这些数据进行分析和整理，提供各种类型的报表和图表，帮助管理者了解生产状况、分析问题和做出决策',menuImg:oneFunction6, menuWidth: '105', menuHeight: '80',menuLeft:'-5',menuSpanTop:'19',menuTop:'7',backImg:oneBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},
      contentItem7:{menuContent:'MES系统通常需要与企业的其他管理系统进行集成，如ERP系统、SCADA系统等。它与这些系统共享数据和信息，实现整个企业的信息流畅和协同',menuImg:oneFunction7, menuWidth: '110', menuHeight: '52',menuLeft:'-10',menuSpanTop:'19',menuTop:'19',backImg:oneBackground,backWidth:'81',backHeight:'40',backLeft:'-17',backBottom:'0'},

      backImg: oneBackground,

      // 实际案例
      index:-1,
      currentType: 2,

      carText1: '机动实操考核系统',
      carText2: '设备台站模拟训练系统',
      carText3: '红蓝双方兵力对抗系统',
      carImage1: areaImg1,
      carImage2: areaImg2,
      carImage3: areaImg3,
      carFieldImgs: [areaImg1, areaImg2, areaImg3],
      carFieldTexts: ['机动实操考核系统', '设备台站模拟训练系统', '红蓝双方兵力对抗系统'],

      currentIndex: 0,//当前显示的图片索引

      intervalId: null, //用于存储定时器ID

      // 应用领域
      info: [

        { p1: '制造行业', span: 'MES系统可以帮助企业实现生产任务的及时完成、生产过程的精细监控和质量的有效控制。它可以通过自动化数据采集和实时监控,提供生产计划的可视化管理和调度，确保生产线的连续运转，并及时发现和解决生产过程中的问题', isActive: false, backgroundImage: areaImg1 },
        { p1: '能源行业', span: 'MES系统可以协助企业实现对能源生产过程的监控和管理。通过与现场自动化设备的连接,MES系统可以实时采集能源生产过程中的数据,并进行分析和处理', isActive: false, backgroundImage: areaImg2 },
        { p1: '汽车行业', span: 'MES系统可以帮助企业实现对生产过程的实时监控和质量控制。支持零部件的追溯和质量报告的生成,提供对生产过程的可视化管理和质量指标的跟踪，确保汽车的生产效率和质量，满足汽车行业的质量管理要求', isActive: false, backgroundImage: areaImg3 },
        { p1: '食品行业', span: 'MES系统可以帮助企业实现对生产过程的可追溯和质量控制。它可以通过自动化数据采集和实时监控,支持产品批次的溯源和召回，提高食品安全管理的能力', isActive: false, backgroundImage: areaImg4 }
      ],
    }
  },
  methods: {
    Scale() {
      if (document.body.clientWidth < 1200) {
        this.oneWidth.width = 1200;
        this.oneWidth.height = 250;

        this.twoWidth.width = 1200;
        // this.twoWidth.height = 295

        this.threeWidth.width = 1200;
        // this.threeWidth.height = 384

        this.fourWidth.width = 1200;
        // this.fourWidth.height = 503

        this.fiveWidth.width = 1200;
        // this.fiveWidth.height = 1251

        this.sixWidth.width = 1200;
        this.sixWidth.height = 333
      } else {
        this.oneWidth.height = 0.21 * document.body.clientWidth;
        this.oneWidth.width = document.body.clientWidth;

        this.twoWidth.height = 0.25 * document.body.clientWidth;
        // this.twoWidth.width = document.body.clientWidth;

        // this.threeWidth.height = 0.32 * document.body.clientWidth;
        this.threeWidth.width = document.body.clientWidth;

        // this.fourWidth.height = 0.42 * document.body.clientWidth;
        this.fourWidth.width = document.body.clientWidth;

        // this.fiveWidth.height = 1.04 * document.body.clientWidth;
        this.fiveWidth.width = document.body.clientWidth;

        this.sixWidth.height = 0.28 * document.body.clientWidth;
        this.sixWidth.width = document.body.clientWidth;
      }
    },

    /*锁定选定的盒子判断他是否选定 */
    // divClick(index) {
    //   /** 当 “点击” 一个“已被点击”的div时 使该div恢复默认色 */
    //   // 遍历info数组中的每个对象
    //   for (let i of this.info) {
    //     // 判断当前对象是否和传入的索引index对应 如果是，则取反
    //     if (index === this.info.indexOf(i)) {
    //       i.isActive = !i.isActive
    //     } else {
    //       i.isActive = false;
    //     }
    //   }
    // },

    // 鼠标滑过时，显示背景图片和修改文字颜色
    mouseOver(index) {
      this.info[index].isActive = true;
    },

    // 鼠标离开时则恢复默认
    mouseLeave(index) {
      this.info[index].isActive = false;
    },


    /*给选定的盒子修改样式 */
    colorDiv(index) {
      if (this.info[index].isActive) {
        return {
          backgroundImage: `url(${this.info[index].backgroundImage})`,
          color: 'white'
        };
      } else {
        return {
          backgroundImage: 'none',
          '.p1 p': {
            color: '#333333;'
          },
          '.span span': {
            color: 'black'
          }
        }
      }
    },

    /*
    实际案例部分轮播切换
     */
    showSlide(index) {
      this.carImage1 = this.carFieldImgs[index];
      this.carText1 = this.carFieldTexts[index];
      this.carImage2 = this.carFieldImgs[++index % 3];
      this.carText2 = this.carFieldTexts[index % 3];
      this.carText3 = this.carFieldTexts[++index % 3];
      this.carImage3 = this.carFieldImgs[index % 3];
      this.currentIndex = (++index) % 3;
    },
    //居中显示
    centerSlide(index) {
      this.currentIndex = index;
      this.showSlide(index);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.carFieldImgs.length;
      this.currentIndex = (this.currentIndex + 1) % this.carFieldTexts.length;
      this.showSlide(this.currentIndex);
    },

    // 定时器 自动轮播
    startTime() {
      this.intervalId = setInterval(this.nextSlide, 5000);
    },

    chanageImg(index) {
      /* 重置计时器 */
      clearInterval(this.intervalId);
      this.currentIndex = index;
      //  // 将被点击的图片居中显示
      // this.centerSlide(this.currentIndex);

      //计算需要移动的步数
      let steps = index - this.currentIndex;

      // 如果步数大于轮播图一半的长度，向前滚动到达目标图片
      if (Math.abs(steps) > this.slides.length / 2) {
        steps = steps > 0 ? steps - 3 : steps + 3;
      }

      // 更新 currentIndex，使目标图片移动到中间位置
      this.currentIndex = (this.currentIndex + steps + 3) % 3;

      // 将目标图片居中显示
      this.centerSlide(this.currentIndex);

      //重新启动定时器
      this.startTime();
    },

    // 主要功能部分切换功能
    changeContent() {
      // 在页面加载完成后先执行这个
      // document.addEventListener("DOMContentLoaded",function(){
      //   // 获取第一个菜单项
      //   var firstMenuItem = document.querySelector('.menu-router');
      //   // 让他点击第一个菜单项
      //   firstMenuItem.click();
      // })

      // 获取元素
      // var tab_list = document.querySelector('.menu');
      var list = document.querySelectorAll('.menu-router');
      var item = document.querySelectorAll('.contentItem');

      // item[0].style.display='block';
      // 获取第一个导航项
      var firstNavItem = document.querySelector('.menu-router');
      //获取第一个导航项对应的内容
      var firstContentItem = document.querySelector('.contentItem');
      // 给导航项添加样式
      firstNavItem.classList.add('menuActive');
      // 让第一导航项对应内容默认显示
      firstContentItem.style.display = 'block';

      // 分别绑定点击事件
      for (var i = 0; i < list.length; i++) {
        // 在这里创建属性，添加索引
        list[i].setAttribute('index', i);
        list[i].onclick = function () {
          // 1.第一大步骤，先解决导航栏的颜色切换
          // 所有的 menu-router清除class类
          for (var i = 0; i < list.length; i++) {
            list[i].classList.remove('menuActive');
          }
          // 自己添加上这个类
          this.classList.add('menuActive');
          // 2.第二大步骤：下面模块跟着选项卡一一匹配
          // 给上面的tab_list里面所有的li添加索引
          var index = this.getAttribute('index');
          console.log(index);
          // 先全部隐藏
          for (var j = 0; j < item.length; j++) {
            item[j].style.display = 'none';
          }
          // 当前的显示
          item[index].style.display = 'block';
        }
      }
    },


  },
}

</script>


<style>
.about {
  width: 100%;
  height: auto;
}

.aboutNav{
  width: 100%;
    height: 90px;
    background-color: #000;
}

.three {
  /* width: 1202px;
	height: 663px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  background-color: #f6f6f6;
}

.font3 {
  width: 100%;
  height: 124px;
}

.font3 p {
  position: relative;
  top: 100px;
  font-family: MySourceHanSansSC-Heavy;
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 2px;
  color: #333333;
  white-space: nowrap;
  margin: 0 auto;
}

.box {
  position: relative;
  top: 0;
  width: 1200px;
  height: 390px;
  display: flex;
  justify-content: space-between;
  /*间隔均匀排列*/
  justify-content: center;
  /* margin: 0 auto; */
  margin: 0;
}

.four {
  background-image: url(' ../assets/mainFunctions.jpg ');
  background-size: cover;
}
.font4 {
  width: 100%;
  height: 120px;
  display: flex;
  white-space: nowrap;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  /*水平居中 */
  /*align-items: center; 垂直居中*/
  position: relative;
  /*相对定位 */
}

.font4 p {
  font-family: SourceHanSansSC-Heavsy;
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 4px;
  color: #ffffff;
  margin: 0;
  position: absolute;
  /*绝对定位 */
  bottom: 3%;
  /*让他的下边距为0 */
  /* margin-top: 50%; */
  /* margin-bottom: 50%; */
}


.multi-purpose {
  /* top: 9%; */

}

.all {
  background-color: white;
  margin: 0 auto;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid rgb(112, 112, 112); */
  border-bottom: solid 1px #d3d3d3;
  border-bottom-width: 50%;
}
.menu-router {
  cursor: pointer;
  /* writing-mode: vertical-lr; */
  /*将文字垂直排列 */
  white-space: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  line-height: 1;
}


.menu-content {
  /* margin-top: 5%; */
  margin-left: 5%;
  /* margin-right: 0; */
  display: flex;
  justify-content: space-between;
}

.contentItem {
  display: none;
}

.menuActive {
  /* background-color: green; */
  border-bottom: solid 2px #ff9000;
  color: #fff;
}



.AboutOneHardwareSupport {
  /* margin: 0 auto; */
  /* display: flex;
 justify-content: center; */
  /* margin-top: 4%; */
  background-color: #f6f6f6;
}

.supportFont5 {
  display: flex;
  position: relative;
  justify-content: center;

}

.supportFont5 h2 {
  /* width: 9%;
  height: 23%; */
  font-family: MySourceHanSansSC-Heavy;
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 2px;
  color: #333333;
  margin: 0;
  white-space: nowrap;
  position: absolute;
  top: 100px;
}

.supportOnly {
  display: flex;
  flex-direction: column;
  /*让整个容器垂直排列 */
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  /* margin: 0 auto; */
  /* margin-left: 17%;
  margin-right: 11%; */
  margin: 0 auto;
}

.tool {
  /* display: flex;
  justify-content: space-between; */
  /*间隔均匀排列*/
  /* justify-content: center; */
}

.topTool {
  display: flex;
  justify-content: space-between;
}

.bottomTool {
  display: flex;
  justify-content: space-between;
}

.top-box {
  width: 32%;
  height: 95%;
  /* border-radius: 8px; */
  /* border: solid 2px #d3d3d3; */
  /* opacity: 0.2; */
  /*加阴影 调整阴影的弧度和颜色 */
}

.bottom-box {
  width: 32%;
  height: 100%;
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); */
  /* box-shadow: 0px 5px 9px 1px 
		rgba(2, 4, 11, 0.5); */
  /* border-radius: 8px;
  border: solid 2px #d3d3d3; */
  /*加阴影 调整阴影的弧度和颜色 */
}


.apply {
  display: flex;
  position: relative;
  justify-content: center;
}

.total {}

.try {
  position: relative;
  display: flex; 
   justify-content: center;
}

/* 实际案例标题 */
.font6 h2 {
  font-family: MySourceHanSansSC-Heavy;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 4px;
  color: #333333;
  margin: 0;
}


/* -----------------1201-1366之间---------------- */
@media (min-width: 1201px) and (max-width:1366px) {

  /* 实际案例标题 */
  .font6 h2 {
    font-size: 25px;
  }

}


/* -----------------1367-1600之间---------------- */
@media (min-width: 1367px) and (max-width:1600px) {
  /* 实际案例标题 */
  .font6 h2 {
    font-size: 30px;
  }
}


/* -----------------1600以上---------------- */
@media (min-width: 1600px) {
  /* 实际案例标题 */
  .font6 h2 {
    font-size: 40px;
  }


}</style>