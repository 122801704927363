import 'core-js/stable';
import "babel-polyfill"
// import '../node_modules/core-js/stable'
// import '../node_modules/babel-polyfill'
// import '../node_modules/nodemailer'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css'
 
Vue.use(ElementUI)


import './assets/font/font.css'

Vue.config.productionTip = false

Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 加入百度统计
// router.beforeEach((to, from, next) => {
//   if (to.path) {
//     if (window._hmt) {
//       window._hmt.push(['_trackPageview', '/#' + to.fullPath])
//     }
//   }
//   next()
// })