<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 产品概述 -->
        <ProjectMilitaryOverview :overViewText='overViewText' :isMilitary="true" />
        <!-- 应用领域 -->
        <div class="projectMilitaryApply">
            <p class="title">应用领域</p>
            <div class="projectMilitaryApplyBox">
                <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
            </div>
        </div>
        <!-- 产品特色 -->
        <div class="projectMilitaryFeaturesDiv">
            <p class="title">产品特色</p>
            <div class="projectMilitaryFeaturesArea">
                <ProjectMilitaryFeaturesRight :item="item1" :changeWidth="2" />
                <ProjectMilitaryFeaturesLeft :item="item2" :changeWidth="1" />
                <ProjectMilitaryFeaturesRight :item="item3" />
                <ProjectMilitaryFeaturesLeft :item="item4" />
            </div>
        </div>
        <!-- 其他案例 -->
        <!-- <Others :index="-1" :currentType="0" /> -->
        <div style="width: 100%; height: 100px; background-color: #f6f6f6;" />
        <!-- 联系我们 -->
        <ContactUs :pageType="0" />
    </div>
</template>

<script>
import areaImg1 from '../assets/examine/areaImg1.webp'
import areaImg2 from '../assets/examine/areaImg2.webp'
import areaImg3 from '../assets/examine/areaImg3.webp'
import areaImg4 from '../assets/examine/areaImg4.webp'
import featureImg1 from '../assets/examine/featureImg1.png'
import featureImg2 from '../assets/examine/featureImg2.png'
import featureImg3 from '../assets/examine/featureImg3.png'
import featureImg4 from '../assets/examine/featureImg4.png'

import CaseTop from '../components/CaseTop.vue'
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
import ProjectMilitaryFeaturesRight from '../components/ProjectMilitaryFeaturesRight.vue'
import ProjectMilitaryFeaturesLeft from '../components/ProjectMilitaryFeaturesLeft.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'ProductExamineView',
    components: {
        CaseTop,
        ProjectMilitaryOverview,
        AboutOneApplicationArea,
        ProjectMilitaryFeaturesRight,
        ProjectMilitaryFeaturesLeft,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 0,
            topTitle: '考核软件',
            // 产品概述
            overViewText: '考核系统是一种用于评估和管理员工考核的软件工具。它可以建立一个科学、公正、透明的考核体系，并提供相应的功能和服务，包含评分与评价、目标设定与跟踪、数据统计与分析、反馈与沟通、档案管理等功能。它能够提高员工绩效、激励员工动力、改善组织管理、促进人才发展等，根据不同用户需求制定对应的考核系统，配备特定功能',
            // 应用领域
            info: [
                { p1: '学校教育评估', span: '在教育领域，考核系统可以帮助学校评估教师和学生的表现，并提供改进建议。通过收集学生成绩、教师评价和家长反馈等数据，学校可以全面了解教师和学生的绩效情况，针对性地制定教学计划和课程设置，以提高学生学业成绩和教师教育质量', isActive: false, backgroundImage: areaImg1 },
                { p1: '企业绩效管理', span: '在企业中，考核系统可以帮助管理者评估员工的工作表现，为提升绩效提供依据。通过设定KPIs，管理者可以跟踪每个员工的工作进展，并根据考核结果制定奖励和激励计划。这可以激励员工提升工作质量和效率，同时也促进了整个组织的目标实现', isActive: false, backgroundImage: areaImg2 },
                { p1: '政府绩效评估', span: '政府机构也可以利用考核系统来评估其绩效和目标实现情况。例如，监管机构可以通过跟踪关键指标来评估企业的合规情况，税务机关可以通过考核系统评估纳税人的遵纪守法情况。这有助于提高政府机构的监管效率，推动社会稳定和经济发展', isActive: false, backgroundImage: areaImg3 },
                { p1: '在线培训', span: '在线考试系统可以用于在线培训课程的评估和考核。培训机构可以将课程资料和考试内容上传至在线考试系统，学员可以在线学习、做题和参加考试。系统会自动记录学员的学习情况和考试成绩，为培训机构提供全面的数据分析报告，帮助其更好地了解学员的学习状况和提高教学质量', isActive: false, backgroundImage: areaImg4 }
            ],
            // 产品特色
            item1: {title: '导入试题', text: '使用模板导入、批量新增或单个新增试题的方式导入试题，可以导入单选、多选、判断、填空、问答、组合题等等多种题型试题。可以创建试题分类进行管理，也能方便查找试题', img: featureImg1},
            item2: {title: '智能阅卷', text: '考生交卷后，考试中设置好答案和分数的客观题系统会自动阅卷打分，只需要人工对主观题进行评卷，可以直接在系统后台去评卷，也可以发送评卷邀请让他人协助评卷，分散阅卷工作量', img: featureImg2},
            item3: {title: '设计试卷', text: '随机组卷和人工组卷两种形式；再设置考试时间、答题时间、答题设备、考试次数、试卷、试题、防作弊等考试相关配置，防止考生在考试过程中的作弊行为，确保线上考试公平公正', img: featureImg3},
            item4: {title: '考题形式', text: '可以是理论题，也可以是实操题，根据不同的用户以及系统可以嵌入考试或者根据设置的扣分标准、操作步骤以及参数的偏差值等进行扣分', img: featureImg4},
        }
    },
}
</script>