<template>
      <p style="width: 100%;height: 32%;" class="menuMsg">{{menuItem.menuMsg}}</p>
</template>

<script>
export default {
    name: 'AboutOneMenu',
    props:{
      menuItem:{
            type: Object,
            required: true,
        }
    }
}
</script>

<style>
/* .menuDiv{
    width: 100%; 
    height: 100%; 
} */

.menuMsg {
  font-family: MySourceHanSansSC-Regular;
  font-size: 16px;
  line-height: 30px; 
   letter-spacing: 2px;
  color: #333333;
  margin: 0;
  position: absolute;
  justify-content: center;
}

</style>