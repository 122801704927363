<template>
    <div class="info">
        <div style="width: 56%; height: 100%;">
          <div style="display: flex; height: 47%;">
            <div class="infoIcon" style="margin-left: 0px;">
              <img class="infoImg" src="../assets/home/infoIcon1.png" alt="简介小图标1" style="width: 24%; height: 34.1%;"/>
              <p class="iconText">高新技术企业</p>
            </div>
            <div class="infoIcon">
              <img class="infoImg" src="../assets/home/infoIcon2.png" alt="简介小图标2" style="width: 22.4%; height: 33.6%;"/>
              <p class="iconText">保密资质</p>
            </div>
            <div class="infoIcon">
              <img class="infoImg" src="../assets/home/infoIcon3.png" alt="简介小图标3" style="width: 34.2%; height: 34.1%;"/>
              <p class="iconText">质量体系认证</p>
            </div>
          </div>
          <div style="display: flex; margin-top: 3.5%; height: 47%;">
            <div class="infoIcon" style="margin-left: 0px;">
              <img class="infoImg" src="../assets/home/20+.png" alt="简介小图标20+" style="width: 36.5%; height: 19.8%;"/>
              <!-- <p class="iconNum">20<sup>+</sup></p> -->
              <p class="iconText">解决方案</p>
            </div>
            <div class="infoIcon">
              <img class="infoImg" src="../assets/home/100+.png" alt="简介小图标100+" style="width: 50.2%; height: 19.8%;"/>
              <!-- <p class="iconNum">100<sup>+</sup></p> -->
              <p class="iconText">企业客户</p>
            </div>
            <div class="infoIcon">
              <img class="infoImg" src="../assets/home/50+.png" alt="简介小图标50+" style="width: 36.9%; height: 19.8%;"/>
              <!-- <p class="iconNum">50<sup>+</sup></p> -->
              <p class="iconText">软件著作权</p>
            </div>
          </div>
        </div>

        <div style="width: 37%; position: relative; z-index: 10;">
          <p class="title" style="position: absolute; top: 13px; left: 15%;">威迪欧</p>
          <div style="height: auto; overflow: hidden; position: absolute; top: 23%; left: 15%; margin-left: 1px; white-space: nowrap;">
            <p class="textMsg">大连威迪欧信息技术有限公司，主要业务方向为仿真系统和模拟训</p>
            <p class="textMsg">练系统的研制、工控领域相关的解决方案以及软件服务，公司坚持</p>
            <p class="textMsg">“创新、高效、合作”的企业精神，秉承科技为先、以人为本的管</p>
            <p class="textMsg">理理念，为每一位员工提供施展才华、发挥潜能和实现自身价值的</p>
            <p class="textMsg">舞台，为客户提供最优质的解决方案。</p>
          </div>
          <div style="position: absolute; top: 65.8%; left: 15.5%; text-align: left;">
            <router-link to="/aboutUs" class="textMsg" style="color: #1b297b; text-decoration: none;" >了解我们  →</router-link>
          </div>
        </div>
      </div>
</template>

<script>
export default {
    name: 'HomeCompanyIntroduction',
}
</script>

<style>
  .info{
    position: absolute; 
    left: 50%; 
    transform: translateX( -50% );
    /* margin-top: 100px; */
    top: 19%;
    width: 79%;
    height: 93%;
    display: flex;
    justify-content: space-between;
    z-index: 15;
  }
  /* 基础信息图标部分 */
  .infoIcon{
    position: relative;
    width: 31%; 
    height: 100%; 
    background-color: #fff; 
    border-radius: 8px; 
    margin-left: 3.5%;
    text-align: left;
  }
  .infoImg{
    position: absolute; 
    left: 16%;
    top: 14.2%;
    /* width: 34%; 
    height: 34%; */
  }
  .iconText{
    position: absolute; 
    top: 71.1%;
    left: 16%;
    margin: 0;
    font-family: MySourceHanSansSC-Regular;
    color: #333333;
  }
  /*分辨率小于1200*/
@media screen and (max-width: 1200px) {
  .iconText{
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
/*分辨率在1200-1360之间*/
@media screen and (min-width: 1200px) {
  .iconText{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
/*分辨率在1360-1600之间*/
@media screen and (min-width: 1360px) {
  .iconText{
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 1.2px;
  }
}
/*分辨率大于1600*/
@media screen and (min-width: 1600px) {
  .iconText{
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 1.2px;
  }
}
</style>