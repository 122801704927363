<template>
    <div class="caseTop" :style="{width:this.screenWidth+'px', height:this.top+'px'}">
        <img :src="image" style="width: 100%; height: 100%;">
        <p class="topTitle">{{ topTitle }}</p>
    </div>
</template>

<script>
import productBackground from '../assets/banner.webp'
import caseBackground from '../assets/case/topBackground.webp'

export default {
    name: 'CaseTop',
    data() {
        return {
            screenWidth: '1920',
            top: '400',
            image: '',
        }
    },
    props:{
        topTitle:{
            type: String,
            required: true,
        },
        topType:{
            type: Number,
            required: true,
        },
    },
    created() {
        this.init()
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.top = 250;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.top = document.body.clientWidth * 0.208;
            }
        },
        init(){
            switch (this.topType){
                case 0:
                    this.image = productBackground;
                    break;
                case 1:
                    this.image = caseBackground;
                    break;
            }
        }
    },
}
</script>

<style>   
.caseTop{
    position: relative;
    background-size: cover;
    background-position: center;
}
.caseTop p{
    position: absolute; 
    left: 50%; 
    /* top: 47.5%; 
    transform: translateX( -50% ); */
    top: 50%;
    transform: translate( -50%,-50% ); 
    color: #fff;
}
</style>