<template>
    <div style="width: 100%; height: 100%;">
        <div class="aboutUsIntroductionInterpunction">
            <img class="aboutUsInterpunctionImg" src="../assets/aboutUs/interpunction.png">
        </div>
        <div class="aboutUsIntroductionMsg">
            <div style="position: relative; width: 100%; height: 100%;">
                <div class="aboutUsIntroductionMsgText">
                    <p class="textTitle" style="color: #333;">大连威迪欧信息技术有限公司</p>
                    <p class="textMsg" style="color: #333; position: absolute; top: 15.3%;">
                        成立于2012年2月，主要业务方向为软件服务，公司提供从需求分析、产品设计、产品实现、产品测试、产品部署实施以及后期维护等一站式全流程服务。客户主要分布在军工、教育、制造、旅游、医疗等各个领域，公司在坚持技术创新的基础上，狠抓质量管理，不断提高服务水平和技术能力，经过多年的发展和技术积累，公司逐步建立了以软件仿真和模拟、信息化管理、多媒体处理为优势技术的技术体系，同时培养出一批行业中的顶尖人才，拥有一支专业的服务团队，为客户提供最优质的解决方案
                    </p>
                </div>
                
            </div>
        </div>
        <div class="aboutUsIntroductionBuild" />
        <div class="aboutUsIntroductionColor" />
    </div>
</template>

<script>
export default {
    name: 'AboutUsIntroduction',
}
</script>

<style>
.aboutUsIntroductionInterpunction{
    position: absolute; 
    top: 18.5%;
    left: 24%;
    height: 13%;
    width: 6.25%;
	background-color: #233881;
	border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.aboutUsInterpunctionImg{
    width: 56.7%; 
    height: 47.5%;
}
.aboutUsIntroductionMsg{
    position: absolute; 
    top: 25%;
    left: 18.75%;
    width: 31%;
    height: 60%;
	background-color: #ffffff;
	box-shadow: 0px 4px 8px 0px rgba(2, 4, 11, 0.35);
	border-radius: 8px;
    z-index: 8;
}
.aboutUsIntroductionMsgText{
    position: absolute;
    width: 85%;
    height: 82%;
    top: 20%;
    left: 50%; 
    transform: translateX( -50% );
}
.aboutUsIntroductionBuild{
    position: absolute; 
    top: 10.3%;
    left: 37.2%;
    width: 62.8%;
    height: 89.7%;
    background-image: url('../assets/aboutUs/build.webp');
    background-size: cover;
    background-position: center;
    z-index: 5;
}
.aboutUsIntroductionColor{
    position: absolute; 
    bottom: 0;
    width: 100%;
    height: 34%;
    background-color: #7495e9;
    z-index: 3;
}
</style>