<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 产品概述 -->
        <ProjectMilitaryOverview :overViewText='overViewText' :isMilitary="true" />
        <!-- 应用领域 -->
        <div class="projectMilitaryApply">
            <p class="title">应用领域</p>
            <div class="projectMilitaryApplyBox">
                <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
            </div>
        </div>
        <!-- 产品特色 -->
        <div class="projectMilitaryFeaturesDiv" style="height: 1490px;">
            <p class="title">产品特色</p>
            <div class="projectMilitaryFeaturesArea" style="height: 1300px;">
                <ProjectMilitaryFeaturesRight :item="item1" :changeWidth="1" />
                <ProjectMilitaryFeaturesLeft :item="item2" />
                <ProjectMilitaryFeaturesRight :item="item3" />
            </div>
        </div>
        <!-- 其他案例 -->
        <Others :index="-1" :currentType="0" :arrayIndex="3" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import areaImg1 from '../assets/wavePrediction/areaImg1.webp'
import areaImg2 from '../assets/wavePrediction/areaImg2.webp'
import areaImg3 from '../assets/wavePrediction/areaImg3.webp'
import areaImg4 from '../assets/wavePrediction/areaImg4.webp'
import featureImg1 from '../assets/wavePrediction/featureImg1.png'
import featureImg2 from '../assets/wavePrediction/featureImg2.png'
import featureImg3 from '../assets/wavePrediction/featureImg3.png'

import CaseTop from '../components/CaseTop.vue'
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
import ProjectMilitaryFeaturesRight from '../components/ProjectMilitaryFeaturesRight.vue'
import ProjectMilitaryFeaturesLeft from '../components/ProjectMilitaryFeaturesLeft.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'ProductWavePredictionView',
    components: {
        CaseTop,
        ProjectMilitaryOverview,
        AboutOneApplicationArea,
        ProjectMilitaryFeaturesRight,
        ProjectMilitaryFeaturesLeft,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 0,
            topTitle: '海浪预报软件',
            // 产品概述
            overViewText: '海浪预报技术，是指根据海面环境特征值的历史资料和实时观测结果，运用专门设计的物理模型和数学模型，对一定海域未来时间内的风力要素、流向、流速、自然变化及其可能造成的影响，以一定的文字、图表、声像等形式进行描述和发布，主要用于海浪信息数据维护，采用非线性薛定谔方程、BP神经网络、极限学习机等不同方法进行海浪预报。海浪数据采用Mysql数据库进行维护管理，使用MFC类库进行界面编程，采用MATLAB_VS混合编程实现算法实现与显示',
            // 应用领域
            info: [
                { p1: '海洋能源', span: '海浪数据的分析可以帮助确定最佳的海洋能源开发区域，评估潮汐、波浪能资源的潜力，并为海洋能源设施的设计和运营提供支持', isActive: false, backgroundImage: areaImg1 },
                { p1: '海洋气象', span: '通过监测和分析海浪数据，可以预测风暴潮、海浪高度等气象现象，为海洋交通、渔业、海上作业等提供准确的气象预报信息，保障人员和设备的安全', isActive: false, backgroundImage: areaImg2 },
                { p1: '海洋交通', span: '通过分析海浪数据，可以了解海洋波浪的频率、高度、速度等参数，为船舶设计提供准确的波浪载荷信息，确保船舶在波浪环境下的安全性能。同时，海洋波浪数据也可以为航行计划提供参考，指导船舶选择最佳航线，提高航行效率', isActive: false, backgroundImage: areaImg3 },
                { p1: '海洋工程', span: '海洋结构物如海上风电场、港口码头、海岸防护工程等都需要考虑海浪环境的影响。通过分析海浪数据，可以评估海洋结构物的抗波性能，确定结构物的设计参数，确保其在恶劣海洋环境下的安全运行', isActive: false, backgroundImage: areaImg4 }
            ],
            // 产品特色
            item1: {title: '海浪信息数据维护及管理', text: '可以输入海浪数据，或者对接第三方传感器，将探测的数据读取到系统，并且将数据进行存储、调用、计算以及解方程等方式得出需要的结果', img: featureImg1},
            item2: {title: '在界面中调用MATLAB方法\n对各个算法进行实现', text: '采用MATLAB_VS混合编程实现算法，以一定的文字、图表、声像等形式进行描述和发布，更直观的展示观测的结果', img: featureImg2},
            item3: {title: '实现算法结果显示及海浪预报', text: '使用MFC类库进行界面编程，采用非线性薛定谔方程、BP神经网络、极限学习机等不同方法进行海浪预报', img: featureImg3},
        }
    },
}
</script>