<template>
    <div class="caseFunctionIcon">
        <img :class="caseFunctionIconImgClass" :src="iconItem.icon" >
        <div :class="caseFunctionIconDecorativeLineClass"/>
        <div :class="caseFunctionTextDivClass" :style="{width:this.width+'%'}">
            <p class="textMsg">{{iconItem.title}}</p>
            <p class="smallText">{{iconItem.text}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CaseMainFunctionIconDiv',
    props:{
        iconItem:{
            type: Object,
            required: true,
        },
        isLong:{
            type: Boolean,
            default: false,
        }
    },
    data() {
      return {
        caseFunctionIconDecorativeLineClass: 'caseFunctionIconDecorativeLine',
        caseFunctionTextDivClass: 'caseFunctionTextDiv',
        caseFunctionIconImgClass: 'caseFunctionIconImg',
        width: 84,
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        if(this.iconItem.iconDivType == 'high'){
          this.caseFunctionIconDecorativeLineClass = 'caseFunctionIconDecorativeHighLine';
          this.caseFunctionTextDivClass = 'caseFunctionTextHighDiv';
          this.caseFunctionIconImgClass = 'caseFunctionIconHighImg';
          if( !this.isLong ){
            this.width = 78.6;
          }
        }else if( !this.isLong ){
          this.width = 82;
        }
      },
    },
}
</script>

<style>
.caseFunctionIcon{
  width: 23%; 
  height: 100%; 
  position: relative;
  background-color: #fff;
  border: solid 1px #fff;
}
.caseFunctionIcon:hover{
  box-shadow: 0px 2px 5px 0px rgba(2, 4, 11, 0.5);
	border: solid 1px #333;
}
.caseFunctionIconDecorativeHighLine{
  position: absolute; 
  width: 78.6%; 
  height: 1px; 
  background-color: #d7d7d7; 
  top: 30%; 
  left: 50%; 
  transform: translateX( -50% );
}
.caseFunctionIconDecorativeLine{
  position: absolute; 
  width: 78.6%; 
  height: 1px; 
  background-color: #d7d7d7; 
  top: 40.9%; 
  left: 50%; 
  transform: translateX( -50% );
}
.caseFunctionIcon:hover .caseFunctionIconDecorativeLine,
.caseFunctionIcon:hover .caseFunctionIconDecorativeHighLine{
  height: 2px; 
  background-color: #333; 
}
.caseFunctionIconHighImg{
  position: absolute; 
  left: 11.2%; 
  top: 7.5%;
}
.caseFunctionIconImg{
  position: absolute; 
  left: 11.1%; 
  top: 9.3%;
}
.caseFunctionTextHighDiv{
  position: absolute; 
  /* width: 78.6%; 84% */
  height: 45%; 
  top: 37.3%; 
  left: 50%; 
  transform: translateX( -50% );
}
.caseFunctionTextDiv{
  position: absolute; 
  /* width: 82%; 84% */
  height: 45%; 
  top: 51.2%; 
  left: 51%; 
  transform: translateX( -50% );
}
.caseFunctionTextDiv .textMsg,.caseFunctionTextHighDiv .textMsg{
  color: #333; 
  font-family: MySourceHanSansSC-Bold;
  white-space: pre-line;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 2px;
}
.smallText{
  font-family: MySourceHanSansSC-Regular;
	color: #333333;
  text-align: left;
  margin: 0;
  white-space: pre-line;
  line-break: anywhere;
  font-size: 14px;
  letter-spacing: 1px;
}
</style>