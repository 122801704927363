<template>
    <div class="recruitsIcon">
        <div class="recruits-img">
        <img :src="recruitsItem.icon" :style="{width:recruitsItem.iconWidth+'%', height:recruitsItem.iconHeight+'%',marginTop:recruitsItem.iconTop+'%',marginLeft:recruitsItem.iconLeft+'%'}" >
        <!-- ,marginTop:recruitsItem.iconTop+'%',marginLeft:recruitsItem.iconLeft+'%' -->
        </div>

        <div class="recruitsContent">
        <div class="recruitsText">
            <p>{{recruitsItem.title}}</p>
        </div>

        <div class="recruitsTitle">
            <p>{{recruitsItem.text}}</p>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name: 'AboutOnlineRecruits',
    props:{
        recruitsItem:{
            type: Object,
            required: true,
        }
    }
}
</script>

<style>
.recruitsIcon{
    width:100%; 
    height: 100%; 
    display: flex;
    /* align-items: flex-start; */
}

.recruits-img{
    position: relative; 
    height: 100%;
    width: 37%;
    flex: 0 0 auto; /* 不伸缩，固定宽度 */
}

.recruits-img img{
    position: absolute; 
    left: 0; 
    top: 0;
}

.recruitsContent{
    display: flex;
    flex-direction: column; /*将文本内容垂直排列 */
    justify-content: center; /*垂直居中对齐 */
    width: 62%; 
    height: 100%; 
    flex: 0 0 auto;
}

.recruitsText{
    display: flex;
    position: relative;
    width: 100%; 
    height: 28%; 
}
.recruitsText p{
    position: absolute;
    margin: 0;
    left: 0;
    bottom: 0;
	font-family: MySourceHanSansSC-Heavy;
	font-size: 24px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 30px;
	letter-spacing: 2px;
	color: #333333;
    white-space: nowrap;
}

.recruitsTitle{
    width: 91%; 
    height: 70%; 
    display: flex;
    position: relative;
}

.recruitsTitle p{
    position: absolute;
    margin: 0;
    left: 0;
    top: 19%;
    font-family: MySourceHanSansSC-Regular;
	font-size: 16px;
    text-align: justify;
    /*文字两端对齐 */
	line-height: 27px;
	letter-spacing: 2px;
	color: #333333;
}
</style>