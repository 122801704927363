<template>
    <div>
        <!-- <div class="caseNav" /> -->
        <!-- 顶部标题 -->
        <CaseTop :topTitle="topTitle" :topType="topType" />
        <!-- 项目概况 -->
        <CasePojectOverview :pojectOverviewMsg="pojectOverviewMsg" />
        <!-- 主要功能 -->
        <div class="caseMainFunction" :style="{width:this.screenWidth+'px'}">
            <div class="caseMainFunctionLargeDiv">
                <div class="caseMainFunctionComputer">
                    <p class="title" style="color: #333;">主要功能</p>
                    <img src="../assets/case/bailingniao/computer.png">
                </div>
                <div class="caseMainFunctionIcon">
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem1" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem2" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem3" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem4" :isLong="true" />
                    </div>
                    <div class="caseMainFunctionIconLine">
                        <CaseMainFunctionIconDiv :iconItem="iconItem5" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem6" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem7" />
                        <CaseMainFunctionIconDiv :iconItem="iconItem8" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 项目图片 -->
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem1" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem2" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem3" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <div class="caseProjectPic" :style="{width:this.screenWidth+'px', height:this.projectPic+'px'}">
            <CaseProjectPic :picItem="picItem4" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem5" :caseProjectPicSize="caseProjectPicSize" />
            <CaseProjectPic :picItem="picItem6" :caseProjectPicSize="caseProjectPicSize2" />
        </div>
        <!-- 其他案例 -->
        <Others :index="index" :currentType="currentType" />
        <!-- 联系我们 -->
        <ContactUs :pageType="1" />
    </div>
</template>

<script>
import icon1 from '../assets/case/bailingniao/icon1.png'
import icon2 from '../assets/case/bailingniao/icon2.png'
import icon3 from '../assets/case/bailingniao/icon3.png'
import icon4 from '../assets/case/bailingniao/icon4.png'
import icon5 from '../assets/case/bailingniao/icon5.png'
import icon6 from '../assets/case/bailingniao/icon6.png'
import icon7 from '../assets/case/bailingniao/icon7.png'
import icon8 from '../assets/case/bailingniao/icon8.png'
import pic1 from '../assets/case/bailingniao/projectPic1.png'
import pic2 from '../assets/case/bailingniao/projectPic2.png'
import pic3 from '../assets/case/bailingniao/projectPic3.jpg'
import pic4 from '../assets/case/bailingniao/projectPic4.png'
import pic5 from '../assets/case/bailingniao/projectPic5.jpg'
import pic6 from '../assets/case/bailingniao/projectPic6.png'

import CaseTop from '../components/CaseTop.vue'
import CasePojectOverview from '../components/CasePojectOverview.vue'
import CaseMainFunctionIconDiv from '../components/CaseMainFunctionIconDiv.vue'
import CaseProjectPic from '../components/CaseProjectPic.vue'
import Others from '../components/Others.vue'
import ContactUs from '../components/contactUs.vue'

export default {
    name: 'CaseLarkView',
    components: {
        CaseTop,
        CasePojectOverview,
        CaseMainFunctionIconDiv,
        CaseProjectPic,
        Others,
        ContactUs
    },
    data() {
        return {
            topType: 1,
            screenWidth: '1920',
            mainFunctionLarge: '620',
            projectPic:'427',
            topTitle: '英语在线教育',
            pojectOverviewMsg: '“英语在线教育”拥有完善的教学体系，专业的师资团队，贴心的教学服务，真人外教口语教学，灵活的学习时间。课程体系包含一对一直播课、一对四直播课、公开课、少儿班课、幼儿班课等对种课程体系，在直播间内通过举手，提问，弹幕消息等功能实现学生与老师实时互动。教学服务包括课前预习资料、课程回顾、课后练习题、图书馆等，还可以了解全国各地节日习俗以及课外文化等故事',
            iconItem1: {title:'丰富的课程资源', text: '提供全面的课程，涵盖不同年级和学习阶段的内容', icon: icon1},
            iconItem2: {title:'实时互动学习', text: '老师和学生通过直播间进行实时互动，提高学习效果和参与度', icon: icon2},
            iconItem3: {title:'精英师资团队', text: '专业的师资团队，采取真人外教口语教学', icon: icon3},
            iconItem4: {title:'多样化学习工具', text: '提供多种学习工具，如在线教材、习题库、学习视频等', icon: icon4},
            iconItem5: {title:'实时答疑辅导', text: '随时向老师提问，获取即时的答疑和辅导支持', icon: icon5},
            iconItem6: {title:'学习进度跟踪评估', text: '记录学生的学习进度和表现，提供学习报告和评估结果', icon: icon6},
            iconItem7: {title:'灵活学习方式', text: '可以随时随地进行学习，根据自己的时间和地点安排学习', icon: icon7},
            iconItem8: {title:'多样的课外读物', text: '在线图书馆，各种节日习俗以及课外文化故事', icon: icon8},
            picItem1: {title:'在线上课', pic: pic1},
            picItem2: {title:'灵活的上课时间', pic: pic2},
            picItem3: {title:'专业的老师', pic: pic3},
            picItem4: {title:'丰富的课程资源', pic: pic4},
            picItem5: {title:'课堂形式', pic: pic5},
            picItem6: {title:'在线图书馆', pic: pic6},
            caseProjectPicSize: {width: '33.3', height: '100'},
            caseProjectPicSize2: {width: '33.4', height: '100'},
            index: 0,
            currentType: 1,
        }
    },
    mounted() {
        this.calcRate()
        window.addEventListener('resize', this.calcRate)
    },
    methods: {
        calcRate () {
            if(document.body.clientWidth <= 1200){
                this.screenWidth = 1200;
                this.mainFunctionLarge = 387.5;
                this.projectPic = 267;
            }else{
                this.screenWidth = document.body.clientWidth;
                this.mainFunctionLarge = document.body.clientWidth * 0.322;
                this.projectPic = document.body.clientWidth * 0.222;
            }
        },
    },
}
</script>