<template>
  <div id="app" ref="systemForm" @scroll="setScrollBar()">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <NavigateView class="nav" />
    <router-view class="rv"/>
  </div>
</template>

<script>
  import NavigateView from './components/navigation.vue'
  export default {
      components: {
      NavigateView
    },
    methods: {
      setScrollBar(){
        this.$nextTick(function(){
          let div = document.querySelector("#app");
          div.style.heiget = document.body.clientHeight - 15 + 'px';
        })
      }
    },
  }
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.nav{
  /* 一直将导航栏置于最顶层 */
  z-index: 999;
}
.rv{
  z-index: 10;
}

@media screen and (max-width: 1217px){
  #app{
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
input[type="search"]::-webkit-search-cancel-button {
    display: none;
}
/* nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */
</style>
