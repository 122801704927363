<template>
  <div class="about">
    <!-- <div class="aboutNav" /> -->
    <!-- -----------------------企业资源计划（ERP） -->
    <CaseTop :topTitle="topTitle" :topType="topType" />

    <!-- ----------------------------产品概述 -->

    <ProjectMilitaryOverview :overViewText='overViewText' />


    <!-- ---------------------------应用领域 -->
    <div class="three" :style="{ width: this.threeWidth.width + 'px' }" style="height: 673px;">
      <div class="font3">
        <p>应用领域</p>
      </div>
      <div class="box">
        <AboutOneApplicationArea v-for="(item, index) in info" :key="index" :item="item" />
      </div>
    </div>

    <!-- --------------------------------------- 主要功能 -->
    <div class="four" :style="{ width: this.fourWidth.width + 'px' }" style="height: 802px;">
      <div class="erpFont4">
        <p style="width:9% ;height:20%">主要功能</p>
      </div>
      <div class="multi-purpose" style="width: 100%; height:75%;position: relative; margin-top:60px">
        <div class="all" style="width: 1096px; height:537px">

          <!-- 导航栏 -->
          <nav class="menu" style="width: 100%; height:11%">
            <div class="menu-router" style=" margin-left:2%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem1" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem2" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem3" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem4" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 10%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem5" />
            </div>
            <div class="menu-router" style="margin-left: 1%;width: 12%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem6" />
            </div>
            <div class="menu-router" style="margin-right: 4%;width: 14%; height: 100%;">
              <AboutOneMenu  :menuItem="menuItem7" />
            </div>
          </nav>

          <!-- 点击导航项显示下面不同的内容 -->
          <div class="menu-content" style="width: 86%; height:89%">

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem1" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem2" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem3" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem4" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem5" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem6" />
            </div>

            <div class="contentItem">
              <AboutOneMenuContent  :contentItem="contentItem7" />
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- --------------------------------硬件支持 -->
    <div class="AboutOneHardwareSupport"
      :style="{ width: this.fiveWidth.width + 'px' }" style="height:1007px">


      <!-- 申请试用 -->
      <div class="apply" style="width:100% ;height:477px">
        <div class="total" style="width:100% ;height:477px">
          <AboutOneApplyTryDiv />
        </div>
      </div>

      <!-- 实际案例 -->
      <Others  :index="index" :currentType="currentType" :topType="topType" :arrayIndex="0" />
    </div>

    <!-- 关于我们 -->
    <div :style="{ width: this.sixWidth.width + 'px', height: this.sixWidth.height + 'px' }">
      <contactUs :pageType="1" />
    </div>

  </div>
</template>

<script>
// 顶部标题
import CaseTop from '../components/CaseTop.vue'
// 关于我们
import contactUs from '../components/contactUs.vue'
// 产品概述
import ProjectMilitaryOverview from '../components/ProjectMilitaryOverview.vue'
// 主要功能导航栏
import AboutOneMenu from '../components/AboutOneMenu.vue'
// 主要功能导航栏对应内容
import AboutOneMenuContent from '../components/AboutOneMenuContent.vue'
// 应用领域
import AboutOneApplicationArea from '../components/AboutOneApplicationArea.vue'
// 申请试用
import AboutOneApplyTryDiv from '../components/AboutOneApplyTryDiv.vue'
// 实际案例
import Others from '../components/Others.vue'


// 应用领域
import areaImg1 from '../assets/aboutOneApplicationArea/facturingIcon.webp'
import areaImg2 from '../assets/aboutOneApplicationArea/retailIcon.webp'
import areaImg3 from '../assets/aboutOneApplicationArea/serverIcon.webp'
import areaImg4 from '../assets/aboutOneApplicationArea/resourcesIcon.webp'

// 主要功能
import erpFunction1 from '../assets/AboutERPMainFunction/function1.png'
import erpFunction2 from '../assets/AboutERPMainFunction/function2.png'
import erpFunction3 from '../assets/AboutERPMainFunction/function3.png'
import erpFunction4 from '../assets/AboutERPMainFunction/function4.png'
import erpFunction5 from '../assets/AboutERPMainFunction/function5.png'
import erpFunction6 from '../assets/AboutERPMainFunction/function6.png'
import erpFunction7 from '../assets/AboutERPMainFunction/function7.png'
import erpBackground from '../assets/AboutERPMainFunction/background.png'



export default {
  name: 'AboutOneView',
  components: {
    CaseTop,
    contactUs,
    ProjectMilitaryOverview,
    AboutOneMenu,
    AboutOneMenuContent,
    AboutOneApplyTryDiv,
    AboutOneApplicationArea,
    Others
  },
  mounted() {
    //加载后先计算一遍缩放比例
    this.Scale();
    //生成一个监听器：窗口发生变化从新计算计算一遍缩放比例
    window.addEventListener('resize', this.Scale);

    // 实际案例部分

    //获取图片样式
    this.slides = document.querySelectorAll('.slide');
    //根据索引居中
    this.centerSlide(this.currentIndex);
    // 定时器  自动轮播，每3秒切换一次图片
    this.startTime();


    // 主要功能部分点击导航切换内容
    this.changeContent();

  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.startTime);
  },
  data() {
    return {
      oneWidth: {
        width: '1920',
        height: '400'
      },
      twoWidth: {
        width: '1919',
        height: '472'
      },
      threeWidth: {
        width: '1919',
        height: '614'
      },
      fourWidth: {
        width: '1920',
        height: '802'
      },
      fiveWidth: {
        width: '1920',
        height: '2001'
      },
      sixWidth: {
        width: '1920',
        height: '533'
      },
      // 顶部标题
      topTitle:'企业信息管理系统（ERP）',
      topType:0,

      // 产品概述
            overViewText: 'ERP是一种主要面向制造行业进行物质资源、资金资源和信息资源集成一体化管理的企业信息管理系统。ERP是一个以管理会计为核心可以提供跨地区、跨部门、甚至跨公司整合实时信息的企业管理软件。针对物资资源管理、人力资源管理、财务资源管理、信息资源管理集成一体化的企业管理软件\n\t ERP系统包括以下主要功能：供应链管理、销售与市场、分销、客户服务、财务管理、制造管理、库存管理、工厂与设备维护、人力资源、报表等。此外还包括金融投资管理、质量管理、运输管理、项目管理、法规与标准和过程控制等补充功能。不仅可用于生产企业的管理，而且在许多其它类型的企业如一些非生产，公益事业的企业也可导入 ERP 系统进行资源计划和管理',
      
      // 主要功能导航栏
      menuItem1:{menuMsg:'销售管理'},
      menuItem2:{menuMsg:'采购管理'},
      menuItem3:{menuMsg:'客户管理'},
      menuItem4:{menuMsg:'财务管理'},
      menuItem5:{menuMsg:'库存管理'},
      menuItem6:{menuMsg:'生产控制管理'},
      menuItem7:{menuMsg:'人力资源管理'},

      // 主要功能内容
      contentItem1:{menuContent:' 销售与客户签订合同，形成订单;\n  业务员根据销售合同，制作业务流转单，明确合同中对应产品的物料 BOM 及物料需求，并指定仓库中可用的材料进行库存锁定，同时确定需采购的材料明细;\n 销售根据订单内容进行产品发货及发货前的质量检验，如有不合格产品进行退补货;\n  根据合同订单、发货退货、质量检验的数据，生成销售业绩报表，员工可查询自己提成，管理者可洞察销售业绩变化  ',menuImg:erpFunction1, menuWidth: '100', menuHeight: '92',menuLeft:'-3',menuSpanTop:'18',menuTop:'7',backImg:erpBackground,backWidth:'81',backHeight:'40',backLeft:'-15',backBottom:'0'},
      contentItem2:{menuContent:' 根据业务流转单产生的物料采购需求，或部分非订单的备料采购需求，统一制定采购合同;\n 对采购合同进行交货期控制和物流到货跟踪，发起物料采购入库，对质量异议物料进行采购退货和赔偿;\n  业务员将供应商信息录入系统，并进行供应商情况分析，制定最佳采购选择；\n  根据采购情况生成采购报表，实时查看采购数据',menuImg:erpFunction2, menuWidth: '88', menuHeight: '80',menuLeft:'5',menuSpanTop:'18',menuTop:'10',backImg:erpBackground,backWidth:'81',backHeight:'40',backLeft:'-15',backBottom:'0'},
      contentItem3:{menuContent:' 客户信息的统计与维护：业务员将客户信息录入系统中便于统计和查询;\n  客户信息分析，制定战略决策：根据数据分析，实时洞察客户信息变化，如订单量增加或减少，制定合理决策；\n  客户拜访/跟进记录：销售对客户的跟进和拜访形成记录，便于查询和参考',menuImg:erpFunction3, menuWidth: '105', menuHeight: '75',menuLeft:'-5',menuSpanTop:'18',menuTop:'10',backImg:erpBackground,backWidth:'81',backHeight:'40',backLeft:'-15',backBottom:'0'},
      contentItem4:{menuContent:'财务管理是对往来数据资料进行管理，从总账、现金、应收、应付、实收、实付、退款、开票、费用、预算，再到员工绩效工资、奖金等，这些都能在ERP管理系统内进行管理，使管理者对企业的财务往来明细了如指掌，并且对每一笔的出入账都能做到有据可查，有效的控制和降低企业的运营成本',menuImg:erpFunction4, menuWidth: '100', menuHeight: '80',menuSpanTop:'18',menuTop:'10',backImg:erpBackground,backWidth:'81',backHeight:'40',backLeft:'-15',backBottom:'0'},
      contentItem5:{menuContent:' ERP系统可以帮助企业管理库存流程，包括物料采购、物料入库、物料领用、物料退库、物料盘点等环节。通过ERP系统，企业可以实现对库存情况的实时掌控，从而降低库存成本，减少库存积压和库存过剩',menuImg:erpFunction5, menuWidth: '77', menuHeight: '87',menuLeft:'10',menuSpanTop:'18',menuTop:'5',backImg:erpBackground,backWidth:'81',backHeight:'40',backLeft:'-15',backBottom:'0'},
      contentItem6:{menuContent:' ERP系统可以帮助企业管理生产流程，包括生产计划、生产订单、生产调度、生产进度控制、生产质量控制等环节。通过ERP系统，企业可以实现对生产过程的实时监控和管理，从而提高生产效率和降低生产成本',menuImg:erpFunction6, menuWidth: '103', menuHeight: '77',menuLeft:'-5',menuSpanTop:'18',menuTop:'5',backImg:erpBackground,backWidth:'81',backHeight:'40',backLeft:'-15',backBottom:'0'},
      contentItem7:{menuContent:' ERP系统可以帮助企业管理人力资源流程，包括招聘、培训、绩效管理、薪酬管理等环节。通过ERP系统，企业可以实现对人力资源的全面管理和优化，从而提高员工工作效率和企业的人力资源管理水平',menuImg:erpFunction7, menuWidth: '95', menuHeight: '87',menuSpanTop:'18',menuTop:'11',backImg:erpBackground,backWidth:'81',backHeight:'40',backLeft:'-15',backBottom:'0'},

      // 实际案例
      index:-1,
      currentType: 2,

      carText1: '机动实操考核系统',
      carText2: '设备台站模拟训练系统',
      carText3: '红蓝双方兵力对抗系统',
      carImage1: areaImg1,
      carImage2: areaImg2,
      carImage3: areaImg3,
      carFieldImgs: [areaImg1, areaImg2, areaImg3],
      carFieldTexts: ['机动实操考核系统', '设备台站模拟训练系统', '红蓝双方兵力对抗系统'],

      currentIndex: 0,//当前显示的图片索引

      intervalId: null, //用于存储定时器ID

      // 应用领域
      info: [

        { p1: '生产制造领域', span: '在制造业中，ERP可以协调供应链、物流、生产等环节，提高企业生产效率和物流管理水平', isActive: false, backgroundImage: areaImg1 },
        { p1: '零售领域', span: '通过ERP管理销售业务，可以实现销售业绩及时掌握和可视化分析，更好地把握市场', isActive: false, backgroundImage: areaImg2 },
        { p1: '服务业领域', span: '服务业的特殊性质在于，它通常不是生产物品。而是提供服务。这就意味着，服务业企业需要将ERP系统与他们的定价系统、服务合同等相结合。这种整合对于服务业中的收入、缴费和预测来说是必要的', isActive: false, backgroundImage: areaImg3 },
        { p1: '人力资源管理领域', span: 'ERP可以管理企业的人力资源，包括员工档案管理、薪酬管理、绩效考核等，提升企业人力资源管理水平', isActive: false, backgroundImage: areaImg4 }
      ],
    }
  },
  methods: {
    Scale() {
      if (document.body.clientWidth < 1200) {
        this.oneWidth.width = 1200;
        this.oneWidth.height = 250;

        this.twoWidth.width = 1200;
        // this.twoWidth.height = 295

        this.threeWidth.width = 1200;
        // this.threeWidth.height = 616;

        this.fourWidth.width = 1200;
        // this.fourWidth.height = 503

        this.fiveWidth.width = 1200;
        // this.fiveWidth.height = 1251

        this.sixWidth.width = 1200;
        this.sixWidth.height = 333
      } else {
        this.oneWidth.height = 0.21 * document.body.clientWidth;
        this.oneWidth.width = document.body.clientWidth;

        // this.twoWidth.height = 0.25 * document.body.clientWidth;
        this.twoWidth.width = document.body.clientWidth;
        // 0.32 * document.body.clientWidth;
        // this.threeWidth.height = 616;
        this.threeWidth.width = document.body.clientWidth;

        // this.fourWidth.height = 0.42 * document.body.clientWidth;
        this.fourWidth.width = document.body.clientWidth;

        // this.fiveWidth.height = 1.04 * document.body.clientWidth;
        this.fiveWidth.width = document.body.clientWidth;

        this.sixWidth.height = 0.28 * document.body.clientWidth;
        this.sixWidth.width = document.body.clientWidth;
      }
    },

    /*锁定选定的盒子判断他是否选定 */
    // divClick(index) {
    //   /** 当 “点击” 一个“已被点击”的div时 使该div恢复默认色 */
    //   // 遍历info数组中的每个对象
    //   for (let i of this.info) {
    //     // 判断当前对象是否和传入的索引index对应 如果是，则取反
    //     if (index === this.info.indexOf(i)) {
    //       i.isActive = !i.isActive
    //     } else {
    //       i.isActive = false;
    //     }
    //   }
    // },

    // 鼠标滑过时，显示背景图片和修改文字颜色
    mouseOver(index) {
      this.info[index].isActive = true;
    },

    // 鼠标离开时则恢复默认
    mouseLeave(index) {
      this.info[index].isActive = false;
    },


    /*给选定的盒子修改样式 */
    colorDiv(index) {
      if (this.info[index].isActive) {
        return {
          backgroundImage: `url(${this.info[index].backgroundImage})`,
          color: 'white'
        };
      } else {
        return {
          backgroundImage: 'none',
          '.p1 p': {
            color: '#333333;'
          },
          '.span span': {
            color: 'black'
          }
        }
      }
    },

    /*
    实际案例部分轮播切换
     */
    showSlide(index) {
      this.carImage1 = this.carFieldImgs[index];
      this.carText1 = this.carFieldTexts[index];
      this.carImage2 = this.carFieldImgs[++index % 3];
      this.carText2 = this.carFieldTexts[index % 3];
      this.carText3 = this.carFieldTexts[++index % 3];
      this.carImage3 = this.carFieldImgs[index % 3];
      this.currentIndex = (++index) % 3;
    },
    //居中显示
    centerSlide(index) {
      this.currentIndex = index;
      this.showSlide(index);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.carFieldImgs.length;
      this.currentIndex = (this.currentIndex + 1) % this.carFieldTexts.length;
      this.showSlide(this.currentIndex);
    },

    // 定时器 自动轮播
    startTime() {
      this.intervalId = setInterval(this.nextSlide, 5000);
    },

    chanageImg(index) {
      /* 重置计时器 */
      clearInterval(this.intervalId);
      this.currentIndex = index;
      //  // 将被点击的图片居中显示
      // this.centerSlide(this.currentIndex);

      //计算需要移动的步数
      let steps = index - this.currentIndex;

      // 如果步数大于轮播图一半的长度，向前滚动到达目标图片
      if (Math.abs(steps) > this.slides.length / 2) {
        steps = steps > 0 ? steps - 3 : steps + 3;
      }

      // 更新 currentIndex，使目标图片移动到中间位置
      this.currentIndex = (this.currentIndex + steps + 3) % 3;

      // 将目标图片居中显示
      this.centerSlide(this.currentIndex);

      //重新启动定时器
      this.startTime();
    },

    // 主要功能部分切换功能
    changeContent() {
      // 在页面加载完成后先执行这个
      // document.addEventListener("DOMContentLoaded",function(){
      //   // 获取第一个菜单项
      //   var firstMenuItem = document.querySelector('.menu-router');
      //   // 让他点击第一个菜单项
      //   firstMenuItem.click();
      // })

      // 获取元素
      // var tab_list = document.querySelector('.menu');
      var list = document.querySelectorAll('.menu-router');
      var item = document.querySelectorAll('.contentItem');

      // item[0].style.display='block';
      // 获取第一个导航项
      var firstNavItem = document.querySelector('.menu-router');
      //获取第一个导航项对应的内容
      var firstContentItem = document.querySelector('.contentItem');
      // 给导航项添加样式
      firstNavItem.classList.add('menuActive');
      // 让第一导航项对应内容默认显示
      firstContentItem.style.display = 'block';

      // 分别绑定点击事件
      for (var i = 0; i < list.length; i++) {
        // 在这里创建属性，添加索引
        list[i].setAttribute('index', i);
        list[i].onclick = function () {
          // 1.第一大步骤，先解决导航栏的颜色切换
          // 所有的 menu-router清除class类
          for (var i = 0; i < list.length; i++) {
            list[i].classList.remove('menuActive');
          }
          // 自己添加上这个类
          this.classList.add('menuActive');
          // 2.第二大步骤：下面模块跟着选项卡一一匹配
          // 给上面的tab_list里面所有的li添加索引
          var index = this.getAttribute('index');
          console.log(index);
          // 先全部隐藏
          for (var j = 0; j < item.length; j++) {
            item[j].style.display = 'none';
          }
          // 当前的显示
          item[index].style.display = 'block';
        }
      }
    },


  },
}

</script>


<style>
.about {
  width: 100%;
  height: auto;
}

.aboutNav{
  width: 100%;
    height: 90px;
    background-color: #000;
}

.three {
  /* width: 1202px;
	height: 663px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  background-color: #f6f6f6;
}

.font3 {
  width: 100%;
  height: 116px;
}

.font3 p {
  font-family: MySourceHanSansSC-Heavy;
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 2px;
  color: #333333;
  white-space: nowrap;
  margin: 0 auto;
}

.box {
  width: 1200px;
  height: 390px;
  display: flex;
  justify-content: space-between;
  /*间隔均匀排列*/
  justify-content: center;
  /* margin: 0 auto; */
  margin: 0;
}

.four {
  background-image: url(' ../assets/mainFunctions.jpg ');
  background-size: cover;
}
.erpFont4 {
  width: 100%;
  height: 120px;
  display: flex;
  white-space: nowrap;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  /*水平居中 */
  /*align-items: center; 垂直居中*/
  position: relative;
  /*相对定位 */
}

.erpFont4 p {
  font-family: SourceHanSansSC-Heavsy;
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  font-stretch: normal;
  /* line-height: 30px; */
  letter-spacing: 4px;
  color: #ffffff;
  margin: 0;
  position: absolute;
  /*绝对定位 */
  bottom: 0;
  /*让他的下边距为0 */
  /* margin-top: 50%; */
  /* margin-bottom: 50%; */
}


.multi-purpose {
  /* top: 9%; */

}

.all {
  background-color: white;
  margin: 0 auto;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid rgb(112, 112, 112); */
  border-bottom: solid 1px #d3d3d3;
  border-bottom-width: 50%;
}
.menu-router {
  cursor: pointer;
  /* writing-mode: vertical-lr; */
  /*将文字垂直排列 */
  white-space: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  line-height: 1;
}


.menu-content {
  /* margin-top: 5%; */
  margin-left: 5%;
  /* margin-right: 0; */
  display: flex;
  justify-content: space-between;
}

.contentItem {
  display: none;
}

.menuActive {
  /* background-color: green; */
  border-bottom: solid 2px #ff9000;
  color: #fff;
}



.AboutOneHardwareSupport {
  /* margin: 0 auto; */
  /* display: flex;
 justify-content: center; */
  /* margin-top: 4%; */
  background-color: #f6f6f6;
}

.apply {
  display: flex;
  position: relative;
  justify-content: center;
}

.total {}

.try {
  position: relative;
  display: flex; 
   justify-content: center;
}

/* 实际案例标题 */
.font6 h2 {
  font-family: SourceHanSansSC-Heavy;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 4px;
  color: #333333;
  margin: 0;
}


/* -----------------1201-1366之间---------------- */
@media (min-width: 1201px) and (max-width:1366px) {
  /* 实际案例标题 */
  .font6 h2 {
    font-size: 25px;
  }

}


/* -----------------1367-1600之间---------------- */
@media (min-width: 1367px) and (max-width:1600px) {

  /* 实际案例标题 */
  .font6 h2 {
    font-size: 30px;
  }
}


/* -----------------1600以上---------------- */
@media (min-width: 1600px) {
  /* 实际案例标题 */
  .font6 h2 {
    font-size: 40px;
  }


}</style>